// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Users_container__a50dK {\n  padding: 25px 50px;\n  height: calc(100vh - 70px);\n  width: 100%;\n  overflow: scroll; }\n\n.Users_mainContainer__2uTGD {\n  height: calc(100vh - 70px);\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.Users_buttonContainer__y9vCy {\n  width: 100%;\n  text-align: right;\n  padding-bottom: 9px; }\n\n.Users_tableButtonContainer__3r454 {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .Users_tableButtonContainer__3r454 button {\n    margin-right: 5px !important; }\n    .Users_tableButtonContainer__3r454 button:last-child {\n      margin-right: 0 !important; }\n\n.Users_statusActive__1xEU2 {\n  font-weight: 700;\n  font-size: 11px;\n  text-transform: uppercase;\n  color: #256029;\n  padding: 3px;\n  background-color: #c8e6c9; }\n\n.Users_statusBlocked__2ZFYx {\n  font-weight: 700;\n  font-size: 11px;\n  text-transform: uppercase;\n  color: #c63737;\n  padding: 3px;\n  background-color: #fbcdd2; }\n\n.Users_dropdownContainer__2ZiII {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .Users_dropdownContainer__2ZiII span {\n    margin-right: 20px; }\n", ""]);
// Exports
exports.locals = {
	"container": "Users_container__a50dK",
	"mainContainer": "Users_mainContainer__2uTGD",
	"buttonContainer": "Users_buttonContainer__y9vCy",
	"tableButtonContainer": "Users_tableButtonContainer__3r454",
	"statusActive": "Users_statusActive__1xEU2",
	"statusBlocked": "Users_statusBlocked__2ZFYx",
	"dropdownContainer": "Users_dropdownContainer__2ZiII"
};
module.exports = exports;
