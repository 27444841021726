import React from 'react';
import {addNewCategoryToParent} from '../../../services/CategoriesService';
import {Field, Form, Formik} from 'formik';
import FormInput from '../../../commons/FormInput';
import styles from '../Categories.module.scss';
import {Button} from 'primereact/button';
import * as Yup from 'yup';

const AddCategoryToParent = ({refresh, setRefresh, setAddCategoryToParent, currentCategory}) => {
  const validationSchema = Yup.object().shape({
    newCategory: Yup
    .string()
    .required("Це поля є обов'язковим"),
  });

  return (
      <Formik
          initialValues={{
            newCategory: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, {setSubmitting}) => {
            setSubmitting(false);
            const isSuccess = await addNewCategoryToParent(values.newCategory, currentCategory?.key);
            isSuccess && setAddCategoryToParent(false);
            setRefresh(!refresh);
          }}
          validateOnChange={false}
      >
        {({values}) => (
            <Form>
              <div className={styles.categoryContainer}>
                <span><b>Категорія:</b></span>
                <span>{currentCategory?.data.name}</span>
              </div>
              <Field name={`newCategory`}
                     label="Вкладена категорія"
                     component={FormInput}
                     required
              />
              <div className={styles.submitButtonContainer}>
                <Button type="submit" label="Додати"/>
                <Button label="Відмінити" type="button" className="p-button-secondary"
                        onClick={() => setAddCategoryToParent(false)}/>
              </div>
            </Form>
        )}
      </Formik>
  );
};

export default AddCategoryToParent;