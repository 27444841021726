import React, { useEffect, useState } from 'react';
import Header from '../../Header';
import styles from '../AddClient/AddClient.module.scss';
import { Field, Form, Formik } from 'formik';
import {
  changeEnableTypeOwner,
  getAllCompanies,
} from '../../../services/ClientService';
import { popupDialog } from '../../../commons/PopupDialog/PopupDialog';
import FormInput from '../../../commons/FormInput';
import { Button } from 'primereact/button';
import SidebarPage from '../../../containers/Sidebar/SidebarPage';
import FormDropdown from '../../../commons/FormDropdown';
import { setNotificationsIsVisible } from '../../../store/actions/notificationsActions';
import Notifications from '../../Header/Notifications/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllClients,
  getClientId,
} from '../../../store/actions/clientsActions';
import { getUserData } from '../../../services/UserService';
import { Dropdown } from 'primereact/dropdown';

const ChangeOwnerStatus = ({ refresh, setRefresh, setChangeStatus }) => {
  const [newOwner, setNewOwner] = useState('');
  const allClients = useSelector(state => state.clientsReducer.allClients);
  const clientId = getClientId();
  const dispatch = useDispatch();
  const [client, setClient] = useState({});

  useEffect(() => {
    (async () => {
      if (clientId) {
        const client = await getUserData(clientId);
        setClient(client)
      }
      const clients = await getAllCompanies() || [];
      dispatch(getAllClients(clients));
    })()
  }, [clientId]);

  const currentClientList = allClients.find(item => item.data.id === client.id);
  const clientList = currentClientList?.children.map(item => ({ label: item.data.email, value: item.data.id }));

  return (
    <Formik
      initialValues={{
        newOwner: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        const isSuccess = await changeEnableTypeOwner(client?.id, newOwner);
        isSuccess && setChangeStatus(false); setRefresh(!refresh);
        !isSuccess && popupDialog('Помилка. Повторіть спробу', () => { }, 'Ok');
      }}
    >
      {() => (
        <Form className={styles.formContainer}>
          <Field name={`current_owner`}
            label="Власник"
            component={FormInput}
            value={client?.email}
            disabled
            required
          />
          <div className={styles.dropdownContainer}>
            <label>
              Новий власник
              <span style={{ fontSize: 12, color: '#E51A4B' }}>*</span>
            </label>
            <Dropdown
              id="owner-dropdown"
              style={{ width: 250 }}
              value={newOwner}
              options={clientList}
              onChange={(e) => { setNewOwner(e.value) }}
              required
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="button"
              label="Відмінити"
              onClick={() => setChangeStatus(false)}
              className="p-button-secondary"
            />
            <Button type="submit" label="Зберегти" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeOwnerStatus;