import {
  getCategory,
  getRootCategories,
} from './CategoriesService';

export const extractCategories = async importedData => {
  if (!Array.isArray(importedData)) {
    return null;
  }

  let rootCategories = [];
  let importedCategories = [];

  rootCategories = await getRootCategories().catch(err => {
    console.log('Error get root categories', err);
    return null;
  });
// Проходим по импортируемым товарам, заполняем дерево категорий с id корневых категорий
  importedData.forEach(item => {
    if (!importedCategories.some(el => el.name === item.category)) {
      let existedCategory = rootCategories.find(
          cat => cat.name?.toLowerCase?.().trim?.() ===
              item.category?.toLowerCase?.()?.trim?.());
      importedCategories.push({
        name: item.category,
        category_id: existedCategory?._id,
        subcategories: [],
      });
    }
    let index = importedCategories.findIndex(el => el.name === item.category);
    if (index >= 0) {
      if (!importedCategories[index].subcategories.some(
          el => el.name === item.subcategory)) {
        importedCategories[index].subcategories.push({name: item.subcategory});
      }
    }
  });

  const findExistedSubcategories = async (category, catIndex) => {
    if (category.category_id) {
      let existedCategory = await getCategory(category.category_id);
      if (Array.isArray(existedCategory?.child_categories) &&
          existedCategory.child_categories.length) {
        const existedSubcategories = existedCategory.child_categories; //существующие подкатегории в данной категории
        category.subcategories.forEach((importedSubcategory, index) => {
          let subIndex = existedSubcategories.findIndex(
              sub => sub?.name?.toLowerCase?.()?.trim?.() === importedSubcategory?.name?.toLowerCase?.()?.trim?.());
          if (subIndex >= 0) {
            importedCategories[catIndex].subcategories[index].subcategory_id = existedSubcategories[subIndex]?._id;
          }
        });
      }
    }
  };

  //Проходим по подкатегориям существующих категорий
  for(const [index,cat] of importedCategories.entries()){
    await findExistedSubcategories(cat, index)
  }

  return importedCategories;
};