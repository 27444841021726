import React, {useState} from 'react';
import * as Yup from 'yup';
import styles from './Registration.module.scss';
import {Field, Form, Formik} from 'formik';
import {Button} from 'primereact/button';
import FormPassword from '../../commons/FormPassword';
import Header from '../Header';
import {registration} from '../../services/LoginService';
import {popupDialog} from '../../commons/PopupDialog/PopupDialog';

const Registration = ({toLoginPage, toHomePage}) => {

  const validationSchema = Yup.object().shape({
    password: Yup
    .string()
    .min(8, 'Пароль повинен містити мінімум 8 символів')
    .matches(/(?!.*[\s])(?=.*[a-z])(?=.*[0-9]).{8,}$/i, 'Пароль повинен містити мінімум одну цифру')
    .required("Це поле обов'язкове"),
    confirmPassword: Yup
    .string()
    .required("Це поле обов'язкове")
    .test('passwords-match', 'Паролі не співпадають',
        function(value) {
          return this.parent.password === value;
        }),
  });

  return (
      <>
        <Header/>
        <div className={styles.container}>
          <h1>Реєстрація</h1>
          <Formik
              initialValues={{
                password: '',
                confirmPassword: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);
                const isValid = registration(values.password);
                isValid
                    ? popupDialog('Реєстрація завершена, чекайте підтвердження', () => toHomePage(), 'Ok')
                    : popupDialog('Помилка відправлення', () => {}, 'Ok')
              }}
              validateOnChange={false}
              validateOnMount={true}
          >
            {({values}) => (
                <Form className={styles.formContainer}>
                  <Field name={`password`}
                         label="Пароль"
                         component={FormPassword}
                  />
                  <Field name={`confirmPassword`}
                         label="Повторіть пароль"
                         component={FormPassword}
                  />
                  <div className={styles.buttonContainer}>
                    <Button type="Зберегти" label="Зареєструватись"/>
                    <Button
                        onClick={toLoginPage}
                        label="Авторизація"
                        className="p-button-secondary"
                    />
                  </div>
                </Form>
            )}
          </Formik>
        </div>
      </>
  );
};

export default Registration;
