import React, {useEffect, useState} from 'react';
import FreeTenders from '../../components/Home/FreeTenders/FreeTenders';
import {addManagerToTender, getFreeTenders} from '../../services/TenderService';
import {
  setAllFreeTenders,
  setCurrentTenderData,
} from '../../store/actions/tendersActions';
import {useDispatch, useSelector} from 'react-redux';
import routes from '../../constants/routes';
import {getUserId} from '../../store/actions/loginActions';
import {popupDialog} from '../../commons/PopupDialog/PopupDialog';
import {getUserById} from '../../services/UserService';
import {setUserRole} from '../../store/actions/usersActions';
const userId = getUserId();

const FreeTendersPage = ({history}) => {
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);
  const filterType = useSelector(state => state.tenderReducer.sortTypeFreeTenders);
  const dispatch = useDispatch();

  const goToTender = async (tender) => {
    const isSuccess = await addManagerToTender(tender._id, userId);

    dispatch(setCurrentTenderData(tender));
    isSuccess
        ? history.push(routes.TENDER_DETAILS_PAGE)
        : popupDialog('Помилка. Повторіть спробу', () => {}, 'Вийти')
  };

  const statusChecker = status => {
    switch (status) {
      case 0:
        return "Новий. Очікує обробки";
      case 3:
        return "Вибір постачальника";
      case 1:
        return "Вимагає підтвердження";
      case 4:
        return "Документи";
      case 5:
        return "Доставка очікується";
      case 2:
        return "Підтверджений";
      case 6:
        return "Доставка успішна";
      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const freeTenders = await getFreeTenders(filterType) || [];
      dispatch(setAllFreeTenders(freeTenders));
      setPreloaderIsActive(false)
    })();
  }, [filterType]);

  useEffect(() => {
    (async () => {
      const id = getUserId();
      const role = await getUserById(id) || [];
      dispatch(setUserRole(role));
    })()
  }, []);

  return (
      <FreeTenders
          preloaderIsActive={preloaderIsActive}
          goToTender={goToTender}
          statusChecker={statusChecker}
      />
  );
};

export default FreeTendersPage;
