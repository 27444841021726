export const styles = {
  wrapper: {
    maxWidth: "100%",
    marginTop: 20,
  },

  fileWrapper: {
    display: 'inline-block',
    height: 50,
    width: 100,
  },

  wrapperUser: {
    marginRight: 30,
  },

  wrapperAdmin: {
    marginLeft: 30,
  },

  admin: {
    height: '100%',
    borderRadius: "10px 10px 10px 0",
    backgroundColor: "#f4f4f4",
    left: 0,
  },

  user: {
    height: '100%',
    borderRadius: "10px 10px 0 10px",
    backgroundColor: "#ceeaf7",
  },

  left: {
    textAlign: 'left',
    alignItems: "left",
    justifyContent: 'flexEnd'
  },

  right: {
    textAlign: 'right',
    alignItems: "right",
    justifyContent: 'flexStart'
  },

  msgContainer: {
    textAlign: "left"
  },

  time: {
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 3,
    color: "gray",
  },
  timeContainer: {
    display: "flex",
    padding: "0 10px",
    flexDirection: "row",
  },

  msg: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 0,
    wordWrap: "break-word",
  },
};
