import React, {useState} from 'react';
import * as Yup from 'yup';
import Header from '../../Header';
import styles from '../Login.module.scss';
import {Field, Form, Formik} from 'formik';
import FormInput from '../../../commons/FormInput';
import {Button} from 'primereact/button';
import {restorePassword} from '../../../services/LoginService';
import Preloader from '../../../commons/Preloader/Preloader';

const RestorePasswordWithEmail = ({ backToLoginPage }) => {
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);

  const emailValidationSchema = Yup
  .object()
  .shape({
    email: Yup.string()
    .email('Неправильний email')
    .required("Це поле обов'язкове"),
  });

  return (
      <>
        <Preloader active={preloaderIsActive}/>
        <Header/>
        <div className={styles.container}>
          <h2>Відновлення паролю</h2>
          <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={emailValidationSchema}
              onSubmit={async (values, {setSubmitting}) => {
                setPreloaderIsActive(true);
                setSubmitting(false);
                await restorePassword(values.email);
                setPreloaderIsActive(false);
              }}
              validateOnChange={true}
              validateOnMount={true}
          >
            {({values}) => (
                <Form className={styles.formContainer}>
                  <Field name={`email`}
                         label="Email"
                         keyfilter="email"
                         component={FormInput}
                         required
                  />
                  <div className={styles.buttonContainer}>
                    <Button
                        label="Відправити"
                        type="submit"
                    />
                  </div>
                </Form>
            )}
          </Formik>
          <Button
              label="Авторизація"
              type="button"
              className="p-button-secondary"
              onClick={backToLoginPage}
          />

        </div>
      </>
  );
};

export default RestorePasswordWithEmail;
