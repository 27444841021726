import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import tenderReducer from './reducers/tenderReducer';
import chatReducer from './reducers/chatReducer';
import usersReducer from './reducers/usersReducer';
import clientsReducer from './reducers/clientsReducer';
import notificationsReducer from './reducers/notificationsReducer';
import offersReducer from './reducers/offersReducer';
import categoriesReducer from './reducers/categoriesReducer';
import supplierReducer from './reducers/supplierReducer';

const initialStore = {};

const rootReducer = combineReducers({
  tenderReducer: tenderReducer,
  chatReducer: chatReducer,
  usersReducer: usersReducer,
  clientsReducer: clientsReducer,
  notificationsReducer: notificationsReducer,
  offersReducer: offersReducer,
  categoriesReducer: categoriesReducer,
  supplierReducer: supplierReducer,
});

export default createStore(
    rootReducer,
    initialStore,
    composeWithDevTools(applyMiddleware(thunk)),
);




