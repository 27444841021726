// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClientCard_container__1vClz {\n  height: calc(100vh - 80px);\n  margin: 0 auto;\n  width: 70%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.ClientCard_formContainer__2YWlo {\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .ClientCard_formContainer__2YWlo input {\n    width: 250px; }\n  .ClientCard_formContainer__2YWlo b {\n    display: block;\n    font-size: 22px;\n    margin-bottom: 30px; }\n\n.ClientCard_buttonContainer__9sK3F {\n  padding: 10px; }\n  .ClientCard_buttonContainer__9sK3F button:first-child {\n    margin-top: 10px;\n    margin-right: 55px; }\n\n.ClientCard_dropdownContainer__1eC6u {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px; }\n\n.ClientCard_mainContainer__3LIAy {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n", ""]);
// Exports
exports.locals = {
	"container": "ClientCard_container__1vClz",
	"formContainer": "ClientCard_formContainer__2YWlo",
	"buttonContainer": "ClientCard_buttonContainer__9sK3F",
	"dropdownContainer": "ClientCard_dropdownContainer__1eC6u",
	"mainContainer": "ClientCard_mainContainer__3LIAy"
};
module.exports = exports;
