// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AllTenders_mainContainer__tOVpr {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.AllTenders_contentContainer__mGT2O {\n  width: 100%;\n  height: calc(100vh - 70px);\n  background-color: #ecf0f5;\n  padding: 25px 50px;\n  overflow: scroll;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: baseline;\n  flex-wrap: nowrap; }\n\n.AllTenders_tableButtonContainer___vF2h {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .AllTenders_tableButtonContainer___vF2h button {\n    margin-right: 5px !important; }\n    .AllTenders_tableButtonContainer___vF2h button:last-child {\n      margin-right: 0 !important; }\n\n.AllTenders_dropdownContainer__gptlB {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .AllTenders_dropdownContainer__gptlB span {\n    margin-right: 20px; }\n\n.AllTenders_formContainer__RT_HZ {\n  width: 500px;\n  margin-top: 50px;\n  background-color: #fff;\n  border: 1px solid #c8c8c8;\n  padding: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .AllTenders_formContainer__RT_HZ label {\n    margin-bottom: 30px;\n    text-align: center;\n    font-weight: bold;\n    font-size: 20px;\n    width: 250px; }\n  .AllTenders_formContainer__RT_HZ input {\n    width: 250px; }\n\n.AllTenders_buttonContainer__3LW0P {\n  margin-top: 20px;\n  padding: 10px;\n  width: 100%;\n  text-align: center; }\n  .AllTenders_buttonContainer__3LW0P button:first-child {\n    margin-right: 50px; }\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "AllTenders_mainContainer__tOVpr",
	"contentContainer": "AllTenders_contentContainer__mGT2O",
	"tableButtonContainer": "AllTenders_tableButtonContainer___vF2h",
	"dropdownContainer": "AllTenders_dropdownContainer__gptlB",
	"formContainer": "AllTenders_formContainer__RT_HZ",
	"buttonContainer": "AllTenders_buttonContainer__3LW0P"
};
module.exports = exports;
