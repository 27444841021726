import React, {useEffect, useState} from 'react';
import ClientHistory
  from '../../components/Clients/ClientHistory/ClientHistory';
import {getClientsHistoryById} from '../../services/ClientService';
import {useSelector} from 'react-redux';

const ClientHistoryPage = () => {
  const [currentHistory, setCurrentHistory] = useState([]);
const currentClientId = useSelector(state => state.clientsReducer.currentClient).id;

  useEffect(() => {
    (async () => {
      const currentHistory = await getClientsHistoryById(currentClientId) || [];
      setCurrentHistory(currentHistory)
    })()
  }, [currentClientId]);

  return (
      <ClientHistory
          currentHistory={currentHistory}
      />
  );
};

export default ClientHistoryPage;