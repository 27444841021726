import axios from 'axios';
import {
  getTemporaryToken,
  setTemporaryToken,
  setToken,
  setUserId, setUserRole,
} from '../store/actions/loginActions';
import {popupDialog} from '../commons/PopupDialog/PopupDialog';
import {BASE_URL} from '../constants/serviceConstants';

export const login = async (email, password) => {
  return await axios.post(
    `${BASE_URL}/users/backoffice/signIn`,
      {email, password})
    .then(({data}) => {

      if (!data.twoFA) {
        setToken(data.token)
      }
      setUserId(data.userId);
      setUserRole(data.role);

      return data;
    })
    .catch(err => {
      popupDialog('Неправильний email або пароль', () => {}, 'Ок');
    })
};

export const resendPassword = async (email) => {
  return await axios.patch(
      `${BASE_URL}/users/duplicateVerifyCode`,
      {email})
      .then(data => console.log('data', data))
      .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const confirmCode = async (code, email, password) => {
  return await axios.post(
      `${BASE_URL}/users/backoffice/signIn`,
      {code, email, password})
      .then(({data}) => {
        const isSuccess = data;
        setToken(data.token);
        setUserId(data.userId);
        setUserRole(data.role);

        if(!isSuccess) {
          popupDialog('Неправильний код', () => {}, 'Ок');
        }

          return isSuccess;
      })
      .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const restorePassword = async (email) => {
  return await axios.patch(
      `${BASE_URL}/users/backoffice/passwordRecovery`,
      {email})
      .then(({data}) => {
        // setTemporaryToken(data.temporaryToken);
        popupDialog('Посилання відправлено на Ваш email', () => {}, 'Ок');
      })
      .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const setNewPassword = async (token, password) => {
  // const token = getTemporaryToken();
  return await axios.patch(`${BASE_URL}/users/passwordBackOffice`,
      {password},
      {headers: {"Authorization": `Bearer ${token}`}})
      .then(({data}) => {
        const isSuccess = data.success;
        isSuccess && popupDialog('Пароль успішно змінено', () => {}, 'Ок');

        return isSuccess})
      .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const registration = async (password) => {
  const splitUrl = window.location.href.split('/');
  const token = splitUrl[splitUrl.length - 1];

  return await axios.patch(
      `${BASE_URL}/users/passwordBackOffice`,
      {password},
      {headers: {"Authorization": `Bearer ${token}`}})
      .then(({data}) => {return data.success})
      .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};