import React, { useState } from 'react';
import { setNotificationsIsVisible } from '../../../store/actions/notificationsActions';
import Notifications from '../../Header/Notifications/Notifications';
import Header from '../../Header';
import SidebarPage from '../../../containers/Sidebar/SidebarPage';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../../commons/Preloader/Preloader';
import styles from './ShipmentRegistry.module.scss';
import { setCurrentPageFreeTenders } from '../../../store/actions/tendersActions';
import { setSentForSupplier } from '../../../store/actions/supplierActions';

import { useTranslation } from 'react-i18next';

const ShipmentRegistry = ({
  preloaderIsActive,
  handleSubmit,
  sentTenders,
  setSentTenders,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { allAcceptedTenders, disabledAcceptedTenders } = useSelector(
    (state) => state.supplierReducer
  );
  const page = useSelector(
    (state) => state.tenderReducer.currentFreeTendersPage
  );
  const [rowCount, setRowCount] = useState(10);

  const rowsCountData = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  const handleChangeSent = (id) => {
    dispatch(setSentForSupplier(id));

    if (sentTenders.includes(id)) {
      return setSentTenders((prev) => prev.filter((item) => item !== id));
    }

    setSentTenders((prev) => [...prev, id]);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Preloader active={preloaderIsActive} />
      <Header />
      <div
        className={styles.mainContainer}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      >
        <SidebarPage />
        <Notifications />
        <div className={styles.contentContainer}>
          <div className={styles.dropdownContainer}>
            <div>
              <span>{t('suppliers.rowsCount')}:</span>
              <Dropdown
                value={rowCount}
                options={rowsCountData}
                onChange={(e) => setRowCount(e.value)}
              />
            </div>
            <Button
              label={t('suppliers.saveChanges')}
              icon='pi pi-check'
              onClick={handleSubmit}
              disabled={sentTenders.length === 0}
            />
          </div>
          <DataTable
            paginator
            value={allAcceptedTenders}
            editMode='cell'
            rows={rowCount}
            first={page}
            onPage={(e) => dispatch(setCurrentPageFreeTenders(e.first))}
            emptyMessage='No tenders found.'
          >
            <Column
              field='internal_id'
              header={t('suppliers.order')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='supplierItem'
              header={t('suppliers.productName')}
              style={{ width: '15%', wordBreak: 'break-word' }}
              sortable
              filter
              bodyStyle={{ textAlign: 'center' }}
            />
            <Column
              field='client_name'
              header={t('suppliers.clientName')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='goods_delivery_address'
              header={t('suppliers.shippingAddress')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='offer_amount'
              header={t('suppliers.count')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              sortable
            />
            <Column
              field='supplierPrice'
              header={t('suppliers.price')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              sortable
            />
            <Column
              field='offer_sum'
              header={t('suppliers.summa')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              sortable
            />
            <Column
              field='tender_date_start'
              header={t('suppliers.dateOfOrder')}
              style={{ width: '15%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
              body={(e) => e.tender_date_start.split('T')[0]}
            />
            <Column
              field='offer_sent'
              header={t('suppliers.shipped')}
              style={{ width: '15%', textAlign: 'center' }}
              sortable
              body={(e) => (
                <Checkbox
                  onChange={() => handleChangeSent(e.offer_id)}
                  checked={e.offer_sent}
                  disabled={disabledAcceptedTenders.includes(e.offer_id)}
                />
              )}
            />
            <Column
              field='offer_delivered'
              header={t('suppliers.received')}
              style={{ width: '15%', textAlign: 'center' }}
              sortable
              body={(e) => <Checkbox checked={e.offer_received} disabled />}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default ShipmentRegistry;
