import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header';
import styles from './Categories.module.scss';
import { Button } from 'primereact/button';
import SidebarPage from '../../containers/Sidebar/SidebarPage';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCategoriesCurrentPage,
  setCategoriesRowsInTable,
} from '../../store/actions/categoryActions';
import { setNotificationsIsVisible } from '../../store/actions/notificationsActions';
import Notifications from '../Header/Notifications/Notifications';
import SettingsPopup from '../../commons/SettingsPopup/SettingsPopup';
import AddNewCategory from './AddNewCategory/AddNewCategory';
import AddItem from './AddItem/AddItem';
import AddCategoryToParent from './AddCategoryToParent/AddCategoryToParent';
import EditItem from './EditItem/EditItem';
import EditCategory from './EditCategory/EditCategory';
import Preloader from '../../commons/Preloader/Preloader';
import { extractCategories } from '../../services/ItemImport';
import { Dialog } from 'primereact/dialog';
import {
  addGoodsArray,
  addNewCategory as addNewCategoryQuery,
  addNewCategoryToParent, getCategory, getRootCategories,
} from '../../services/CategoriesService';
import { FileUpload } from 'primereact/fileupload';
import readXlsxFile from 'read-excel-file';
import { popupDialog } from '../../commons/PopupDialog/PopupDialog';

const Categories = ({
  setRefresh,
  deleteCategory,
  refresh,
  rowsCountData,
  categoriesList,
  deleteItem,
  preloaderIsActive,
  setPreloaderIsActive,
}) => {
  const page = useSelector(state => state.categoriesReducer.currentPage);
  const rowsInTable = useSelector(state => state.categoriesReducer.rowsInTable);
  const dispatch = useDispatch();
  const [addNewCategory, setNewCategory] = useState(false);
  const [addNewItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [addCategoryToParent, setAddCategoryToParent] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoriesForCreation, setCategoriesForCreation] = useState(null);
  const [categoryForCreation, setCategoryForCreation] = useState(null);
  const [importedNomenclature, setImportedNomenclature] = useState([]);
  const [categoriesForSelect, setCategoriesForSelect] = useState([]);
  const fileUploadRef = useRef(null);

  const prepareCategoriesForImport = async dataFromXlsxFile => {
    setPreloaderIsActive(true);
    let importedCategories = await extractCategories(dataFromXlsxFile);
    if (Array.isArray(importedCategories) && importedCategories.length) {
      setCategoriesForCreation(importedCategories);
    }
    setPreloaderIsActive(false);
  };

  const closeCreateCategoryDialog = () => {
    setCategoryForCreation(null);
  };

  const createCategoryForImport = async () => {
    if (typeof categoryForCreation === 'object') {
      let {
        name,
        categoryIndex,
        subcategoryIndex,
        parentId,
      } = categoryForCreation;
      let newCategory = parentId ?
        await addNewCategoryToParent(name, parentId) :
        await addNewCategoryQuery(name);
      if (newCategory?._id && !parentId) {
        let importedCategoryTree = [...categoriesForCreation];
        importedCategoryTree[categoryIndex].category_id = newCategory._id;
        setCategoryForCreation(null);
        setCategoriesForCreation(importedCategoryTree);
        setRefresh(!refresh);
      } else if (newCategory?._id && parentId) {
        let importedCategoryTree = [...categoriesForCreation];
        importedCategoryTree[categoryIndex].subcategories[subcategoryIndex].subcategory_id = newCategory._id;
        setCategoryForCreation(null);
        setCategoriesForCreation(importedCategoryTree);
        setRefresh(!refresh);
      } else {
        setCategoryForCreation(null);
        console.log('Помилка створення категорії');
      }
    }
  };

  const selectCategoryForImport = () => {
    let {
      categoryIndex,
      subcategoryIndex,
      parentId,
      category_id,
      subcategory_id,
      existedCategory,
    } = categoryForCreation;
    if (category_id && !parentId) { //Если корневая категория
      let importedCategoryTree = [...categoriesForCreation];
      importedCategoryTree[categoryIndex].category_id = category_id;
      importedCategoryTree[categoryIndex].existedCategory = existedCategory;
      setCategoryForCreation(null);
      setCategoriesForCreation(importedCategoryTree);
    } else { // Если подкатегория
      let importedCategoryTree = [...categoriesForCreation];
      importedCategoryTree[categoryIndex].subcategories[subcategoryIndex].subcategory_id = subcategory_id;
      setCategoryForCreation(null);
      setCategoriesForCreation(importedCategoryTree);
    }
  };

  const onCategorySelect = ({ value, originalEvent }) => {
    if (!categoryForCreation.parentId) {
      setCategoryForCreation({
        ...categoryForCreation,
        category_id: value,
        existedCategory: originalEvent?.target?.innerText,
      });
    } else {
      setCategoryForCreation({ ...categoryForCreation, subcategory_id: value });
    }
  };

  const importItems = () => {
    setPreloaderIsActive(true);
    let importedItemsList = importedNomenclature.map(item => {
      let rootCategory = categoriesForCreation.find(
        cat => cat.name === item.category);
      let category = null;
      if (rootCategory?.subcategories) {
        category = rootCategory.subcategories.find(
          subcat => subcat.name === item.subcategory);
      }
      return ({
        name: item.name,
        description: item.description || '',
        measureUnit: item.measure_unit || '',
        data: {},
        note: item.note || '',
        category: {
          _id: category?.subcategory_id ?
            category?.subcategory_id :
            rootCategory?.category_id,
        },
      });
    });
    addGoodsArray(importedItemsList).then(res => {
      if (res) {
        popupDialog('Файл успішно завантажений', () => { setRefresh(!refresh) }, 'Ок');
      } else {
        popupDialog('Помилка. Повторіть спробу', () => { }, 'Ок')
      }
    }).finally(() => {
      setPreloaderIsActive(false);
    },
    );
  };

  useEffect(() => {
    if (Array.isArray(categoriesForCreation)) {
      const index = categoriesForCreation.findIndex(
        cat => (cat.name && !cat.category_id));
      if (index >= 0) {
        setCategoryForCreation(
          { name: categoriesForCreation[index].name, categoryIndex: index });
      } else {
        for (const [catIndex, cat] of categoriesForCreation.entries()) {
          const subCatIndex = cat.subcategories?.findIndex?.(
            item => (item?.name && !item.subcategory_id));
          if (subCatIndex >= 0) {
            setCategoryForCreation(
              {
                name: cat.subcategories[subCatIndex].name,
                categoryIndex: catIndex,
                subcategoryIndex: subCatIndex,
                parentName: cat?.name,
                parentId: cat?.category_id,
                existedCategory: cat?.existedCategory,
              });
            break;
          } else if (catIndex === categoriesForCreation.length - 1) {
            // console.log('Все категории созданы');
            importItems();
          }
        }
      }
    }
  }, [categoriesForCreation]);

  useEffect(() => {
    if (!categoryForCreation) {
      return
    }
    if (!categoryForCreation?.parentId) {
      getRootCategories().then(cat => {
        if (Array.isArray(cat)) {
          setCategoriesForSelect(cat);
        }
      }).catch(err => {
        console.log('Error get root categories', err);
      });
    } else {
      getCategory(categoryForCreation?.parentId).then(cat => {
        if (Array.isArray(cat?.child_categories)) {
          setCategoriesForSelect(cat.child_categories);
        } else {
          setCategoriesForSelect([]);
        }
      }).catch(err => {
        console.log('Error get root categories', err);
      });
    }
  }, [categoryForCreation]);

  const createCategoryFooter = (
    <div className={styles.dialogFooter}>
      <Button label="Відміна" icon="pi pi-times"
        className="p-button-danger"
        onClick={closeCreateCategoryDialog} />
      <Button label="Вибрати з існуючих" icon="pi pi-list"
        onClick={selectCategoryForImport}
        disabled={!categoryForCreation?.category_id && !categoryForCreation?.subcategory_id} />
      <Button label="Створити" icon="pi pi-check"
        onClick={createCategoryForImport} />
    </div>
  );

  const mapRowsToObjects = rows => {
    const itemList = rows.slice(1).map((row) => {
      if (row[2] && row[0]) {
        const newNomenclatureObj = {
          category: row[0],
          subcategory: row[1],
          name: row[2],
          description: row[3],
          measure_unit: row[4],
        };
        if (row[5]) {
          newNomenclatureObj.note = row[5];
        }
        return newNomenclatureObj;
      }
    });
    return itemList?.filter?.(el => !!el);
  };

  const onSelectFile = async e => {
    if (e.files && e.files.length > 0) {
      const file = e.files[0];
      if (file.type === 'application/vnd.ms-excel' || file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        let rows = await readXlsxFile(file).catch(err=>{
         return  popupDialog('Неправильний формат файлу. Завантажте шаблон по посиланню "Завантажити шаблон" і скористайтеся ним для формування файлу даних',
              () => { fileUploadRef.current.clear() }, 'Закрити')
        });
        if (Array.isArray(rows[0])){
          let tableHeader = rows[0];
          if(tableHeader[0]!=='Категория'||tableHeader[1]!=='Подкатегория'||tableHeader[2]!=='Наименование'){
            return  popupDialog('Таблиця не відповідає шаблоном. Завантажте шаблон по посиланню "Завантажити шаблон" і скористайтеся ним для формування файлу даних',
                () => { fileUploadRef.current.clear() }, 'Закрити');
          }
        }
        let importedData = mapRowsToObjects(rows);
        setImportedNomenclature(importedData);
        await prepareCategoriesForImport(importedData);
        fileUploadRef.current.clear();
      } else {
        console.log('error file type');
        return  popupDialog('Неправильний формат файлу. Завантажте шаблон по посиланню "Завантажити шаблон" і скористайтеся ним для формування файлу даних',
            () => { fileUploadRef.current.clear() }, 'Закрити');
      }
    }
  };

  const getDialogText = () => {
    if (categoryForCreation?.parentName) {
      if (!categoryForCreation?.existedCategory) {
        return (<p>
          Підкатегорія <span>"{categoryForCreation?.name}"</span> отуствует
          в категории <span>"{categoryForCreation?.parentName}"</span>.
          Ви можете створити її або вибрати з існуючих
        </p>);
      } else {
        return (<p>
          Виберіть підкатегорію, відповідну підкатегорії <span>"{categoryForCreation?.name}"</span>, або створіть нову
        </p>);
      }
    } else {
      return (<p>
        Категорія <span>"{categoryForCreation?.name}"</span> отуствует
        в номенклатурі. Ви можете створити її або вибрати з існуючих
      </p>);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Preloader active={preloaderIsActive} />
      <Dialog onHide={closeCreateCategoryDialog}
        visible={!!categoryForCreation}
        header={'Створення категорії'}
        footer={createCategoryFooter}
        className={styles.dialogContainer}
      >
        <div className={styles.createCategoryDialog}>
          {getDialogText()}
          <Dropdown placeholder="Вибрати з існуючих"
            options={categoriesForSelect}
            optionLabel="name"
            optionValue="_id"
            value={categoryForCreation?.category_id ||
              categoryForCreation?.subcategory_id}
            className={styles.categorySelect}
            scrollHeight={'140px'}
            onChange={onCategorySelect}
          />
        </div>
      </Dialog>
      <Header />
      <div
        className={styles.mainContainer}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      >
        <SidebarPage />
        <Notifications />
        <div className={styles.container}>
          <SettingsPopup
            visible={addNewCategory}
            onHide={() => setNewCategory(false)}
            headerText="Додавання категорії"
          >
            <AddNewCategory
              setNewCategory={setNewCategory}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </SettingsPopup>
          <SettingsPopup
            visible={addNewItem}
            onHide={() => setNewItem(false)}
            headerText="Додавання товару"
          >
            <AddItem
              currentCategory={currentCategory}
              setNewItem={setNewItem}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </SettingsPopup>
          <SettingsPopup
            visible={addCategoryToParent}
            onHide={() => setAddCategoryToParent(false)}
            headerText="Додавання категорії"
          >
            <AddCategoryToParent
              currentCategory={currentCategory}
              setAddCategoryToParent={setAddCategoryToParent}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </SettingsPopup>
          <SettingsPopup
            visible={editItem}
            onHide={() => setEditItem(false)}
            headerText="Редагування товару"
          >
            <EditItem
              currentCategory={currentCategory}
              setEditItem={setEditItem}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </SettingsPopup>
          <SettingsPopup
            visible={editCategory}
            onHide={() => setEditCategory(false)}
            headerText="Редагування категорії"
          >
            <EditCategory
              currentCategory={currentCategory}
              setEditCategory={setEditCategory}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </SettingsPopup>
          <div className={styles.rightSide}>
            <div className={styles.infoContainer}>
              <div className={styles.dropdownContainer}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    label="Додати категорію"
                    icon="pi pi-plus-circle"
                    onClick={() => {
                      setNewCategory(true);
                    }}
                    style={{ marginRight: '10px', cursor: 'default' }}
                  />
                  <FileUpload
                    chooseLabel="Iмпорт з Excel"
                    name="nomenclature"
                    mode="basic"
                    accept="application/*"
                    customUpload
                    uploadHandler={onSelectFile}
                    style={{ cursor: 'default' }}
                    ref={fileUploadRef}
                  />
                  <a href="/template/nomenclature_template.xlsx" download
                    style={{ marginLeft: '10px' }}>Скачати шаблон Excel</a>
                </div>
                <div>
                  <span>Кількість рядків у таблиці:</span>
                  <Dropdown
                    value={rowsInTable}
                    options={rowsCountData}
                    onChange={e => dispatch(
                      setCategoriesRowsInTable(e.value))}
                  />
                </div>
              </div>
              <div>
                <TreeTable responsive={true} value={categoriesList} paginator
                  rows={rowsInTable} first={page}
                  onPage={e => dispatch(
                    setCategoriesCurrentPage(e.first))}>
                  <Column field="name" expander header="Найменування"
                    style={{ width: '70%', height: 44 }}
                    sortable filter filterMatchMode="contains" />
                  <Column header="Тип" style={{ width: 120 }} body={e => (
                    <span style={{
                      backgroundColor: e?.data?.type === 'Категорія' ?
                        '#c8e6c9' :
                        '#b3e5fc',
                      color: e?.data?.type === 'Категорія' ?
                        '#256029' :
                        '#23547b',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      paddingLeft: 5,
                      paddingRight: 5,
                      width: '100%',
                      textAlign: 'center',
                    }}
                    >
                      {e?.data?.type || ''}
                    </span>
                  )} />
                  <Column field="measureUnit" header="Величина"
                    style={{ width: 100 }} />
                  <Column columnKey="actions" header="Дії"
                    style={{ width: 200 }} body={(e) => (
                      <div className={styles.tableButtonContainer}>
                        {e?.children && <Button icon="pi pi-pencil"
                          tooltip="Редагувати"
                          tooltipOptions={{ position: 'top' }}
                          onClick={() => {
                            setEditCategory(true);
                            setCurrentCategory(e);
                          }} />}
                        {!e?.children && <Button icon="pi pi-pencil"
                          tooltip="Редагувати товар"
                          tooltipOptions={{ position: 'top' }}
                          onClick={() => {
                            setEditItem(true);
                            setCurrentCategory(e);
                          }} />}
                        {e?.children && <Button icon="pi pi-list"
                          className="p-button-secondary"
                          tooltip="Додати категорію"
                          tooltipOptions={{ position: 'top' }}
                          onClick={() => {
                            setAddCategoryToParent(
                              true);
                            setCurrentCategory(e);
                          }} />}
                        {e?.children && <Button icon="pi pi-shopping-cart"
                          className="p-button-success"
                          tooltip="Додати товар"
                          tooltipOptions={{ position: 'top' }}
                          onClick={() => {
                            setNewItem(true);
                            setCurrentCategory(e);
                          }} />}
                        {e?.children &&
                          <Button icon="pi pi-trash" className="p-button-danger"
                            tooltip="Видалити категорію"
                            tooltipOptions={{ position: 'top' }}
                            onClick={() => deleteCategory(e.key,
                              e.data.name)} />}
                        {!e?.children &&
                          <Button icon="pi pi-trash" className="p-button-danger"
                            tooltip="Видалити товар"
                            tooltipOptions={{ position: 'top' }}
                            onClick={() => {
                              deleteItem(e.key, e.data.name);
                            }} />}
                      </div>)} />
                </TreeTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;