import React, { useState } from 'react';
import { popupDialog } from '../../../../commons/PopupDialog/PopupDialog';
import { Field, Form, Formik } from 'formik';
import styles from './ReassignManager.module.scss';
import FormInput from '../../../../commons/FormInput';
import { Button } from 'primereact/button';
import { addManagerToTender } from '../../../../services/TenderService';
import { Dropdown } from 'primereact/dropdown';

const ReassignManager = ({ currentTender, allManagers, setIsNewManager, refreshPage }) => {
  const [manager, setManager] = useState(allManagers[0]?.value || '');

  return (
    <Formik
    initialValues={{
      ...currentTender,
      newManager: ''
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        const isSuccess = await addManagerToTender(currentTender._id, manager);
        isSuccess && setIsNewManager(false); refreshPage();
        !isSuccess && popupDialog('Помилка відправлення', () => { }, 'Ok')
      }}

      validateOnChange={false}
      enableReinitialize={true}
    >
      {() => (
        <Form className={styles.formContainer}>
          <Field name={`manager.fullName`}
            label="Менеджер"
            component={FormInput}
            disabled
            required
          />
          <div className={styles.dropdownContainer}>
            <label>
              Новий менеджер
              <span style={{ fontSize: 12, color: '#E51A4B' }}>*</span>
            </label>
            <Dropdown
              value={manager}
              style={{ width: 250 }}
              options={allManagers}
              onChange={(e) => setManager(e.value)}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              label="Зберегти"
            />
            <Button
              type="button"
              label="Відмінити"
              className="p-button-secondary"
              onClick={() => setIsNewManager(false)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReassignManager;