import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import styles from '../UserCard/UserCard.module.scss';
import { Field, Form, Formik } from 'formik';
import { addNewUser } from '../../../services/AdminService';
import { popupDialog } from '../../../commons/PopupDialog/PopupDialog';
import FormInput from '../../../commons/FormInput';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getRootCategories } from '../../../services/CategoriesService';

const AddNewUser = ({ refresh, setRefresh, setAddUser }) => {
  const [role, setRole] = useState(4);
  const [categoriesList, setCategoriesList] = useState([]);
  const [categories, setСategories] = useState([]);

  const createValidationSchema = Yup.object().shape({
    email: Yup
      .string()
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Некоректний email')
      .required("Це поле є обов'язковим"),
    fullName: Yup
      .string()
      .nullable()
      .required("Це поле є обов'язковим"),
    phone: Yup
      .string()
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
      .nullable(),
    additional_phone: Yup
      .string()
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
      .nullable(),
  });

  useEffect(() => {
    (async () => {
      const allCategories = await getRootCategories() || [];
      const preparedCategories = allCategories.map((category) => ({ label: category.name, value: category._id }))
      setCategoriesList(preparedCategories);
    })()
  }, []);

  useEffect(() => {
    if (role === 3) {
      setСategories([{ "_id": categoriesList[0]?.value }]);
    } else {
      setСategories([]);
    }
  }, [role]);

  return (
    <Formik
      initialValues={{
        email: '',
        fullName: '',
        phone: '',
        additional_phone: '',
      }}
      validationSchema={createValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        const isSuccess = addNewUser(values, role, categories);
        isSuccess && setAddUser(false); setRefresh((prev) => !prev);
        !isSuccess && popupDialog('Помилка. Повторіть спробу', () => { }, 'Ок');
      }}
      validateOnChange={false}
      enableReinitialize={true}
    >
      {() => (
        <Form className={styles.formContainer}>
          <Field name={`email`}
            label="Email"
            keyfilter={'email'}
            component={FormInput}
            required
          />
          <Field name={`fullName`}
            label={`ПІБ`}
            component={FormInput}
            required
          />
          <div className={styles.dropdownContainer}>
            <label>
              Роль
              <span style={{ fontSize: 12, color: '#E51A4B' }}>*</span>
            </label>
            <Dropdown
              value={role}
              style={{ width: 250 }}
              options={[
                { label: 'Reader', value: 4 },
                { label: 'Manager', value: 5 },
                { label: 'Admin', value: 6 },
                { label: 'Supplier', value: 3 },
              ]}
              onChange={(e) => setRole(e.value)}
            />
          </div>
          {role === 3 &&
            <div className={styles.dropdownContainer}>
              <label>
                Категория
                <span style={{ fontSize: 12, color: '#E51A4B' }}>*</span>
              </label>
              <Dropdown
                value={categories[0]?._id}
                style={{ width: 250 }}
                options={categoriesList}
                onChange={(e) => setСategories([{ "_id": e.value }])}
              />
            </div>
          }
          <Field name={`phone`}
            label={`Телефон`}
            component={FormInput}
          />
          <Field name={`additional_phone`}
            label={`Дод. номер`}
            component={FormInput}
          />
          <div className={styles.buttonContainer}>
            <Button type="submit" label="Зберегти" />
            <Button label="Відмінити" className="p-button-secondary"
              type="button" onClick={() => setAddUser(false)} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewUser;