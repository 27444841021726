import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: 'AIzaSyAtmH59C7dQZMI1PuWPzxqSs9LMuUV1WVA',
  authDomain: 'tradehub-f39bd.firebaseapp.com',
  databaseURL: 'https://tradehub-f39bd.firebaseio.com',
  projectId: 'tradehub-f39bd',
  storageBucket: 'tradehub-f39bd.appspot.com',
  messagingSenderId: '489298861212',
  appId: '1:489298861212:web:ad13c6d3842682a9742159',
};

firebase.initializeApp(config);

export const myFirebase = firebase;
export const myFirestore = firebase.firestore();
