import React from "react";
import { styles } from "./style";
import style from './MessageBubble.module.scss';
import { BASE_URL } from "../../../constants/serviceConstants";
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentAudioId} from '../../../store/actions/chatActions';

const allowedFileTypes = ['png', 'jpg', 'svg', 'gif'];

const MessageBubble = (props) => {
  let createData = new Date(props.item.created_at);
  const dispatch = useDispatch();
  const currentAudioId = useSelector(state => state.chatReducer.currentAudioId);

  let fullData = {
    day:
      createData.getDate() < 10
        ? `0${createData.getDate()}`
        : createData.getDate(),
    month:
      createData.getMonth() + 1 < 10
        ? `0${createData.getMonth() + 1}`
        : createData.getMonth() + 1,
    year: createData.getFullYear(),
    hour:
      createData.getHours() < 10
        ? `0${createData.getHours()}`
        : createData.getHours(),
    minutes:
      createData.getMinutes() < 10
        ? `0${createData.getMinutes()}`
        : createData.getMinutes(),
  };

  const getFileName = (file) => {
    if(file.filename.length > 20) {
      return `${file?.filename?.slice(0, 20) || ''}.${file?.extension || ''}`;
    }

    return `${file?.filename || ''}.${file?.extension || ''}`;
  };

  return (
    <div style={props.user ? { ...styles.wrapper, ...styles.wrapperAdmin } : { ...styles.wrapper, ...styles.wrapperUser }} >
      <div
        style={props.user ? styles.user : styles.admin}
      >

        <div style={styles.msgContainer}>
          <div style={props.user ? { ...styles.msg, ...styles.right } : { ...styles.msg, ...styles.left }}>
            {
              props?.item?.isAudio ? <div className={props.user ? style.rightAudio : style.leftAudio}>
                    <audio
                        src={`${BASE_URL}${props?.item?.file?.path}`}
                        controls
                        muted={currentAudioId && (props?.item?.file?._id !== currentAudioId)}
                        onPlay={(e) => dispatch(setCurrentAudioId(props?.item?.file?._id))}
                    />
                  </div>
                  : props?.item?.isFile ? <div>
                    <a
                        className={styles.fileWrapper}
                        target="_blank"
                        href={`${BASE_URL}${props?.item?.file?.path}`}
                    >
                      {allowedFileTypes.includes(props?.item?.file?.extension) ? (
                        <div style={{ backgroundImage: `url(${BASE_URL}${props?.item?.file?.path})` }} className={style.img} />
                      ) : (
                        <div className={style.fileContainer}>
                          <div className="pi pi-file-pdf" style={{fontSize: 40}} />
                          <div
                            style={{marginBottom: 10, padding: 10}}
                          >
                            {getFileName(props?.item?.file)}
                          </div>
                        </div>
                      )}
                    </a>
                  </div>
                  : <div>{props?.item?.text}</div>
            }
          </div>
        </div>

        <div style={props.user ? { ...styles.timeContainer, ...styles.right } : { ...styles.timeContainer, ...styles.left }}>
          {props.user ?
            <>
              <p style={styles.time}>{`${fullData.day}.${fullData.month}.${fullData.year}, `}</p>
              <p style={styles.time}>{`${fullData.hour}:${fullData.minutes}`}</p>
              <p style={styles.time}>{`${props.item.user.name || ''} ${props.item.user.surname || ''}`}</p>
            </> :
            <>
              <p style={styles.time}>{`${fullData.day}.${fullData.month}.${fullData.year}, `}</p>
              <p style={styles.time}>{`${fullData.hour}:${fullData.minutes}`}</p>
              <p style={styles.time}>{`${props.item.user.name || ''} ${props.item.user.surname || ''}`}</p>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default MessageBubble;
