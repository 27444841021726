import React from 'react';
import {connect} from 'react-redux';
import Login from '../../components/Login';
import routes from '../../constants/routes';

const LoginPage = ({history}) => {
  const toHomePage = () => {
    history.push(routes.HOME_PAGE);
  };

  const toRestorePasswordPage = () => {
    history.push(routes.RESTORE_PASSWORD_PAGE)
  };

  return (
      <Login
          toHomePage={toHomePage}
          toRestorePasswordPage={toRestorePasswordPage}
      />
  );
};

export default connect()(LoginPage);
