import React, {useState} from 'react';
import styles from './AudioRecord.module.scss'
import {ProgressBar} from 'primereact/progressbar';
import ReactStopwatch from 'react-stopwatch';
import {Button} from 'primereact/button';
import { ReactMic } from 'react-mic';
import {setChatVisibility} from '../../../store/actions/chatActions';
import {useDispatch, useSelector} from 'react-redux';
import {popupDialog} from '../../../commons/PopupDialog/PopupDialog';
import {getUserId} from '../../../store/actions/loginActions';
import axios from 'axios';
import {BASE_URL} from '../../../constants/serviceConstants';

const AudioRecord = ({active, setRecordAudio, tenderId}) => {
 const [isPlay, setIsPlay] = useState(false);
 const [audioData, setAudioData] = useState({});
 const socket = useSelector(state => state.tenderReducer.socket);
 const dispatch = useDispatch();
 const userId = getUserId();

  const sendAudio = async (tender_id, user_id, file) => {
    socket && socket.emit("chatMessage", {
      text: '',
      tender_id,
      user_id,
      file: file ? {
        originalname: 'audio.m4a',
        size: file.size,
        buffer: await file.blob.arrayBuffer(),
      } : {}
    });

    setTimeout(async () => {
      await readMessagesById(user_id, tender_id)
    }, 1000);
  };

  const readMessagesById = async (userId, tenderId) => {

    return await axios.patch(
        `${BASE_URL}/users/${userId}/messages/${tenderId}/read`)
    .then(({data}) => data)
    .catch(err => console.log('error', err))
  };

const handlePlay = () => {
  if(isPlay) {
    return setIsPlay(false);
  } else {
    if(audioData?.blobURL) {
      return popupDialog(
          'Ви дійсно хочете перезаписати аудіо?',
          () => setIsPlay(true),
          'Так',
          () => {},
          'Ні')
    }

    return setIsPlay(true);
  }
};

    return (
        <>
        {
          active && (
              <div className={styles.audioContainer}>
                  <div className={styles.recordControls}>
                    <div className={styles.playerContainer}>
                      <div className={styles.reactMicContainer}>
                        <ReactMic
                            record={isPlay}
                            onStop={(e) => setAudioData(e)}
                            strokeColor="#000000"
                            backgroundColor="#f1f3f4"
                            bitRate={256000}
                            mimeType="audio/wav"
                        />
                      </div>
                      <label>Запис аудіо</label>
                      <div className={styles.recordContainer}>
                        <div
                            className={styles.stopPlayButton}
                            onClick={() => handlePlay()}
                        >
                          {isPlay
                              ? <span style={{fontWeight: 'bold'}}>II</span>
                              : <i className="pi pi-caret-right"/>
                          }
                        </div>
                        {!isPlay ? <div className={styles.timer}>00:00:00</div>
                            : <ReactStopwatch
                                seconds={0}
                                minutes={0}
                                hours={0}
                                limit="00:03:00"
                                onCallback={() => setIsPlay(false)}
                                render={({ formatted }) => {
                                  return (
                                      <div className={styles.timer}>
                                        { formatted }
                                      </div>
                                  );
                                }}
                            />}
                        <ProgressBar
                            mode={isPlay ? "indeterminate" : "determinate"}
                            style={{height: 4.5, width: 120, borderRadius: 3, backgroundColor: '#c1c2c3'}}
                        />
                      </div>
                    </div>
                    <div className={styles.playerContainer}>
                      <label>Прослуховування аудіо</label>
                      <audio src={audioData?.blobURL} controls/>
                    </div>
                    <div className={styles.buttonsContainer}>
                      <Button
                          type="button"
                          className="p-button-secondary"
                          onClick={() => {
                            setRecordAudio(false);
                            setAudioData({});
                            dispatch(setChatVisibility(true))
                          }}
                          label="Відмінити"
                      />
                      <Button
                          type="button"
                          label="Надіслати"
                          onClick={async () => {
                           await sendAudio(tenderId, userId, audioData);
                            setRecordAudio(false);
                            setAudioData({});
                            dispatch(setChatVisibility(true))
                          }}
                          disabled={!audioData?.blobURL}
                      />
                    </div>
                  </div>
              </div>
          )
        }
        </>
    )
};

export default AudioRecord;
