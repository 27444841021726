import React, {useEffect, useState} from 'react';
import TenderCardEdit from '../../components/Home/TenderCardEdit';
import {getUserId} from '../../store/actions/loginActions';
import {getUserById} from '../../services/UserService';
import {setUserRole} from '../../store/actions/usersActions';
import {useDispatch, useSelector} from 'react-redux';

const TenderCardEditPage = ({history}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const id = getUserId();
      const role = await getUserById(id) || [];
      dispatch(setUserRole(role));
    })()
  }, []);

  const getName = (name) => {
    if (name.length > 10) {
      return name.slice(0, 10);

    }
    return name;

  };

  const tenderStatusData = [
    {label: 'Новий. Очікує обробки'},
    {label: 'Вимагає підтвердження'},
    {label: 'Підтверджений'},
    {label: 'Вибір постачальника'},
    {label: 'Договір'},
    {label: 'Доставка очікується'},
    {label: 'Доставка успішна'},
  ];

  const statusCode = status => {
    switch (status) {
      case "Новий. Очікує обробки":
        return 'new';
      case "Вибір постачальника":
        return 'offerSelection';
      case "Вимагає підтвердження":
        return 'waitingToBeConfirmed';
      case "Договір":
        return 'contract';
      case "Доставка очікується":
        return 'delivery';
      case "Підтверджений":
        return 'confirmed';
      case "Доставка успішна":
        return 'successDelivery';
      default:
        break;
    }
  };

  return (
      <TenderCardEdit
          tenderStatusData={tenderStatusData}
          getName={getName}
          history={history}
          statusCode={statusCode}
      />
  );
};

export default TenderCardEditPage;
