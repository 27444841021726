// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormPassword_container__3iTUK {\n  position: relative;\n  margin-bottom: 15px; }\n  .FormPassword_container__3iTUK .FormPassword_formLabel__1Zgzl {\n    width: 100%;\n    font-weight: 700;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: flex-start;\n    flex-wrap: nowrap; }\n\n.FormPassword_formError__3cHIT {\n  position: absolute;\n  top: 62px;\n  font-size: 12px;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  white-space: nowrap;\n  color: #E51A4B; }\n", ""]);
// Exports
exports.locals = {
	"container": "FormPassword_container__3iTUK",
	"formLabel": "FormPassword_formLabel__1Zgzl",
	"formError": "FormPassword_formError__3cHIT"
};
module.exports = exports;
