import {
  SET_TENDERS_FOR_SUPPLIER,
  SET_ALL_SUPPLIERS,
  SET_ACCEPTED_TENDERS_FOR_SUPPLIER,
  SET_SENT_FOR_SUPPLIER,
  SET_DISABLED_ACCEPTED_TENDERS_FOR_SUPPLIER
} from '../constants/actionTypes';

const initialState = {
  allTenders: [],
  allAcceptedTenders: [],
  suppliersList: [],
  disabledAcceptedTenders: []
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TENDERS_FOR_SUPPLIER:
      return { ...state, allTenders: action.payload };
    case SET_ACCEPTED_TENDERS_FOR_SUPPLIER:
      return { ...state, allAcceptedTenders: action.payload };
    case SET_ALL_SUPPLIERS:
      return { ...state, suppliersList: action.payload };
    case SET_SENT_FOR_SUPPLIER:
      return {
        ...state,
        allAcceptedTenders: state.allAcceptedTenders.map((acceptedTender) => {
          if (acceptedTender.offer_id === action.id) {
            return {
              ...acceptedTender,
              offer_sent: !acceptedTender.offer_sent
            }
          }

          return acceptedTender;
        })
      };
    case SET_DISABLED_ACCEPTED_TENDERS_FOR_SUPPLIER:
      return { ...state, disabledAcceptedTenders: action.payload };

    default:
      return state;
  }
};

export default supplierReducer;