import {
  SET_ALL_NOTIFICATIONS,
  SET_CHAT_NOTIFICATIONS,
  SET_NOTIFICATION_IS_VISIBLE, SET_UNREAD_NOTIFICATIONS,
} from '../constants/actionTypes';

export const setChatNotifications = (chatId) => ({type: SET_CHAT_NOTIFICATIONS, payload: chatId});
export const setUnreadNotifications = (count) => ({type: SET_UNREAD_NOTIFICATIONS, payload: count});
export const setAllNotifications = (notifications) => ({type: SET_ALL_NOTIFICATIONS, payload: notifications});

export const setNotificationsIsVisible = (boolean) => ({type: SET_NOTIFICATION_IS_VISIBLE, payload: boolean});
