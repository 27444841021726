import React, {useState} from 'react';
import {editProduct} from '../../../services/CategoriesService';
import {popupDialog} from '../../../commons/PopupDialog/PopupDialog';
import {Field, Form, Formik} from 'formik';
import styles from '../Categories.module.scss';
import FormInput from '../../../commons/FormInput';
import {Button} from 'primereact/button';
import * as Yup from 'yup';

const EditItem = ({setRefresh, refresh, setEditItem, currentCategory}) => {
  const [descriptionValue, setDescriptionValue] = useState(currentCategory?.data?.description || '');
  const [noteValue, setNoteValue] = useState(currentCategory?.data?.note || '');

  const goodsValidationSchema = Yup.object().shape({
    newGoods: Yup
    .string()
    .required("Це поля є обов'язковим"),
    measureUnit: Yup
    .string()
    .required("Це поля є обов'язковим"),
  });

  return (
      <Formik
          initialValues={{
            newGoods: currentCategory?.data?.name || '',
            measureUnit: currentCategory?.data?.measureUnit || '',
          }}
          validationSchema={goodsValidationSchema}
          onSubmit={async (values, {setSubmitting}) => {
            setSubmitting(false);
            const isSuccess = await editProduct(values, currentCategory?.key, descriptionValue, noteValue);
            isSuccess && setEditItem(false);
            setRefresh(!refresh);

          }}
          validateOnChange={false}
      >
        {() => (
            <Form className={styles.container}>
              <Field name={`newGoods`}
                     label="Товар"
                     component={FormInput}
                     required
              />
              <Field name={`measureUnit`}
                     label="Одиниця вимірювання"
                     component={FormInput}
                     required
              />
              <div className={styles.textareaContainer}>
                <label>Опис</label>
                <textarea
                    style={{width: 250, height: 100}}
                    value={descriptionValue}
                    onChange={e => setDescriptionValue(e.target.value)}
                />
              </div>
              <div className={styles.textareaContainer}>
                <label>Примітка</label>
                <textarea
                    value={noteValue}
                    onChange={e => setNoteValue(e.target.value)}
                    style={{width: 250, height: 100}}
                />
              </div>
              <div className={styles.submitButtonContainer}>
                <Button type="submit" label="Зберегти"/>
                <Button label="Відмінити" type="button" className="p-button-secondary"
                        onClick={() => setEditItem(false)}/>
              </div>
            </Form>
        )}
      </Formik>
  );
};

export default EditItem;