import React, {useState} from 'react';
import Header from '../../Header';
import styles from './AddClient.module.scss';
import {Field, Form, Formik} from 'formik';
import FormInput from '../../../commons/FormInput';
import {Button} from 'primereact/button';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {addNewClient} from '../../../services/ClientService';
import {popupDialog} from '../../../commons/PopupDialog/PopupDialog';
import {Dropdown} from 'primereact/dropdown';

const AddClient = ({setAddClient, refresh, setRefresh}) => {
  const [role, setRole] = useState(0);
  const currentClient = useSelector(state => state.clientsReducer.currentClient);

  const validationSchema = Yup.object().shape({
    email: Yup
      .string()
      .email()
      .required("Це поле є обов'язковим"),
    fullName: Yup
      .string()
      .required("Це поле є обов'язковим"),
    phone: Yup
      .string('Некоректний номер')
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
      .nullable(),
    additional_phone: Yup
      .string('Некоректний номер')
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
      .nullable(),
  });

  return (
            <Formik
                initialValues={{
                  fullName: '',
                  email: '',
                  phone: '',
                  additional_phone: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, {setSubmitting}) => {
                  setSubmitting(false);
                  const isSuccess = await addNewClient(values, currentClient, role);
                  isSuccess && setAddClient(false); setRefresh(!refresh);
                  !isSuccess && popupDialog('Помилка. Повторіть спробу', () => {}, 'Ок');
                  setRefresh(!refresh);
                }}
                validateOnChange={false}
                enableReinitialize={true}
                validateOnMount={true}
            >
              {() => (
                  <Form className={styles.formContainer}>
                    <Field name={`fullName`}
                           label="ПІБ"
                           component={FormInput}
                           required
                    />
                    <Field name={`email`}
                           label="Email"
                           keyfilter={'email'}
                           component={FormInput}
                           required
                    />
                    <div className={styles.dropdownContainer}>
                      <label style={{fontSize: 16, fontWeight: 'bold'}}>
                        Роль
                        <span style={{fontSize: 12, color: '#E51A4B'}}>*</span>
                      </label>
                      <Dropdown
                          value={role}
                          style={{width: 250}}
                          options={[
                            {label: 'User', value: 0},
                            {label: 'UserFull', value: 1},
                          ]}
                          onChange={(e) => setRole(e.value)}
                      />
                    </div>
                    <Field name={`phone`}
                           label="Телефон"
                           component={FormInput}
                    />
                    <Field name={`additional_phone`}
                           label="Дод. телефон"
                           component={FormInput}
                    />
                    <div className={styles.buttonContainer}>
                      <Button
                          type="button"
                          label="Відмінити"
                          onClick={() => setAddClient(false)}
                          className="p-button-secondary"
                      />
                      <Button type="submit" label="Зберегти"/>
                    </div>
                  </Form>
              )}
            </Formik>
  );
};

export default AddClient;