import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import styles from '../TenderCardEdit.module.scss';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { getUserRole } from '../../../../store/actions/loginActions';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { addItemToTender, getTenderById, removeItem } from '../../../../services/TenderService';
import { popupDialog } from '../../../../commons/PopupDialog/PopupDialog';
import { useDispatch, useSelector } from 'react-redux';
import { addTenderItem, deleteTenderItem } from '../../../../store/actions/tendersActions';
import { uuid } from 'uuidv4';
import { getRootCategories, getItemsFromCategory } from '../../../../services/CategoriesService';
import { getItemById } from '../../../../services/TenderService';

const AddCategory = ({
  tenderId, status, setCategories, setPreloaderIsActive,
  setAddCategory, isAddCategory, categories, setEditItemActive,
  setCurrentItem, setAddSuppliers, setAmount, setNewCategory, setNewItem, setNewItemId, setSelectedSupplier
}) => {
  const dispatch = useDispatch();
  const currentTender = useSelector(state => state.tenderReducer.currentTender);
  const [newAddCategory, setNewAddCategory] = useState('');
  const [newAddItem, setNewAddItem] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [newAmount, setNewAmount] = useState('');
  const userRole = Number(getUserRole() || 4);
  const formValidation = !!newAmount * !!newAddCategory * !!newAddItem * !!maxPrice;
  const [rootCategories, setRootCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    (async () => {
      const allCategories = await getRootCategories() || [];
      const preparedCategories = allCategories.map((category) => ({ label: category.name, value: category._id }))
      setRootCategories(allCategories);
      setCategoriesList(preparedCategories);
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (newAddCategory) {
        const allItemsFromCategory = await getItemsFromCategory(newAddCategory) || [];
        // const preparedItemsFromCategory = allItemsFromCategory.map((category) => ({ label: category.name, value: category._id }))
        const preparedItemsFromCategoryObj={}
        allItemsFromCategory.forEach((category) => { preparedItemsFromCategoryObj[category.name]=category._id});
        const preparedItemsFromCategory=[];
        for (const [key, value] of Object.entries(preparedItemsFromCategoryObj)) {
          preparedItemsFromCategory.push({label:key, value: value})
          console.log(`${key}: ${value}`);
        }
        setItemsList(preparedItemsFromCategory);
      }
    })()
  }, [newAddCategory]);

  const addNewSuppliersWithCurrentItem = (e) => {
    setAddSuppliers(true);
    setAmount(e?.amount);
    setNewCategory(e?.item?.category?.name);
    setNewItem(e?.item?.name);
    setNewItemId(e?._id);
    setSelectedSupplier('');
  };

  //   const goodsList = categoriesList
  //     .filter(category => category?.value === newAddCategory)[0]?.items
  //     .map(item => ({ label: item.label, value: item.value }));
  // console.log(categoriesList);
  const handleAddCategory = async (tenderId, amount, maxPrice) => {
    if (!formValidation) {
      return popupDialog('Всі поля мають бути заповнені', () => { }, 'Ок')
    }
    setPreloaderIsActive(true);
    // const currentItemId = allCategories
    // .find(category => category?.name === newAddCategory)?.items
    // .find(item => item.name === newAddItem)._id;
    const resetForm = () => {
      setAddCategory(false);
      setNewAddCategory('');
      setNewAddItem('');
      setNewAmount('');
      setMaxPrice('');
    };
    // const isSuccess = await addItemToTender(currentItemId, tenderId, amount, maxPrice);
    const currentCategory = rootCategories.find(category => category?._id === newAddCategory);
    // const currentItem = currentCategory.items.find(item => item._id === newAddItem);

    const currentItem = await getItemById(newAddItem);

    dispatch(addTenderItem({
      _id: `new${uuid()}`,
      maxPrice: Number(maxPrice),
      amount: Number(amount),
      category: {
        _id: currentCategory._id,
      },
      item: {
        ...currentItem,
        category: {
          _id: currentCategory._id,
          name: currentCategory.name,
        }
      },
      offers: []
    }));

    resetForm();

    const currentCategories = await getTenderById(tenderId);
    setCategories(currentCategories);
    // if (isSuccess) {
    //   resetForm();

    //   const currentCategories = await getTenderById(tenderId);
    //   setCategories(currentCategories);
    // } else {
    //   popupDialog('Помилка. Товар не збережений', () => resetForm(), 'Ок');
    // }
    setPreloaderIsActive(false);
  };

  const removeCurrentItem = (tenderId, e) => {
    const remove = async () => {
      setPreloaderIsActive(true);

      // const isSuccess = await removeItem(tenderId, e?._id);
      const newItems = currentTender.items.filter(item => item._id !== e?._id);

      dispatch(deleteTenderItem(newItems));

      // if (isSuccess) {
      //   const currentCategories = await getTenderById(tenderId);
      //   setCategories(currentCategories);
      //   setAddSuppliers(false);
      // } else {
      //   popupDialog(`Помилка. Повторіть спробу`, () => { }, 'Ок');
      // }

      setPreloaderIsActive(false);
    };

    popupDialog(`Ви дійсно хочете видалити товар ${e?.item?.name} ${e?.amount}?`, async () => await remove(), 'Так', () => { }, 'Ні');
  };

  const categoryFooter = (
    isAddCategory && (
      <ColumnGroup>
        <Row>
          <Column style={{ width: 65 }} footer={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <i
                className={"pi pi-star"}
                style={{ color: "grey" }}
              />
            </div>
          } />
          <Column footer={<div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
            <Button icon="pi pi-check" type={isAddCategory ? "submit" : "button"} tooltip="Зберегти" tooltipOptions={{ position: 'top' }} onClick={async () => { setAddCategory(false); await handleAddCategory(tenderId, newAmount, maxPrice) }} />
            <Button icon="pi pi-times" type="button" className="p-button-danger" tooltip="Видалити" tooltipOptions={{ position: 'top' }} onClick={() => setAddCategory(false)} />
          </div>}
          />
          <Column footer={<div>
            <Dropdown
              style={{ width: '100%' }}
              options={categoriesList}
              onChange={e => setNewAddCategory(e.value)}
              value={newAddCategory}
              filter={true}
              showClear={true}
              filterPlaceholder="Виберіть категорію"
              filterBy="label,value"
            />
          </div>}
          />
          <Column footer={<div>
            <Dropdown
              style={{ width: '100%' }}
              options={itemsList}
              onChange={e => setNewAddItem(e.value)}
              value={newAddItem}
              filter={true}
              showClear={true}
              filterPlaceholder="Виберіть товар"
              filterBy="label"
              filterMatchMode={'contain'}
              disabled={!newAddCategory}
              resetFilterOnHide={true}
            />
          </div>}
          />
          <Column footer={<div>
            <InputText
              style={{ width: '100%' }}
              value={newAmount}
              onChange={e => setNewAmount(e.target.value.replace(/\D/, ''))}
              disabled={!newAddItem}
            />
          </div>}
          />
          <Column footer={<div>
            <InputText
              style={{ width: '100%' }}
              value={maxPrice}
              onChange={e => setMaxPrice(e.target.value.replace(/\D/, ''))}
              disabled={!newAddItem}
            />
          </div>}
          />
          <Column footer={<div></div>} />
        </Row>
      </ColumnGroup>
    )
  );

  return (
    <DataTable value={currentTender?.items} className={styles.table} footerColumnGroup={categoryFooter}>
      <Column columnKey='actions' header="" style={{ width: 65 }} body={(e) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <i
            className={"pi pi-star"}
            style={{ color: "grey" }}
          />
        </div>
      )} />
      <Column columnKey='actions' header="Дії" style={{ width: 130 }} body={(e) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: status > 1 ? 'space-between' : 'space-around' }}>
          <Button icon="pi pi-pencil" type="button"
            onClick={() => { setEditItemActive(true); setCurrentItem(e) }}
            disabled={userRole === 4}
            tooltip="Редагувати" tooltipOptions={{ position: 'top' }} />
          <Button className="p-button-danger" icon="pi pi-trash" type="button"
            onClick={() => removeCurrentItem(tenderId, e)}
            disabled={userRole === 4}
            tooltip="Видалити" tooltipOptions={{ position: 'top' }} />
          {status > 1 &&
            (
              <Button icon="pi pi-plus" type="button" className="p-button-success"
                onClick={() => { addNewSuppliersWithCurrentItem(e); window.scrollTo({ top: 1050, behavior: "smooth" }); setAddCategory(false) }}
                disabled={userRole === 4}
                tooltip="Вибрати постачальника" tooltipOptions={{ position: 'top' }} />
            )
          }
        </div>
      )} />
      <Column field="item.category.name" header="Категорія" sortable style={{ width: '30%' }} />
      <Column field="item.name" header="Товар" sortable style={{ width: '30%' }} />
      <Column field={"amount"} header="Кількість" sortable style={{ width: 100 }} />
      <Column field="maxPrice" header="Макс. ціна" sortable style={{ width: 100 }} />
      <Column field="item.measureUnit" header="Величина" sortable style={{ width: 100 }} />
    </DataTable>
  );
};

export default AddCategory;
