import React from 'react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import styles from './SettingsPopup.module.scss'

const SettingsPopup = ({
                          visible = false, headerText = '', onSubmit = () => {},
                          onHide = () => {}, saveLabel = null, cancelLabel = 'Відмінити',
                          children = null
}) => {

  const footer = (
      <div className={styles.buttonsContainer}>
        {saveLabel && <Button label={saveLabel} type="submit" onClick={onSubmit} />}
        <Button label={cancelLabel} className="p-button-secondary" type="button" onClick={onHide} />
      </div>
  );

  return (
      <Dialog
          onHide={onHide}
          visible={visible}
          header={headerText}
          // footer={footer}
          className={styles.popupContainer}
      >
        <div className={styles.contentContainer}>
          {children}
        </div>
      </Dialog>
  );
};

export default SettingsPopup;