import React, { useEffect, useRef, useState } from 'react';
import styles from '../TenderCardEdit.module.scss';
import {
  attachContractsToTender,
  changeStatusById,
  getTenderById,
  removeFileById,
  updateTender,
} from '../../../../services/TenderService';
import { popupDialog } from '../../../../commons/PopupDialog/PopupDialog';
import routes from '../../../../constants/routes';
import { Field, Form, Formik } from 'formik';
import FormInput from '../../../../commons/FormInput';
import FormCalendar from '../../../../commons/FormCalendar';
import { BASE_URL } from '../../../../constants/serviceConstants';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Steps } from 'primereact/steps';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId, getUserRole } from '../../../../store/actions/loginActions';
import ChatRoma from '../../../../components/ChatRoma/ChatRoma';
import SettingsPopup from '../../../../commons/SettingsPopup/SettingsPopup';
import EditSupplier from '../EditSupplier/EditSupplier';
import AddCategory from '../AddCategory/AddCategory';
import AddSuppliers from '../AddSuppliers/AddSuppliers';
import EditItem from '../EditItem/EditItem';
import {
  setNotificationsIsVisible,
  setUnreadNotifications,
} from '../../../../store/actions/notificationsActions';
import { setChatVisibility } from '../../../../store/actions/chatActions';
import { setAllSuppliers } from '../../../../store/actions/supplierActions';
import AudioRecord from '../../../ChatRoma/AudioRecord/AudioRecord';
import { getUserNotificationById } from '../../../../services/UserService';
import { getAllUsers } from '../../../../services/AdminService';
import axios from 'axios';
import {
  setCurrentTenderData,
  addTenderContracts,
  deleteTenderFile,
  deleteTenderContract,
  changeTenderStatus,
  setCurrentTenderCopy,
} from '../../../../store/actions/tendersActions';
import { uuid } from 'uuidv4';

const PINNED_FILES = 'PINNED_FILES';
const AUTH_TOKEN = 'auth-token';

const TenderCard = ({
  setRefresh,
  tenderId,
  setPreloaderIsActive,
  setCategories,
  refresh,
  descriptionValue,
  setAdditionalConditions,
  additionalConditions,
  setDescriptionValue,
  categories,
  setStatusCode,
  currentTender,
  history,
  getName,
  allCategories,
}) => {
  const uploader = useRef(null);
  const userRole = Number(getUserRole() || 4);
  const userId = getUserId();
  const prevPage = useSelector((state) => state.tenderReducer.prevPage);
  const socket = useSelector((state) => state.tenderReducer.socket);
  const suppliersList = useSelector(
    (state) => state.supplierReducer.suppliersList
  );
  const currentTenderCopy = useSelector(
    (state) => state.tenderReducer.currentTenderCopy
  );
  const dispatch = useDispatch();
  const [isChatActive, setChatActive] = useState(false);
  const [isEditSupplierActive, setEditSupplierActive] = useState(false);
  const [isEditItemActive, setEditItemActive] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState(null);
  const [amount, setAmount] = useState('');
  const [newItem, setNewItem] = useState('');
  const [newItemId, setNewItemId] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [isAddSuppliers, setAddSuppliers] = useState(false);
  const [isAddCategory, setAddCategory] = useState(false);
  const [recordAudio, setRecordAudio] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [supplierItem, setSupplierItem] = useState('');
  const [supplierComment, setSupplierComment] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');

  useEffect(() => {
    (async () => {
      const tender = await getTenderById(tenderId);
      dispatch(setCurrentTenderCopy(tender));
    })();

    return () => {
      dispatch(setCurrentTenderCopy({}));
    };
  }, []);

  useEffect(() => {
    (async () => {
      const allUsers = (await getAllUsers()) || [];
      const suppliers = allUsers.filter((user) => user.role.role === 3);
      dispatch(setAllSuppliers(suppliers));
    })();
  }, []);

  const tenderStatusData = [
    { label: 'Новий. Очікує обробки' },
    { label: 'Вимагає підтвердження' },
    { label: 'Підтверджений' },
    { label: 'Вибір постачальника' },
    { label: 'Документи' },
    { label: 'Доставка очікується' },
    { label: 'Доставка успішна' },
  ];

  const getUnreadMessageAmountById = async (userId) => {
    return await axios
      .get(`${BASE_URL}/users/${userId}/messages/unread`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      })
      .then(({ data }) => data)
      .catch((err) => console.log('error', err));
  };

  const readMessagesById = async (userId, tenderId) => {
    return await axios
      .patch(
        `${BASE_URL}/users/${userId}/messages/${tenderId}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        }
      )
      .then(({ data }) => data)
      .catch((err) => console.log('error', err));
  };

  const statusCode = (status) => {
    switch (status) {
      case 'Новий. Очікує обробки':
        return 'new';
      case 'Вибір постачальника':
        return 'offerSelection';
      case 'Вимагає підтвердження':
        return 'waitingToBeConfirmed';
      case 'Документи':
        return 'contract';
      case 'Доставка очікується':
        return 'delivery';
      case 'Підтверджений':
        return 'confirmed';
      case 'Доставка успішна':
        return 'successDelivery';
      default:
        break;
    }
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string(),
    internal_id: Yup.string(),
    external_id: Yup.string().nullable().matches(/\d/im, 'Некоректний номер'),
    name: Yup.string(),
    date_start: Yup.string(),
    date_end: Yup.string(),
    bargain_end_date: Yup.string(),
  });

  const uploadHandler = async (e) => {
    const requestSize = e?.files?.reduce((acc, item) => acc + item.size, 0);
    if (requestSize < 9900000) {
      const attachFiles = async (e) => {
        setPreloaderIsActive(true);
        // const filesToAdd = e?.files?.map((file) => {
        //   const url = URL.createObjectURL(file);

        //   return ({
        //     _id: uuid(),
        //     filename: file.name,
        //     path: url,
        //     extension: file.type,
        //     metadata: null
        //   });
        // });

        const filesToAdd = await attachContractsToTender(tenderId, e.files);

        uploader.current.clear();
        if (filesToAdd) {
          uploader.current.clear();
          dispatch(addTenderContracts(filesToAdd));
          // const currentCategories = await getTenderById(tenderId);
          // setCategories(currentCategories);
          // popupDialog('Дані успішно збережені', () => { }, 'Ок');
        }

        setPreloaderIsActive(false);
      };

      popupDialog(
        'Ви дійсно хочете прикріпити файл(и)?',
        () => attachFiles(e),
        'Прикріпити',
        () => {}
      );
    } else {
      popupDialog(
        'Сумарний розмір файлів не повинен перевищувати 10мб',
        () => {},
        'Ок'
      );
    }
  };

  const changeStatus = (e) => {
    const newStatus = async (e) => {
      setPreloaderIsActive(true);
      // const isSuccess = await changeStatusById(tenderId, statusCode(e.item?.label));
      const changedStatus = statusNumber(e.item?.label);

      dispatch(changeTenderStatus(changedStatus));

      // if (!isSuccess) {
      //   popupDialog('Помилка. Статус не змінено', () => { }, 'Ок');
      // } else {
      //   const notifications = await getUserNotificationById(userId) || [];
      //   const unreadNotification = notifications.filter(item => !item.read)?.length;
      //   dispatch(setUnreadNotifications(unreadNotification));
      //   setStatusCode(e.index);
      // }

      setPreloaderIsActive(false);
    };
    if (e.item.label !== currentTender.status_code) {
      popupDialog(
        `Ви дійсно хочете змінити статус на "${e.item.label}"?`,
        async () => await newStatus(e),
        'Так',
        () => {},
        'Ні'
      );
    }
  };

  const statusNumber = (status) => {
    switch (status) {
      case 'Новий. Очікує обробки':
        return 0;
      case 'Вибір постачальника':
        return 3;
      case 'Вимагає підтвердження':
        return 1;
      case 'Документи':
        return 4;
      case 'Доставка очікується':
        return 5;
      case 'Підтверджений':
        return 2;
      case 'Доставка успішна':
        return 6;
      default:
        break;
    }
  };

  const removeFile = (id, name, type) => {
    const remove = async () => {
      setPreloaderIsActive(true);

      const isSuccess = await removeFileById(id, tenderId);

      if (isSuccess) {
        if (type === PINNED_FILES) {
          const fileToDelete = currentTender?.files?.filter(
            ({ _id }) => _id !== id
          );
          dispatch(deleteTenderFile(fileToDelete));
        } else {
          const contractToDelete = currentTender?.contracts?.filter(
            ({ _id }) => _id !== id
          );
          dispatch(deleteTenderContract(contractToDelete));
        }
      } else {
        popupDialog(`Помилка. Повторіть спробу`, () => {}, 'Ок');
      }

      setPreloaderIsActive(false);
    };

    popupDialog(
      `Ви дійсно хочете видалити договір ${name}?`,
      async () => {
        await remove();
      },
      'Видалити',
      () => {}
    );
  };

  useEffect(() => {
    if (socket) {
      socket.emit('chatNotificationsSubscribe', {
        tender_id: tenderId,
      });

      socket.on('chatNotification', () => {
        setUnreadMessages((unreadMessages) => unreadMessages + 1);
      });
    }

    return () => {
      if (socket) {
        socket.emit('chatNotificationsUnsubscribe', {
          tender_id: tenderId,
        });
        socket.off('chatNotification');
        socket.off('chatNotificationsSubscribe');
      }
    };
  }, [socket]);

  useEffect(() => {
    (async () => {
      const allUnreadMessages =
        (await getUnreadMessageAmountById(userId)) || [];
      const allUnreadMessagesKeysValues = Object.entries(allUnreadMessages);
      const tenderUnreadMessages = allUnreadMessagesKeysValues?.find(
        (item) => item[0] === tenderId
      );
      setUnreadMessages((tenderUnreadMessages && tenderUnreadMessages[1]) || 0);
    })();
  }, [refresh]);
  const isFormHasChanges = (values, descriptionValue, additionalConditions) => {
    const compare = (a, b) => (a._id > b._id ? 1 : b._id > a._id ? -1 : 0);

    const prevTender = {
      name: currentTenderCopy?.name,
      description: currentTenderCopy?.description,
      additional_conditions: currentTenderCopy?.additional_conditions,
      date_end: new Date(currentTenderCopy?.date_end).toISOString(),
      bargain_end_date: new Date(
        currentTenderCopy?.bargain_end_date
      ).toISOString(),
      external_id: currentTenderCopy?.external_id,
      goods_delivery_address: currentTenderCopy?.goods_delivery_address,
      documents_delivery_address: currentTenderCopy?.documents_delivery_address,
      max_price: currentTenderCopy?.max_price,
      files: currentTenderCopy?.files.sort(compare),
      items: currentTenderCopy?.items.sort(compare),
      contracts: currentTenderCopy?.contracts.sort(compare),
      status_code: currentTenderCopy?.status_code,
    };
    const newTender = {
      name: values?.name,
      description: descriptionValue,
      additional_conditions: additionalConditions,
      date_end: values?.date_end,
      bargain_end_date: values?.bargain_end_date,
      external_id: values?.external_id,
      goods_delivery_address: values?.goods_delivery_address,
      documents_delivery_address: values?.documents_delivery_address,
      max_price: values?.max_price,
      files: values?.files.sort(compare),
      items: values?.items.sort(compare),
      contracts: values?.contracts.sort(compare),
      status_code: values?.status_code,
    };

    newTender.items.forEach((item) => {
      if (item._id.startsWith('new')) {
        delete item._id;
      }

      item.offers.forEach((offer) => {
        if (offer._id.startsWith('new')) {
          delete offer._id;
        }
      });
    });

    if (currentTender.audio) {
      newTender.files = [...newTender.files, currentTender.audio];
    }

    if (JSON.stringify(prevTender) === JSON.stringify(newTender)) {
      return false;
    }

    return newTender;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...currentTender,
        company_name: currentTender?.company?.name,
        phone: currentTender?.company?.phone,
        date_start: new Date(
          JSON.parse(JSON.stringify(currentTender?.date_start || '')) ||
            new Date()
        ),
        date_end: new Date(
          JSON.parse(JSON.stringify(currentTender?.date_end || '')) ||
            new Date()
        ),
        created_at: new Date(
          JSON.parse(JSON.stringify(currentTender?.created_at || '')) ||
            new Date()
        ),
        updated_at: new Date(
          JSON.parse(JSON.stringify(currentTender?.updated_at || '')) ||
            new Date()
        ),
        bargain_end_date: new Date(
          JSON.parse(JSON.stringify(currentTender?.bargain_end_date || '')) ||
            new Date()
        ),
        type: currentTender?.type || 'Закупівля',
        goods_delivery_address:
          currentTender?.goods_delivery_address ||
          currentTender?.company?.goods_delivery_address,
        documents_delivery_address:
          currentTender?.documents_delivery_address ||
          currentTender?.company?.documents_delivery_address,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        const newTenderData = isFormHasChanges(
          values,
          descriptionValue,
          additionalConditions
        );

        if (newTenderData) {
          const isSuccess = updateTender(currentTender._id, newTenderData);
          isSuccess &&
            popupDialog(
              'Дані успішно збережені',
              () => history.push(routes.HOME_PAGE),
              'Ок'
            );
        } else {
          popupDialog('Введіть зміни', () => {}, 'Ок');
        }
      }}
      validateOnChange={false}
    >
      {() => (
        <>
          <Form className={styles.formikContainer}>
            {userRole !== 4 && (
              <div
                className={styles.chatButton}
                onClick={async () => {
                  if (userId) {
                    dispatch(setChatVisibility(true));
                    dispatch(setNotificationsIsVisible(false));
                    await readMessagesById(userId, tenderId);
                    const notifications =
                      (await getUserNotificationById(userId)) || [];
                    const unreadNotification = notifications.filter(
                      (item) => !item.read
                    )?.length;
                    dispatch(setUnreadNotifications(unreadNotification));
                    setRefresh(!refresh);
                  }
                }}
              >
                <i
                  className='pi pi-comment'
                  style={{ color: '#fff', fontSize: 40 }}
                />
                {!!unreadMessages && (
                  <div className={styles.messageCounter}>
                    {unreadMessages > 9 ? '9+' : unreadMessages}
                  </div>
                )}
              </div>
            )}
            <SettingsPopup
              visible={isEditSupplierActive}
              onHide={() => setEditSupplierActive(false)}
              headerText='Редагування постачальника'
              cancelLabel='Відмінити'
              saveLabel='Зберегти'
            >
              <EditSupplier
                setEditSupplierActive={setEditSupplierActive}
                currentSupplier={currentSupplier}
                allCategories={allCategories}
                setRefresh={setRefresh}
                refresh={refresh}
                newItemId={newItemId}
                suppliersList={suppliersList}
              />
            </SettingsPopup>
            <SettingsPopup
              visible={isEditItemActive}
              onHide={() => setEditItemActive(false)}
              headerText='Редагування товару'
              cancelLabel='Відмінити'
              saveLabel='Зберегти'
            >
              <EditItem
                isEditItemActive={isEditItemActive}
                currentItem={currentItem}
                setEditItemActive={setEditItemActive}
                allCategories={allCategories}
                setRefresh={setRefresh}
                tenderId={tenderId}
                refresh={refresh}
              />
            </SettingsPopup>
            <div className={styles.mainInfoContainer}>
              <label>
                <b>Основна інформація</b>
              </label>
              <div className={styles.formContainer}>
                <div className={styles.inputContainer}>
                  <Field
                    name={`company_name`}
                    label='Назва компанії'
                    component={FormInput}
                    disabled
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`internal_id`}
                    label='Номер заявки 1'
                    component={FormInput}
                    disabled
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`external_id`}
                    label='Номер заявки 2'
                    component={FormInput}
                    disabled={userRole === 4}
                    saveToRedux
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`created_at`}
                    label='Дата створення'
                    component={FormCalendar}
                    showTime
                    disabled
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`updated_at`}
                    label='Дата оновлення'
                    component={FormCalendar}
                    disabled
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`name`}
                    label='Назва заявки'
                    component={FormInput}
                    disabled={userRole === 4}
                    saveToRedux
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`date_start`}
                    label='Дата початку'
                    component={FormCalendar}
                    disabled
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`date_end`}
                    label='Дата завершення'
                    component={FormCalendar}
                    disabled={userRole === 4}
                    saveToRedux
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`bargain_end_date`}
                    label='Дата завершення торгів'
                    component={FormCalendar}
                    disabled={userRole === 4}
                    saveToRedux
                    minDate={new Date()}
                    maxDate={
                      new Date(
                        JSON.parse(
                          JSON.stringify(currentTender?.date_end || '')
                        )
                      )
                    }
                    showTime
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`type`}
                    label='Вид замовлення'
                    component={FormInput}
                    disabled
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`goods_delivery_address`}
                    label='Адреса доставки товарів'
                    component={FormInput}
                    disabled={userRole === 4}
                    saveToRedux
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Field
                    name={`documents_delivery_address`}
                    label='Адреса доставки документів'
                    component={FormInput}
                    disabled={userRole === 4}
                    saveToRedux
                  />
                </div>
              </div>
            </div>
            <div className={styles.filesContainer}>
              <label>
                <b>Прикріплені файли</b>
              </label>
              <div className={styles.filesForm}>
                {!!currentTender?.audio && (
                  <audio
                    src={`${BASE_URL}${currentTender.audio?.path}`}
                    controls
                  />
                )}
                {!!currentTender?.files?.length && (
                  <div className={styles.files}>
                    {currentTender?.files?.map((file, index) => {
                      const fileName = `${getName(file.filename)}.${
                        file.extension
                      }`;
                      return (
                        <div
                          style={{ position: 'relative', width: 100 }}
                          key={index}
                        >
                          <i
                            className='pi pi-times'
                            style={{
                              position: 'absolute',
                              fontSize: 12,
                              zIndex: 1,
                              color: '#e51a4b',
                              top: 7,
                              right: 20,
                            }}
                            onClick={() =>
                              userRole !== 4 &&
                              removeFile(file._id, file.filename, PINNED_FILES)
                            }
                          />
                          <a
                            className={styles.fileWrapper}
                            target='_blank'
                            href={`${BASE_URL}${file.path}`}
                          >
                            <i
                              className='pi pi-file-pdf'
                              style={{ fontSize: 40 }}
                            />
                            <span>{fileName}</span>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.textareaContainer}>
              <label className={styles.label}>Деталі заявки</label>
              <textarea
                className={styles.textarea}
                value={descriptionValue || ''}
                onChange={(e) => setDescriptionValue(e.target.value)}
                disabled={userRole === 4}
              />
            </div>
            <div className={styles.textareaContainer}>
              <label className={styles.label}>Примітки</label>
              <textarea
                className={styles.textarea}
                value={additionalConditions || ''}
                onChange={(e) => setAdditionalConditions(e.target.value)}
                disabled={userRole === 4}
              />
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.titleContainer}>
                <div style={{ width: 132, height: 50 }} />
                <label>
                  <b>Товари</b>
                </label>
                <Button
                  label='Додати товар'
                  type='button'
                  onClick={() => {
                    setAddCategory(true);
                    setAddSuppliers(false);
                  }}
                  disabled={userRole === 4}
                />
              </div>
              <AddCategory
                setCategories={setCategories}
                setPreloaderIsActive={setPreloaderIsActive}
                tenderId={tenderId}
                setEditItemActive={setEditItemActive}
                setCurrentItem={setCurrentItem}
                setAddCategory={setAddCategory}
                isAddCategory={isAddCategory}
                setAddSuppliers={setAddSuppliers}
                categories={categories}
                setAmount={setAmount}
                setNewCategory={setNewCategory}
                setNewItem={setNewItem}
                setNewItemId={setNewItemId}
                status={currentTender?.status_code}
                setSelectedSupplier={setSelectedSupplier}
              />
            </div>
            {currentTender?.status_code > 1 && (
              <div className={styles.tableContainer}>
                {/* <div className={styles.titleContainer}>
                    <div style={{ width: 200, height: 50 }} />
                    <label><b>Постачальники</b></label>
                    <Button
                      label="Додати постачальника"
                      type="button"
                      onClick={() => { setAddSuppliers(true); setAddCategory(false) }}
                      disabled={userRole === 4}
                    />
                  </div> */}
                <AddSuppliers
                  allCategories={allCategories}
                  currentTender={currentTender}
                  setPreloaderIsActive={setPreloaderIsActive}
                  amount={amount}
                  setAmount={setAmount}
                  newCategory={newCategory}
                  setNewCategory={setNewCategory}
                  newItem={newItem}
                  newItemId={newItemId}
                  setNewItem={setNewItem}
                  setNewItemId={setNewItemId}
                  tenderData={categories}
                  isAddSuppliers={isAddSuppliers}
                  setAddSuppliers={setAddSuppliers}
                  tenderId={tenderId}
                  setCurrentSupplier={setCurrentSupplier}
                  setEditSupplierActive={setEditSupplierActive}
                  supplierItem={supplierItem}
                  setSupplierItem={setSupplierItem}
                  supplierComment={supplierComment}
                  setSupplierComment={setSupplierComment}
                  suppliersList={suppliersList}
                  selectedSupplier={selectedSupplier}
                  setSelectedSupplier={setSelectedSupplier}
                />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <div className={styles.labelContainer}>
                <label>
                  <b>Договір</b>
                </label>
              </div>
              <div className={styles.contractContainer}>
                <div style={{ height: '100%', width: '50%' }}>
                  <FileUpload
                    name='demo[]'
                    url={`${BASE_URL}`}
                    multiple
                    cancelLabel='Відмінити'
                    chooseLabel='Додати'
                    uploadLabel='Прикріпити'
                    customUpload={true}
                    uploadHandler={uploadHandler}
                    ref={uploader}
                    disabled={userRole === 4}
                    maxFileSize='3000000'
                  />
                </div>
                <div className={styles.contract}>
                  <div className={styles.labelContractContainer}>
                    <label>
                      <b>Прикріплені договори</b>
                    </label>
                  </div>
                  <div className={styles.contractContent}>
                    {currentTender?.contracts?.map((file, index) => {
                      const fileName = `${getName(file.filename)}.${
                        file.extension
                      }`;
                      return (
                        <div
                          style={{ position: 'relative', width: 100 }}
                          key={index}
                        >
                          <i
                            className='pi pi-times'
                            style={{
                              position: 'absolute',
                              fontSize: 12,
                              zIndex: 1,
                              color: '#e51a4b',
                              top: 7,
                              right: 20,
                            }}
                            onClick={() =>
                              userRole !== 4 &&
                              removeFile(file._id, file.filename)
                            }
                          />
                          <a
                            className={styles.fileWrapper}
                            href={`${BASE_URL}${file.path}`}
                            target='_blank'
                          >
                            <i
                              className='pi pi-file-pdf'
                              style={{ fontSize: 40 }}
                            />
                            <span>{fileName}</span>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.actionsWrapper}>
              <label>
                <b>Статус</b>
              </label>
              <div className={styles.actionsContainer}>
                <Steps
                  model={tenderStatusData}
                  readOnly={userRole === 4}
                  activeIndex={currentTender.status_code}
                  onSelect={(e) => changeStatus(e)}
                />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                label='Назад'
                type='button'
                className='p-button-secondary'
                onClick={() =>
                  popupDialog(
                    'Ви дійсно хочете вийти без збереження?',
                    () =>
                      prevPage === 0
                        ? history.push(routes.HOME_PAGE)
                        : history.push(routes.TENDERS_PAGE),
                    'Вийти',
                    () => {}
                  )
                }
                style={{ marginTop: 30 }}
              />
              <Button
                label='Зберегти'
                type='submit'
                style={{ marginTop: 30 }}
                disabled={userRole === 4 || isAddSuppliers || isAddCategory}
              />
            </div>
          </Form>
          <ChatRoma
            setRefresh={setRefresh}
            refresh={refresh}
            setRecordAudio={setRecordAudio}
            setChatActive={setChatActive}
            isChatActive={isChatActive}
            currentTender={currentTender}
          />
          <AudioRecord
            active={recordAudio}
            setRecordAudio={setRecordAudio}
            tenderId={tenderId}
          />
        </>
      )}
    </Formik>
  );
};

export default TenderCard;
