// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ReassignManager_formContainer__19Mxg {\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .ReassignManager_formContainer__19Mxg input {\n    width: 250px; }\n\n.ReassignManager_buttonContainer__1P_zO {\n  padding: 10px;\n  margin-top: 20px; }\n  .ReassignManager_buttonContainer__1P_zO button:first-child {\n    margin-right: 55px; }\n\n.ReassignManager_dropdownContainer__2AaXZ {\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  margin-bottom: 10px; }\n  .ReassignManager_dropdownContainer__2AaXZ label {\n    font-weight: bold; }\n", ""]);
// Exports
exports.locals = {
	"formContainer": "ReassignManager_formContainer__19Mxg",
	"buttonContainer": "ReassignManager_buttonContainer__1P_zO",
	"dropdownContainer": "ReassignManager_dropdownContainer__2AaXZ"
};
module.exports = exports;
