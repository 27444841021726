import {
  SET_TENDERS_FOR_SUPPLIER,
  SET_ACCEPTED_TENDERS_FOR_SUPPLIER,
  SET_ALL_SUPPLIERS,
  SET_SENT_FOR_SUPPLIER,
  SET_DISABLED_ACCEPTED_TENDERS_FOR_SUPPLIER
} from '../constants/actionTypes';

export const setTendersForSupplier = (payload) => ({
  type: SET_TENDERS_FOR_SUPPLIER,
  payload
});

export const setAcceptedTendersForSupplier = (payload) => ({
  type: SET_ACCEPTED_TENDERS_FOR_SUPPLIER,
  payload
});

export const setAllSuppliers = (payload) => ({
  type: SET_ALL_SUPPLIERS,
  payload
});

export const setSentForSupplier = (id) => ({
  type: SET_SENT_FOR_SUPPLIER,
  id
});

export const setDisabledAcceptedTendersForSupplier = (payload) => ({
  type: SET_DISABLED_ACCEPTED_TENDERS_FOR_SUPPLIER,
  payload
});
