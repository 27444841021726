import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  ua: {
    translation: {
      suppliers: {
        orderRegistry: 'Реєстр замовлень',
        orderShipments: 'Реєстр відвантажень',
        rowsCount: 'Кількість рядків у таблиці',
        orderNumber: 'Номер заказу',
        clientNumber: 'Клієнт',
        shippingAddress: 'Адреса доставки',
        subCategory: 'Підкатегорія',
        attachments: 'Прикріплені файли',
        orderName: 'Назва заявки',
        product: 'Товар',
        description: 'Опис',
        orderDetails: 'Деталі заявки',
        count: 'Кількість',
        endTime: 'Час закінчення торгів',
        proposeProduct: 'Запропонований товар',
        editPropose: 'Редагування пропозиції',
        shippingCount: 'Кількість поставки',
        price: 'Ціна',
        summa: 'Сума',
        comment: 'Коментар',
        minPrice: 'Мін ціна',
        order: 'Замовлення',
        productName: 'Назва товару',
        clientName: 'Клієнт',
        dateOfOrder: 'Дата замовлення',
        shipped: 'Відвантажено клієнту',
        received: 'Отримано клієнтом',
        saveChanges: 'Зберегти змiни',
        discardChanges: 'Відмінити',
        needed: 'Необхідна кількість',
      },
    },
  },
  en: {
    translation: {
      suppliers: {
        orderRegistry: 'Register of orders',
        orderShipments: 'Register of shipments',
        rowsCount: 'Number of rows in the table',
        orderNumber: 'Order number',
        clientNumber: 'Client',
        shippingAddress: 'Delivery address',
        subCategory: 'Subcategory',
        attachments: 'Attached files',
        orderName: 'Order name',
        product: 'Goods',
        description: 'Description',
        orderDetails: 'Order details',
        count: 'Count',
        endTime: 'Bidding end time',
        proposeProduct: 'The offered goods',
        editPropose: 'Edit suggestions',
        shippingCount: 'Quantity of delivery',
        price: 'Price',
        summa: 'Sum',
        comment: 'Comment',
        minPrice: 'Min price',
        order: 'Order',
        productName: 'Product name',
        clientName: 'Client',
        dateOfOrder: 'Order date',
        shipped: 'Shipped to customer',
        received: 'Received by the client',
        saveChanges: 'Save changes',
        discardChanges: 'Discard',
        needed: 'Needed count',
      },
    },
  },
  de: {
    translation: {
      suppliers: {
        orderRegistry: 'Register der Aufträge',
        orderShipments: 'Register der Verbringungen',
        rowsCount: 'Anzahl der Zeilen in der Tabelle',
        orderNumber: 'Bestellnummer',
        clientNumber: 'Kunde',
        shippingAddress: 'Lieferadresse',
        subCategory: 'Unterkategorie',
        attachments: 'Angehängte Dateien',
        orderName: 'Name der Bestellung',
        product: 'Waren',
        description: 'Beschreibung',
        orderDetails: 'Details zur Bestellung',
        count: 'Zählen Sie',
        endTime: 'Endzeit der Ausschreibung',
        proposeProduct: 'Die angebotenen Waren',
        editPropose: 'Vorschläge bearbeiten',
        shippingCount: 'Menge der Lieferung',
        price: 'Preis',
        summa: 'Summe',
        comment: 'Kommentar',
        minPrice: 'Mindestpreis',
        order: 'Bestellung',
        productName: 'Name des Produkts',
        clientName: 'Kunde',
        dateOfOrder: 'Datum der Bestellung',
        shipped: 'An den Kunden versandt',
        received: 'Empfangen vom Kunden',
        saveChanges: 'Änderungen speichern',
        discardChanges: 'Ablegen',
        needed: 'Erforderliche Anzahl',
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ua',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
