import React from 'react';
import styles from './Sidebar.module.scss';
import { getUserRole } from '../../store/actions/loginActions';

const Sidebar = ({ categories }) => {
  const userRole = Number(getUserRole() || 4);
  const sidebarCategories = userRole === 6
    ? categories.filter(item => !item.categoryForSuppliers)
    : userRole === 4
      ? categories.filter(item => item.categoryForReader)
      : userRole === 3
        ? categories.filter(item => item.categoryForSuppliers)
        : categories.filter(item => item.openForAllUsers);

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.categoryContainer}>
        {sidebarCategories.map((category, index) => (
          <div
            className={styles.categoryWrapper}
            style={{ backgroundColor: category.status ? '#2c3b41' : '#28353b' }}
            key={index}
            onClick={() => category.route()}
          >
            {category.status && <div className={styles.activeLabel} />}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i
                className={category.icon}
                style={{ color: category.status ? '#fff' : '#b8c7ce' }}
              />
              <p style={{ color: category.status ? '#fff' : '#b8c7ce' }}>
                {category.name}
              </p >
            </div>
            <i
              className="pi pi-angle-right"
              style={{ color: category.status ? '#fff' : '#b8c7ce' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;