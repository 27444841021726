import {SET_CATEGORIES_CURRENT_PAGE, SET_CATEGORIES_ROWS_IN_TABLE} from '../constants/actionTypes';

const initialState = {
  currentPage: 0,
  rowsInTable: 10,
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES_CURRENT_PAGE:
      return {...state, currentPage: action.payload};
    case SET_CATEGORIES_ROWS_IN_TABLE:
      return {...state, rowsInTable: action.payload};

    default:
      return state;

  }
};

export default categoriesReducer;