import React, {useEffect, useState} from 'react';
import {getUserRole} from '../../../store/actions/loginActions';
import {getClientId} from '../../../store/actions/clientsActions';
import {getAllUserInfoById} from '../../../services/UserService';
import styles from './OwnerCard.module.scss';
import {Field, Form, Formik} from 'formik';
import {editClient, editOwnerById} from '../../../services/ClientService';
import {popupDialog} from '../../../commons/PopupDialog/PopupDialog';
import FormInput from '../../../commons/FormInput';
import {Button} from 'primereact/button';
import * as Yup from 'yup';

const OwnerCard = ({refresh, setRefresh, setEditOwner}) => {
  const userRole = Number(getUserRole() || 4);
  const clientId = getClientId();
  const [client, setClient] = useState({});

  const validationSchema = Yup.object().shape({
    email: Yup
    .string()
    .email('Некоректна електронна пошта')
    .required("Це поле є обов'язковим"),
    fullName: Yup
    .string()
    .required("Це поле є обов'язковим"),
    phone: Yup
    .string('Некоректний номер')
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
    .nullable(),
    additional_phone: Yup
    .string('Некоректний номер')
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
    .nullable(),
    company_phone: Yup
    .string('Некоректний номер')
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
    .nullable(),
    // .required("Це поле є обов'язковим"),
    company_additional_phone: Yup
    .string('Некоректний номер')
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
    .nullable(),
    // .required("Це поле є обов'язковим"),
    company_email: Yup
    .string()
    .email('Некоректна електронна пошта')
    .required("Це поле є обов'язковим"),
    company_name: Yup
    .string()
    .nullable(),
    // .required("Це поле є обов'язковим"),
    goods_delivery_address: Yup
    .string()
    .nullable(),
    // .required("Це поле є обов'язковим"),
    documents_delivery_address: Yup
    .string()
    .nullable(),
    // .required("Це поле є обов'язковим"),
    payment_account: Yup
    .string()
    .nullable()
    .matches(/^[A-Z]{2}[A-Z0-9/\s]{13,29}$/, 'Некоректний рахунок'),
    // .required("Це поле є обов'язковим"),
    edrpou: Yup
    .string()
    .nullable(),
    // .required("Це поле є обов'язковим"),
  });

  useEffect(() => {
    (async () => {
      const client = await getAllUserInfoById(clientId);
      setClient(client);
    })()
  }, []);

  return (
            <Formik
                initialValues={{
                  id: client?.profile?.id,
                  email: client?.profile?.email,
                  role: client?.profile?.role?.role,
                  role_id: client?.profile?.role?.id,
                  fullName: client?.profile?.fullName,
                  phone: client?.profile?.phone,
                  additional_phone: client?.profile?.additional_phone,
                  notifications_enabled: client?.profile?.notifications_enabled,
                  twoFA: client?.profile?.twoFA,

                  company_id: client?.company?._id,
                  company_email: client?.company?.email,
                  bank_name: client?.company?.bank_name,
                  company_name: client?.company?.name,
                  company_phone: client?.company?.phone,
                  company_additional_phone: client?.company?.additional_phone,
                  documents_delivery_address: client?.company?.documents_delivery_address,
                  edrpou: client?.company?.edrpou,
                  goods_delivery_address: client?.company?.goods_delivery_address,
                  mfi: client?.company?.mfi,
                  payment_account: client?.company?.payment_account,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, {setSubmitting}) => {
                  setSubmitting(false);
                  const isSuccess = await editOwnerById(values);
                  isSuccess && setEditOwner(false); setRefresh(!refresh)
                  !isSuccess && popupDialog('Помилка. Повторіть спробу', () => {}, 'Ok')
                }}
                validateOnChange={false}
                enableReinitialize={true}
                validateOnMount={true}
            >
              {() => (
                  <Form>
                    <div className={styles.wrapper}>
                      <div className={styles.formContainer}>
                        <div className={styles.wrapper}>
                          <b>Особиста інформація</b>
                          <div className={styles.ownerContainer}>
                            <Field name={`email`}
                                   label="Email"
                                   keyfilter={'email'}
                                   component={FormInput}
                                   disabled
                                   required
                            />
                            <Field name={`fullName`}
                                   label="ПІБ"
                                   component={FormInput}
                                   required
                                   disabled={userRole === 4}
                            />
                            <Field name={`phone`}
                                   label={`Телефон`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />
                            <Field name={`additional_phone`}
                                   label={`Додатковий номер`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />
                          </div>
                        </div>
                        <div className={styles.wrapper}>
                          <b>Про компанію</b>
                          <div className={styles.companyContainer}>
                            <Field name={`company_name`}
                                   label="Назва компанії"
                                   component={FormInput}
                                   disabled
                                   required
                            />
                            <Field name={`company_email`}
                                   label="Email компанії"
                                   keyfilter={'email'}
                                   component={FormInput}
                                   disabled
                                   required
                            />
                            <Field name={`company_phone`}
                                   label={`Телефон компанії`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />
                            <Field name={`company_additional_phone`}
                                   label={`Додатковий номер компанії`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />

                            <Field name={`goods_delivery_address`}
                                   label={`Адреса доставки товарів`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />
                            <Field name={`documents_delivery_address`}
                                   label={`Адреса доставки документів`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />
                            <Field name={`edrpou`}
                                   label={`ЕДРПОУ`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />
                            <Field name={`payment_account`}
                                   label={`Розрахунковий рахунок (IBAN)`}
                                   component={FormInput}
                                   disabled={userRole === 4}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.buttonContainer}>
                        <Button
                          className="p-button-secondary"
                          type="button"
                          label="Вийти"
                          onClick={() => setEditOwner(false)}
                        />
                        <Button
                            type="submit"
                            label="Зберегти"
                        />
                      </div>
                    </div>
                  </Form>
              )}
            </Formik>
  );
};

export default OwnerCard;