import {
  SET_CHAT_VISIBILITY,
  LOAD_CHAT_MESSAGES,
  SET_CURRENT_USER_ID,
  SET_AUDIO_ID,
} from '../constants/actionTypes';

export const setChatVisibility = (isVisible) => ({type: SET_CHAT_VISIBILITY, payload: isVisible});

export const loadChatMessages = (messages) => ({type: LOAD_CHAT_MESSAGES, payload: messages});

export const setCurrentUserId = (id) => ({type: SET_CURRENT_USER_ID, payload: id});
export const setCurrentAudioId = (id) => ({type: SET_AUDIO_ID, payload: id});
