import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadChatMessages, setCurrentUserId} from '../store/actions/chatActions';
import {myFirebase, myFirestore} from '../services/Firebase';
import {setChatNotifications} from '../store/actions/notificationsActions';
import io from 'socket.io-client';
import {setSocket} from '../store/actions/tendersActions';

const BackgroundService = () => {
  const notifications = useSelector(state => state.notificationsReducer.notifications) || [];
  const chatMessages = useSelector(state => state.chatReducer.chatMessages);
  const dispatch = useDispatch();
  const setCurrentFirebaseUserId = (id) => dispatch(setCurrentUserId(id));
  const loadChatMessagesToStore = useCallback((messages) => dispatch(loadChatMessages(messages)), [dispatch]);
  const loadChatNotificationsToStore = (notes) => dispatch(setChatNotifications(notes));

  useEffect(() => {
    observeAuth();
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {reconnectionDelay: 5000});
    dispatch(setSocket(socket));
  }, []);

  let removeListener = null;
  let removeNotificationListener = null;

  const observeAuth = () => myFirebase.auth().onAuthStateChanged(reconnect);

  const reconnect = user => {
    if (!user) {
      try {
        myFirebase.auth().signInAnonymously().then(result => {
          setCurrentFirebaseUserId(result.user.uid);
          getListHistory();
          getNotification();
        }).catch(err => {
        });
      } catch ({message}) {
        console.log(message);
      }
    } else if (user) {
      setCurrentFirebaseUserId(user.uid);
      getListHistory();
    }
  };

  const getNotification = () => {
    if (removeNotificationListener) removeNotificationListener();
    removeNotificationListener = myFirestore.collection('chats').
        doc('t11111111').
        collection('messages').
        where('viewedByAdmin', '==', false).
        orderBy('timestamp').
        limitToLast(5).
        onSnapshot(snapshot => {
          let notes = [...notifications];
          snapshot.forEach(doc => {
            return notes = [...notes, {...doc.data(), tender: 't11111111', path: doc.ref.path}];
          });
          loadChatNotificationsToStore(notes);
        });
  };

  const getListHistory = () => {
    if (chatMessages.length) return;
    if (removeListener) removeListener();

    // Get history and listen new data added
    removeListener = myFirestore.collection('chats').
        doc('t11111111').
        collection('messages').
        orderBy('timestamp').
        onSnapshot(
            snapshot => {
              let message = [...chatMessages];
              snapshot.forEach(doc => {
                message = [...message, {...doc.data(), tender: 't11111111', path: doc.ref.path}];
              });
              message.sort(sortByTime);
              loadChatMessagesToStore(message);
            },
            err => {
            },
        );
  };

  const sortByTime = (a, b) => {
    if (a.timestamp < b.timestamp) {
      return -1;
    } else if (a.timestamp > b.timestamp) {
      return 1;
    }
    return 0;
  };

  return (<></>);
};

export default BackgroundService;
