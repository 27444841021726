// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FreeTenders_mainContainer__1uaWH {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.FreeTenders_contentContainer__hzFhn {\n  width: 100%;\n  height: calc(100vh - 70px);\n  background-color: #ecf0f5;\n  padding: 25px 50px;\n  overflow: scroll;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.FreeTenders_tableButtonContainer__1fEMy {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .FreeTenders_tableButtonContainer__1fEMy button {\n    margin-right: 5px !important; }\n    .FreeTenders_tableButtonContainer__1fEMy button:last-child {\n      margin-right: 0 !important; }\n\n.FreeTenders_dropdownContainer__2I6VT {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .FreeTenders_dropdownContainer__2I6VT span {\n    margin-right: 20px; }\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "FreeTenders_mainContainer__1uaWH",
	"contentContainer": "FreeTenders_contentContainer__hzFhn",
	"tableButtonContainer": "FreeTenders_tableButtonContainer__1fEMy",
	"dropdownContainer": "FreeTenders_dropdownContainer__2I6VT"
};
module.exports = exports;
