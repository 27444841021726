import {
  SET_CURRENT_TENDER,
  SET_CURRENT_SUPPLIERS,
  SET_ALL_TENDERS,
  SET_CURRENT_TENDER_ID,
  SET_SORT_TYPE,
  SET_FREE_TENDERS,
  SET_SORT_TYPE_FREE_TENDERS,
  SET_SORT_TYPE_ALL_TENDERS,
  SET_MY_TENDERS,
  SET_ALL_TENDERS_CURRENT_PAGE,
  SET_MY_TENDERS_CURRENT_PAGE,
  SET_FREE_TENDERS_CURRENT_PAGE,
  SET_PREV_PAGE,
  SET_SOCKET,
  ADD_TENDER_ITEM,
  EDIT_TENDER_ITEM,
  DELETE_TENDER_ITEM,
  SET_TENDER_OFFERS,
  ADD_TENDER_OFFER,
  EDIT_TENDER_OFFER,
  DELETE_TENDER_OFFER,
  ADD_TENDER_CONTRACTS,
  DELETE_TENDER_FILE,
  DELETE_TENDER_CONTRACT,
  CHANGE_TENDER_STATUS,
  SET_CURRENT_TENDER_COPY,
  SET_TENDER_MAIN_INFO
} from '../constants/actionTypes';
import { setChatVisibility } from './chatActions';


export const setSort = (sortType) => ({ type: SET_SORT_TYPE, payload: sortType });
export const setSortAllTenders = (sortType) => ({ type: SET_SORT_TYPE_ALL_TENDERS, payload: sortType });
export const setSortFreeTenders = (sortType) => ({ type: SET_SORT_TYPE_FREE_TENDERS, payload: sortType });

export const setPrevPage = (page) => ({ type: SET_PREV_PAGE, payload: page });

export const setCurrentPageAllTenders = (page) => ({ type: SET_ALL_TENDERS_CURRENT_PAGE, payload: page });
export const setCurrentPageMyTenders = (page) => ({ type: SET_MY_TENDERS_CURRENT_PAGE, payload: page });
export const setCurrentPageFreeTenders = (page) => ({ type: SET_FREE_TENDERS_CURRENT_PAGE, payload: page });

const setAllTenders = (tenders) => ({ type: SET_ALL_TENDERS, payload: tenders });
const setMyTenders = (tenders) => ({ type: SET_MY_TENDERS, payload: tenders });
const setFreeTenders = (tenders) => ({ type: SET_FREE_TENDERS, payload: tenders });

const setCurrentTender = (tender) => ({ type: SET_CURRENT_TENDER, payload: tender });

export const setCurrentSuppliers = (supplier) => ({ type: SET_CURRENT_SUPPLIERS, payload: supplier });

export const setSocket = (socket) => ({ type: SET_SOCKET, payload: socket });

export const setCurrentTenderId = (tenderId) => ({ type: SET_CURRENT_TENDER_ID, payload: tenderId });

export const setAllTendersData = (tenderList) => (dispatch) => {
  dispatch(setAllTenders(tenderList));
  dispatch(setCurrentTenderData(tenderList[0]));
};

export const setMyTendersData = (tenderList) => (dispatch) => {
  dispatch(setMyTenders(tenderList));
  dispatch(setCurrentTenderData(tenderList[0]));
};

export const setAllFreeTenders = (freeTenderList) => (dispatch) => {
  dispatch(setFreeTenders(freeTenderList));
};

export const setCurrentTenderData = (tender) => (dispatch) => {
  dispatch(setCurrentTender(tender));
};

export const setCurrentTenderIdToStorage = (tenderId) => localStorage.setItem('tender-id', tenderId);
export const getCurrentTenderId = () => localStorage.getItem('tender-id');

export const addTenderItem = (payload) => ({
  type: ADD_TENDER_ITEM,
  payload
});

export const editTenderItem = (payload) => ({
  type: EDIT_TENDER_ITEM,
  payload
});

export const deleteTenderItem = (payload) => ({
  type: DELETE_TENDER_ITEM,
  payload
});

export const setTenderOffers = (payload) => ({
  type: SET_TENDER_OFFERS,
  payload
});

export const addTenderOffer = (offer, newItemId) => ({
  type: ADD_TENDER_OFFER,
  offer,
  newItemId
});

export const editTenderOffer = (offer, newItemId, currentSupplierId) => ({
  type: EDIT_TENDER_OFFER,
  offer,
  newItemId,
  currentSupplierId
});

export const deleteTenderOffer = (tenderItemId, _id) => ({
  type: DELETE_TENDER_OFFER,
  tenderItemId,
  _id
});

export const addTenderContracts = (payload) => {
  return {
    type: ADD_TENDER_CONTRACTS,
    payload
  };
};

export const deleteTenderFile = (payload) => ({
  type: DELETE_TENDER_FILE,
  payload
});

export const deleteTenderContract = (payload) => ({
  type: DELETE_TENDER_CONTRACT,
  payload
});

export const changeTenderStatus = (payload) => ({
  type: CHANGE_TENDER_STATUS,
  payload
});

export const setCurrentTenderCopy = (payload) => ({
  type: SET_CURRENT_TENDER_COPY,
  payload
});

export const setTenderMainInfo = (value, name) => ({
  type: SET_TENDER_MAIN_INFO,
  value,
  name
});
