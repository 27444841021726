import React from 'react';
import styles from './Home.module.scss';
import Header from '../Header';
import TenderInfo from './TenderInfo';
import SidebarPage from '../../containers/Sidebar/SidebarPage';
import {setNotificationsIsVisible} from '../../store/actions/notificationsActions';
import Notifications from '../Header/Notifications/Notifications';
import {useDispatch} from 'react-redux';
import Preloader from '../../commons/Preloader/Preloader';

const Home = ({currentTender, preloaderIsActive}) => {
const dispatch = useDispatch();

  return (
      <div className={styles.mainContainer}>
        <div style={{width: '100%', position: 'relative'}}>
          <Preloader active={preloaderIsActive}/>
          <Header/>
          <div
              className={styles.container}
              onClick={() => dispatch(setNotificationsIsVisible(false))}
          >
            <SidebarPage/>
            <Notifications/>
            <div className={styles.rightSide}>
              <div className={styles.tenderInfo}>
                <TenderInfo currentTender={currentTender}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Home;
