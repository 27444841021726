// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShipmentRegistry_mainContainer__1cXQr {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.ShipmentRegistry_contentContainer__rHVgU {\n  width: 100%;\n  height: calc(100vh - 70px);\n  background-color: #ecf0f5;\n  padding: 25px 50px;\n  overflow: scroll;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.ShipmentRegistry_dropdownContainer__3h_D6 {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .ShipmentRegistry_dropdownContainer__3h_D6 span {\n    margin-right: 20px; }\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "ShipmentRegistry_mainContainer__1cXQr",
	"contentContainer": "ShipmentRegistry_contentContainer__rHVgU",
	"dropdownContainer": "ShipmentRegistry_dropdownContainer__3h_D6"
};
module.exports = exports;
