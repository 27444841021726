import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Home from '../../components/Home';
import {
  setCurrentTenderData,
  setCurrentTenderId, setMyTendersData,
} from '../../store/actions/tendersActions';
import { setChatVisibility } from '../../store/actions/chatActions';
import { getAllTendersForCurrentUser } from '../../services/TenderService';
import { getUserId, setUserRole } from '../../store/actions/loginActions';
import { getUserById } from '../../services/UserService';
import OrdersRegistryPage from '../../containers/Suppliers/OrdersRegistryPage';

const HomePage = ({ history }) => {
  const filterType = useSelector(state => state.tenderReducer.sortType);
  const currentTender = useSelector(state => state.tenderReducer.currentTender);
  const currentSuppliers = useSelector(state => state.tenderReducer.currentSuppliers);
  const allTenders = useSelector(state => state.tenderReducer.allTenders) || [];
  const freeTenders = useSelector(state => state.tenderReducer.freeTenders) || [];
  const isChatVisible = useSelector(state => state.chatReducer.isVisible);
  const currentTenderId = useSelector(state => state.tenderReducer.currentTenderId);
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);
  const dispatch = useDispatch();
  const AUTH_USER_ROLE = 'auth-user-role';

  const setCurrentTenderDataToStore = () => {
    const currentTenderData = [...allTenders, ...freeTenders].filter(el => el._id === currentTenderId);
    if (currentTenderData.length) dispatch(setCurrentTenderData(currentTenderData[0]));
  };
  const setChatVisibilityToStore = (bool) => dispatch(setChatVisibility(bool));

  const setCurrentTenderIdToStore = (tenderId) => {
    dispatch(setChatVisibility(false));
    dispatch(setCurrentTenderId(tenderId));
  };

  useEffect(() => {
    if (localStorage.getItem(AUTH_USER_ROLE) === '3') {
      return;
    }
    
    (async () => {
      setPreloaderIsActive(true);
      const id = getUserId();
      const tenderForCurrentUser = await getAllTendersForCurrentUser(id, filterType) || [];
      dispatch(setMyTendersData(tenderForCurrentUser));
      setPreloaderIsActive(false);
    })();
  }, [filterType]);

  useEffect(() => {
    if (!currentTenderId) return;
    setCurrentTenderDataToStore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenderId]);

  // useEffect(() => {
  //   (async () => {
  //     const id = getUserId();
  //     const role = await getUserById(id) || [];
  //     role?.role?.role && setUserRole(role?.role?.role);
  //   })()
  // }, []);

  return (
    localStorage.getItem(AUTH_USER_ROLE) === '3'
      ? <OrdersRegistryPage />
      : <Home
        history={history}
        preloaderIsActive={preloaderIsActive}
        freeTenders={freeTenders}
        tenderList={allTenders}
        currentTender={currentTender}
        setCurrentTenderIdToStore={setCurrentTenderIdToStore}
        currentSuppliers={currentSuppliers}
        isChatVisible={isChatVisible}
        setChatVisibilityToStore={setChatVisibilityToStore}
      />
  );
};

export default HomePage;
