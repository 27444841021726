import React, {useEffect, useState} from 'react';
import Clients from '../../components/Clients';
import {useDispatch, useSelector} from 'react-redux';
import {
  getAllClients,
  setClientId,
  setCurrentClient,
} from '../../store/actions/clientsActions';
import routes from '../../constants/routes';
import {popupDialog} from '../../commons/PopupDialog/PopupDialog';
import {
  getAllCompanies,
  removeCurrentClient,
} from '../../services/ClientService';
import {changeEnableType} from '../../services/AdminService';
import {getUserId} from '../../store/actions/loginActions';
import {getUserById} from '../../services/UserService';
import {setUserRole} from '../../store/actions/usersActions';

const ClientsPage = ({history}) => {
  const dispatch = useDispatch();
  const clients = useSelector(state => state.clientsReducer.allClients);
  const [refresh, setRefresh] = useState(false);
  const [editOwner, setEditOwner] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [removeOwner, setRemoveOwner] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);
  const [addClient, setAddClient] = useState(false);

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const id = getUserId();
      const clients = await getAllCompanies() || [];
      const role = await getUserById(id) || [];
      dispatch(getAllClients(clients));
      dispatch(setUserRole(role));
      setPreloaderIsActive(false);
    })();
  }, [refresh]);

  const goBack = () => {
    history.push(routes.CLIENTS_PAGE)
  };

  const addCurrentClientToStore = (e) => {
    setClientId(e?.data?.id);
    e?.key
        ? setEditOwner(true)
        : setEditClient(true);
  };

  const goToHistoryPage = (user) => {
    dispatch(setCurrentClient(user.data));
    history.push(routes.HISTORY_CLIENT);
  };

  const restoreOwner = (id) => {
    setClientId(id);
    setRemoveOwner(true);
  };

  const changeOwnerStatus = (id) => {
    setClientId(id);
    setChangeStatus(true);
  };

  const addSubUser = (user) => {
    dispatch(setCurrentClient(user));
    setAddClient(true);
  };

  const addNewSubClient = () => {
    setCurrentClient({});
  };

  const setClientStatus = (id, status) => {
    const statusText = status === 'blocked' ?
        'Ви дійсно хочите заблокувати користувача?' :
        'Ви дійсно хочите розблокувати користувача?';
    const buttonText = status === 'blocked' ?
        'Заблокувати' : 'Розблокувати';
    const setClient = async () => {
      await changeEnableType(id);
      const clients = await getAllCompanies();
      dispatch(getAllClients(clients));
    };
    popupDialog(statusText, async () => await setClient(), buttonText, () => {});
  };

  const removeClient = (id, name) => {
    const removeOneClient = async () => {
      await removeCurrentClient(id);
      const clients = await getAllCompanies();
      dispatch(getAllClients(clients));
    };
    popupDialog(`Ви дійсно хочете видали користувача ${name}?`, () => removeOneClient(), 'Видалити', () => {});
  };

  const rowsCountData = [
    {label: 5, value: 5},
    {label: 10, value: 10},
    {label: 50, value: 50},
    {label: 100, value: 100},
  ];

  return (
      <Clients
          goBack={goBack}
          clients={clients}
          refresh={refresh}
          setAddClient={setAddClient}
          addClient={addClient}
          changeStatus={changeStatus}
          setChangeStatus={setChangeStatus}
          setRemoveOwner={setRemoveOwner}
          removeOwner={removeOwner}
          setRefresh={setRefresh}
          preloaderIsActive={preloaderIsActive}
          history={history}
          setEditOwner={setEditOwner}
          setEditClient={setEditClient}
          editClient={editClient}
          editOwner={editOwner}
          rowsCountData={rowsCountData}
          goToHistoryPage={goToHistoryPage}
          changeOwnerStatus={changeOwnerStatus}
          restoreOwner={restoreOwner}
          addSubUser={addSubUser}
          setClientStatus={setClientStatus}
          removeClient={removeClient}
          addCurrentClientToStore={addCurrentClientToStore}
          addNewSubClient={addNewSubClient}
      />
  );
};

export default ClientsPage;
