import {
  SET_CURRENT_TENDER,
  SET_CURRENT_SUPPLIERS,
  SET_ALL_TENDERS,
  SET_CURRENT_TENDER_ID,
  SET_SORT_TYPE,
  SET_FREE_TENDERS,
  SET_SORT_TYPE_FREE_TENDERS,
  SET_SORT_TYPE_ALL_TENDERS,
  SET_MY_TENDERS,
  SET_ALL_TENDERS_CURRENT_PAGE,
  SET_MY_TENDERS_CURRENT_PAGE,
  SET_FREE_TENDERS_CURRENT_PAGE,
  SET_PREV_PAGE,
  SET_SOCKET,
  ADD_TENDER_ITEM,
  EDIT_TENDER_ITEM,
  DELETE_TENDER_ITEM,
  SET_TENDER_OFFERS,
  ADD_TENDER_OFFER,
  EDIT_TENDER_OFFER,
  DELETE_TENDER_OFFER,
  ADD_TENDER_CONTRACTS,
  DELETE_TENDER_CONTRACT,
  DELETE_TENDER_FILE,
  CHANGE_TENDER_STATUS,
  SET_CURRENT_TENDER_COPY,
  SET_TENDER_MAIN_INFO
} from '../constants/actionTypes';

const initialState = {
  currentTender: {},
  currentTenderCopy: {},
  currentSuppliers: [],
  allTenders: [],
  myTenders: [],
  currentTenderId: '',
  sortType: `created`,
  sortTypeFreeTenders: `created`,
  sortTypeAllTenders: `created`,
  freeTenders: [],
  currentAllTendersPage: 0,
  currentMyTendersPage: 0,
  currentFreeTendersPage: 0,
  prevPage: 0,
  socket: null,
};

const tenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SORT_TYPE:
      return { ...state, sortType: action.payload };
    case SET_SORT_TYPE_ALL_TENDERS:
      return { ...state, sortTypeAllTenders: action.payload };
    case SET_SORT_TYPE_FREE_TENDERS:
      return { ...state, sortTypeFreeTenders: action.payload };
    case SET_FREE_TENDERS:
      return { ...state, freeTenders: action.payload };
    case SET_CURRENT_TENDER:
      return { ...state, currentTender: action.payload };
    case SET_CURRENT_SUPPLIERS:
      return { ...state, currentSuppliers: action.payload };
    case SET_ALL_TENDERS:
      return { ...state, allTenders: action.payload };
    case SET_MY_TENDERS:
      return { ...state, myTenders: action.payload };
    case SET_CURRENT_TENDER_ID:
      return { ...state, currentTenderId: action.payload };
    case SET_ALL_TENDERS_CURRENT_PAGE:
      return { ...state, currentAllTendersPage: action.payload };
    case SET_MY_TENDERS_CURRENT_PAGE:
      return { ...state, currentMyTendersPage: action.payload };
    case SET_FREE_TENDERS_CURRENT_PAGE:
      return { ...state, currentFreeTendersPage: action.payload };
    case SET_PREV_PAGE:
      return { ...state, prevPage: action.payload };
    case SET_SOCKET:
      return { ...state, socket: action.payload };
    case ADD_TENDER_ITEM:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          items: [...state.currentTender.items, action.payload]
        }
      };
    case EDIT_TENDER_ITEM:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          items: action.payload
        }
      };
    case DELETE_TENDER_ITEM:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          items: action.payload
        }
      };
    case SET_TENDER_OFFERS:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          offers: [...action.payload]
        }
      };
    case ADD_TENDER_OFFER:
      console.log(action.offer, action.newItemId);
      return {
        ...state, currentTender: {
          ...state.currentTender,
          items: state.currentTender.items.map((item) => {
            if (item._id === action.newItemId) {
              return { ...item, offers: [...item.offers, action.offer] }
            }

            return item
          })
        }
      };
    case EDIT_TENDER_OFFER:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          items: state.currentTender.items.map((item) => {
            if (item._id === action.newItemId) {
              return {
                ...item, offers: item.offers.map(offer => {
                  if (offer._id === action.currentSupplierId) {
                    return {
                      ...offer,
                      ...action.offer
                    }
                  }

                  return offer;
                })
              }
            }

            return item
          })
        }
      };
    case DELETE_TENDER_OFFER:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          items: state.currentTender.items.map((item) => {
            if (item._id === action.tenderItemId) {
              return {
                ...item,
                offers: item.offers.filter(offer => offer._id !== action._id)
              }
            }

            return item
          })
        }
      };
    case ADD_TENDER_CONTRACTS:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          contracts: [...action.payload]
        }
      };
    case DELETE_TENDER_CONTRACT:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          contracts: action.payload
        }
      };
    case DELETE_TENDER_FILE:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          files: action.payload
        }
      };
    case CHANGE_TENDER_STATUS:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          status_code: action.payload
        }
      };
    case SET_CURRENT_TENDER_COPY:
      return {
        ...state,
        currentTenderCopy: action.payload
      };
    case SET_TENDER_MAIN_INFO:
      return {
        ...state, currentTender: {
          ...state.currentTender,
          [action.name]: action.value
        }
      };
    default:
      return state;
  }
};

export default tenderReducer;
