// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Sidebar_sidebarContainer__3IS0- {\n  height: calc(100vh - 70px);\n  min-width: 230px;\n  background-color: #28353b;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.Sidebar_logoContainer__1vkHN {\n  height: 70px;\n  width: 100%;\n  background-color: #3c8dbc;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.Sidebar_categoryContainer__3GPcs {\n  width: 100%;\n  padding-top: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.Sidebar_categoryWrapper__3LjE6 {\n  position: relative;\n  width: 100%;\n  padding: 0 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .Sidebar_categoryWrapper__3LjE6:hover {\n    cursor: pointer;\n    background-color: #2c3b41 !important; }\n  .Sidebar_categoryWrapper__3LjE6:hover p {\n    color: #fff !important; }\n  .Sidebar_categoryWrapper__3LjE6:hover i {\n    color: #fff !important; }\n  .Sidebar_categoryWrapper__3LjE6 p {\n    margin-left: 15px;\n    font-size: 14px; }\n\n.Sidebar_activeLabel__I1K1X {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  width: 3px;\n  background-color: #3c8dbc; }\n", ""]);
// Exports
exports.locals = {
	"sidebarContainer": "Sidebar_sidebarContainer__3IS0-",
	"logoContainer": "Sidebar_logoContainer__1vkHN",
	"categoryContainer": "Sidebar_categoryContainer__3GPcs",
	"categoryWrapper": "Sidebar_categoryWrapper__3LjE6",
	"activeLabel": "Sidebar_activeLabel__I1K1X"
};
module.exports = exports;
