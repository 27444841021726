import React, { useState } from 'react';
import Header from '../Header';
import { Button } from 'primereact/button';
import styles from './Users.module.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SidebarPage from '../../containers/Sidebar/SidebarPage';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUserCurrentPage,
  setUserRowsInTable,
} from '../../store/actions/usersActions';
import { setNotificationsIsVisible } from '../../store/actions/notificationsActions';
import Notifications from '../Header/Notifications/Notifications';
import SettingsPopup from '../../commons/SettingsPopup/SettingsPopup';
import AddNewUser from './AddNewUser/AddNewUser';
import UserCard from './UserCard';
import Preloader from '../../commons/Preloader/Preloader';

const Users = ({
  users, setCurrentUserToStore, setAddUser, refresh,
  addNewUser, removeOneUser, setEditUser, setRefresh,
  setUserStatus, rowsCountData, addUser, editUser, preloaderIsActive
}) => {
  const page = useSelector(state => state.usersReducer.currentPage);
  const rowsInTable = useSelector(state => state.usersReducer.rowsInTable);
  const dispatch = useDispatch();
  const tableData = users?.map(user => ({
    ...user,
    fullName: `${user.name || ''} ${user.surname || ''} ${user.lastName || ''}`,
    created_at: user.created_at.split('-').reverse().join('-')
  }));

  return (
    <div style={{ position: 'relative' }}>
      <Preloader active={preloaderIsActive} />
      <Header />
      <div
        className={styles.mainContainer}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      >
        <SidebarPage />
        <Notifications />
        <SettingsPopup
          visible={addUser}
          onHide={() => setAddUser(false)}
          headerText="Додавання користувача"
        >
          <AddNewUser
            refresh={refresh}
            setRefresh={setRefresh}
            setAddUser={setAddUser}
          />
        </SettingsPopup>
        <SettingsPopup
          visible={editUser}
          onHide={() => setEditUser(false)}
          headerText="Редагування користувача"
        >
          <UserCard
            refresh={refresh}
            setRefresh={setRefresh}
            setEditUser={setEditUser}
          />
        </SettingsPopup>
        <div className={styles.container}>
          <div className={styles.dropdownContainer}>
            <Button label="Додати користувача" icon="pi pi-user-plus"
              onClick={addNewUser} />
            <div>
              <span>Кількість рядків у таблиці:</span>
              <Dropdown
                value={rowsInTable}
                options={rowsCountData}
                onChange={e => dispatch(setUserRowsInTable(e.value))}
              />
            </div>
          </div>
          <DataTable value={tableData} rows={rowsInTable} paginator first={page} onPage={e => dispatch(setUserCurrentPage(e.first))}>
            <Column header="ПІБ" field="fullName" sortable style={{ width: '30%' }} filter filterMatchMode="contains" />
            <Column header="Email" field="email" sortable style={{ width: '30%' }} filter filterMatchMode="contains" />
            <Column header="Роль" style={{ width: 95 }} body={({ role }) => {
              return <span>{role?.role === 4 ? 'Reader' : role?.role === 5 ? 'Manager' : role?.role === 3 ? 'Supplier' : 'Admin'}</span>
            }} />
            <Column header="Статус" style={{ width: 85 }} body={({ authentication }) => {
              return authentication?.enabled ?
                <span className={styles.statusActive}>active</span> :
                <span className={styles.statusBlocked}>inactive</span>
            }} />
            <Column header="Дії" style={{ width: 150 }} body={(e) => (
              <div className={styles.tableButtonContainer}>
                <Button icon="pi pi-pencil"
                  tooltip="Редагувати"
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => setCurrentUserToStore(e.id)} />
                <Button
                  className={e.authentication?.enabled ? "p-button-warning" : "p-button-success"}
                  tooltip={e.authentication?.enabled ? "Заблокувати" : "Розблокувати"}
                  tooltipOptions={{ position: 'top' }}
                  icon="pi pi-ban"
                  onClick={() => setUserStatus(e.id, e.authentication?.enabled, e.fullName)}
                />
                <Button className="p-button-danger" icon="pi pi-trash"
                  tooltip="Видалити"
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => removeOneUser(e.id)} />
              </div>)} />
            <Column header="Дата створення" field="created_at" sortable style={{ width: 100 }} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Users;
