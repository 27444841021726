import React from 'react';
import {Dropdown} from 'primereact/dropdown';
import styles from './FormDropdown.module.scss'
import {getIn} from 'formik';

const FormDropdown = ({ field, form, meta, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
      <div style={{position: 'relative'}}>
        <div className={styles.container}>
          <div className={styles.formLabel}>
            {props.label}
            {props.required && <div style={{fontSize: 12, color: '#E51A4B'}}>*</div> }
          </div>
          <Dropdown {...field} {...props}
                    style={{borderColor: touch && error ? '#E51A4B' : '#a6a6a6'}}/>
        </div>
        <div className={styles.formError}>{touch && error ? error : ''}</div>
      </div>
  );
};

export default FormDropdown;
