import React from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import styles from './Preloader.module.scss';

const Preloader = ({active}) => {
  return (
      <div style={{height: '100%', width: '100%'}}>
        {active &&
          (
              <div className={styles.preloaderContainer}>
                <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="5" animationDuration=".7s" fill="transparent"/>
              </div>
          )
        }
      </div>
  );
};

export default Preloader;