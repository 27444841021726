import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import routes from '../../constants/routes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SidebarPage = () => {
  const { t, i18n } = useTranslation();
  const matchTenders =
    useRouteMatch(routes.HOME_PAGE)?.isExact ||
    !!document.location.href.match(/\/tenders\/details/);
  const matchFreeTenders = useRouteMatch(routes.FREE_TENDERS_PAGE)?.isExact;
  const allTenders =
    useRouteMatch(routes.TENDERS_PAGE)?.isExact ||
    !!document.location.href.match(/\/tenders\/info/);
  const matchUsers = !!useRouteMatch(routes.USERS_PAGE)?.url.match(/\/users/);
  const matchClients = !!useRouteMatch(routes.CLIENTS_PAGE)?.url.match(
    /\/clients/
  );
  const matchCategory = useRouteMatch(routes.CATEGORIES_PAGE)?.isExact;
  const matchShipmentRegistry = useRouteMatch(
    routes.SHIPMENT_REGISTRY_PAGE
  )?.isExact;
  const history = useHistory();

  const toTendersPage = () => {
    history.push(routes.HOME_PAGE);
  };

  const toUsersPage = () => {
    history.push(routes.USERS_PAGE);
  };

  const toFreeTendersPage = () => {
    history.push(routes.FREE_TENDERS_PAGE);
  };

  const toCategoriesPage = () => {
    history.push(routes.CATEGORIES_PAGE);
  };

  const toClientsPage = () => {
    history.push(routes.CLIENTS_PAGE);
  };

  const toAllTenders = () => {
    history.push(routes.TENDERS_PAGE);
  };

  const toShipmentRegistryPage = () => {
    history.push(routes.SHIPMENT_REGISTRY_PAGE);
  };

  const categories = [
    {
      name: 'Всі заявки',
      icon: 'pi pi-list',
      status: allTenders,
      route: toAllTenders,
      openForAllUsers: false,
      categoryForReader: true,
      categoryForSuppliers: false,
    },
    {
      name: 'Мої заявки',
      icon: 'pi pi-star',
      status: matchTenders,
      route: toTendersPage,
      openForAllUsers: true,
      categoryForReader: false,
      categoryForSuppliers: false,
    },
    {
      name: 'Вільні заявки',
      icon: 'pi pi-plus',
      status: matchFreeTenders,
      route: toFreeTendersPage,
      openForAllUsers: true,
      categoryForReader: true,
      categoryForSuppliers: false,
    },
    {
      name: 'Користувачі',
      icon: 'pi pi-users',
      status: matchUsers,
      route: toUsersPage,
      openForAllUsers: false,
      categoryForReader: false,
      categoryForSuppliers: false,
    },
    {
      name: 'Клієнти',
      icon: 'pi pi-id-card',
      status: matchClients,
      route: toClientsPage,
      openForAllUsers: true,
      categoryForReader: false,
      categoryForSuppliers: false,
    },
    {
      name: 'Номенклатура',
      icon: 'pi pi-shopping-cart',
      status: matchCategory,
      route: toCategoriesPage,
      openForAllUsers: false,
      categoryForReader: false,
      categoryForSuppliers: false,
    },
    {
      name: `${t('suppliers.orderRegistry')}`,
      icon: 'pi pi-shopping-cart',
      status: matchTenders,
      route: toTendersPage,
      openForAllUsers: false,
      categoryForReader: false,
      categoryForSuppliers: true,
    },
    {
      name: `${t('suppliers.orderShipments')}`,
      icon: 'pi pi-directions',
      status: matchShipmentRegistry,
      route: toShipmentRegistryPage,
      openForAllUsers: false,
      categoryForReader: false,
      categoryForSuppliers: true,
    },
  ];

  return <Sidebar categories={categories} />;
};

export default SidebarPage;
