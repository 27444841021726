import React, { useState, useEffect } from 'react';
import ShipmentRegistry from '../../components/Suppliers/ShipmentRegistry/ShipmentRegistry';
import { getAcceptedTendersForSupplier } from '../../services/SupplierService';
import { useDispatch } from 'react-redux';
import { setAcceptedTendersForSupplier, setDisabledAcceptedTendersForSupplier } from '../../store/actions/supplierActions';
import { popupDialog } from '../../commons/PopupDialog/PopupDialog';
import { setShipmentStatus } from '../../services/SupplierService';

const ShipmentRegistryPage = () => {
  const dispatch = useDispatch();
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [sentTenders, setSentTenders] = useState([]);

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const tenders = await getAcceptedTendersForSupplier() || [];
      dispatch(setAcceptedTendersForSupplier(tenders));

      const disabledAcceptedTendersForSupplier = tenders.filter((tender) => tender.offer_sent).map((item) => item.offer_id);
      dispatch(setDisabledAcceptedTendersForSupplier(disabledAcceptedTendersForSupplier));
      setPreloaderIsActive(false);
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const tenders = await getAcceptedTendersForSupplier() || [];
      dispatch(setAcceptedTendersForSupplier(tenders));
      
      const disabledAcceptedTendersForSupplier = tenders.filter((tender) => tender.offer_sent).map((item) => item.offer_id);
      dispatch(setDisabledAcceptedTendersForSupplier(disabledAcceptedTendersForSupplier));
      setPreloaderIsActive(false);
    })()
  }, []);

  const handleSubmit = (() => {
    const submitChanges = async () => {
      const preparedSentTenders = sentTenders.map((tender) => ({ _id: tender, sent: true }));
      const submited = await setShipmentStatus(preparedSentTenders);
      submited && setSentTenders([]);
      setRefresh();
    };

    popupDialog('Ви дійсно хочите зберегти змiни?', () => submitChanges(), 'Так', () => { });
  });

  return (
    <ShipmentRegistry
      preloaderIsActive={preloaderIsActive}
      handleSubmit={handleSubmit}
      sentTenders={sentTenders}
      setSentTenders={setSentTenders}
    />
  );
}

export default ShipmentRegistryPage;
