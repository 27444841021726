import React from 'react';
import {
  addNewCategory,
  editCategory,
} from '../../../services/CategoriesService';
import {popupDialog} from '../../../commons/PopupDialog/PopupDialog';
import {Field, Form, Formik} from 'formik';
import FormInput from '../../../commons/FormInput';
import styles from '../Categories.module.scss';
import {Button} from 'primereact/button';
import * as Yup from 'yup';

const EditCategory = ({setRefresh, refresh, setEditCategory, currentCategory}) => {
  const categoryValidationSchema = Yup.object().shape({
    newCategory: Yup
    .string()
    .required("Це поля є обов'язковим"),
  });

  return (
      <Formik
          initialValues={{
            newCategory: currentCategory?.data?.name || '',
          }}
          validationSchema={categoryValidationSchema}
          onSubmit={async (values, {setSubmitting}) => {
            setSubmitting(false);
            const isSuccess = await editCategory(values.newCategory, currentCategory?.key);
            isSuccess && setEditCategory(false);
            setRefresh(!refresh);
          }}
          validateOnChange={false}
      >
        {() => (
            <Form>
              <Field name={`newCategory`}
                     label="Категорія"
                     component={FormInput}
                     required
              />
              <div className={styles.submitButtonContainer}>
                <Button type="submit" label="Зберегти"/>
                <Button label="Відмінити" type="button" className="p-button-secondary"
                        onClick={() => setEditCategory(false)}/>
              </div>
            </Form>
        )}
      </Formik>
  );
};

export default EditCategory;