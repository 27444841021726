import {
  SET_ALL_CATEGORIES, SET_CATEGORIES_CURRENT_PAGE, SET_CATEGORIES_ROWS_IN_TABLE,
  SET_CLIENTS_CURRENT_PAGE, SET_CLIENTS_ROWS_IN_TABLE,
} from '../constants/actionTypes';

export const setAllCategories = (categories) => ({type: SET_ALL_CATEGORIES, payload: categories});

export const setCategoriesCurrentPage = (page) => ({type: SET_CATEGORIES_CURRENT_PAGE, payload: page});
export const setCategoriesRowsInTable = (count) => ({type: SET_CATEGORIES_ROWS_IN_TABLE, payload: count});

