import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import {
  AUTH_TEMPORARY_TOKEN,
  AUTH_TOKEN,
  AUTH_USER_ID,
  AUTH_USER_ROLE,
  getUserId,
  getUserRole,
  loggedIn,
} from '../../store/actions/loginActions';
import { useHistory } from 'react-router-dom';
import routes from '../../constants/routes';
import { popupDialog } from '../../commons/PopupDialog/PopupDialog';
import { Button } from 'primereact/button';
import {
  getUserData,
  getUserNotificationById,
} from '../../services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNotificationsIsVisible,
  setUnreadNotifications,
} from '../../store/actions/notificationsActions';
import { setChatVisibility } from '../../store/actions/chatActions';
import { setUserName } from '../../store/actions/usersActions';

import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

const Header = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const userRole = Number(getUserRole() || 4);
  const dispatch = useDispatch();
  const notificationsIsActive = useSelector(
    (state) => state.notificationsReducer.isVisible
  );
  const unreadNotifications = useSelector(
    (state) => state.notificationsReducer.unreadNotifications
  );
  const userName = useSelector((state) => state.usersReducer.userName);
  const userId = getUserId();

  const history = useHistory();

  const toProfilePage = () => {
    history.push(routes.PROFILE_PAGE);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_TEMPORARY_TOKEN);
    localStorage.removeItem(AUTH_USER_ID);
    localStorage.removeItem(AUTH_USER_ROLE);
    dispatch(setUserName(''));

    history.push(routes.LOGIN_PAGE);
  };

  const notificationCount = () => {
    if (unreadNotifications > 9) {
      return '9+';
    }

    return unreadNotifications;
  };

  useEffect(() => {
    // if (localStorage.getItem(AUTH_USER_ROLE) === '3') {
    //   return;
    // }

    if (userId) {
      (async () => {
        const fullUserData = await getUserData(userId);
        const notifications = (await getUserNotificationById(userId)) || [];
        const unreadNotification = notifications.filter(
          (item) => !item.read
        )?.length;
        dispatch(setUnreadNotifications(unreadNotification));
        dispatch(setUserName(fullUserData));
      })();
    }
  }, []);

  const toLoginPage = () => {
    popupDialog(
      'Ви дійсно хочете вийти?',
      () => clearLocalStorage(),
      'Так',
      () => {},
      'Ні'
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.leftMenu}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      />
      <img
        src={require('./../../assets/img/Logo.svg')}
        alt='Logo'
        height={50}
        width={50}
        onClick={() => history.push(routes.HOME_PAGE)}
        className={styles.logo}
      />
      <div className={styles.rightMenu}>
        {loggedIn() && (
          <>
            <div className={styles.profileIconContainer}>
              <div>{userName}</div>
              <Button
                icon='pi pi-user'
                style={{ fontSize: 20, backgroundColor: '#3c8dbc' }}
                tooltip='Профіль'
                tooltipOptions={{ position: 'bottom' }}
                onClick={() => {
                  toProfilePage();
                  dispatch(setNotificationsIsVisible(false));
                }}
              />
            </div>
            {userRole !== 4 && userRole !== 3 && (
              <div
                style={{ position: 'relative' }}
                onClick={() => {
                  dispatch(setNotificationsIsVisible(!notificationsIsActive));
                  dispatch(setChatVisibility(false));
                }}
              >
                <Button
                  icon='pi pi-bell'
                  style={{ fontSize: 20, backgroundColor: '#3c8dbc' }}
                  tooltip='Сповіщення'
                  tooltipOptions={{ position: 'bottom' }}
                />
                {unreadNotifications > 0 && (
                  <div className={styles.notificationsMark}>
                    {notificationCount()}
                  </div>
                )}
              </div>
            )}
            <Button
              icon='pi pi-sign-out'
              style={{ fontSize: 22, backgroundColor: '#3c8dbc' }}
              tooltip='Вихід'
              tooltipOptions={{ position: 'bottom' }}
              onClick={() => {
                toLoginPage();
                dispatch(setNotificationsIsVisible(false));
              }}
            />

            {userRole === 3 && (
              <div>
                <button
                  style={{
                    fontSize: 22,
                    backgroundColor: '#3c8dbc',
                    color: '#fff',
                  }}
                  onClick={() => changeLanguage('en')}
                >
                  En
                </button>
                <button
                  style={{
                    fontSize: 22,
                    backgroundColor: '#3c8dbc',
                    color: '#fff',
                  }}
                  onClick={() => changeLanguage('ua')}
                >
                  Ua
                </button>
                <button
                  style={{
                    fontSize: 22,
                    backgroundColor: '#3c8dbc',
                    color: '#fff',
                  }}
                  onClick={() => changeLanguage('de')}
                >
                  De
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
