import axios from 'axios';
import { BASE_URL } from '../constants/serviceConstants';
import { popupDialog } from '../commons/PopupDialog/PopupDialog';
const AUTH_TOKEN = 'auth-token';

export const getAllUsers = async () => {
  return await axios
    .get(`${BASE_URL}/users/admins`, {
      headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
    })
    .then(({ data }) => data)
    .catch((err) => console.log('error', err));
};

export const addNewUser = async (values, role, categories) => {
  const { email, additional_phone, phone, fullName } = values;
  const splitName = (fullName || '').split(' ');
  const data = {
    email,
    role,
    phone,
    additional_phone,
    fullName,
    enabled: false,
    name: splitName[0],
    surname: splitName[1],
    lastName: splitName[2],
    registrationType: 'email',
  };

  if (categories.length) {
    data.categories = categories;
  }

  return await axios
    .post(`${BASE_URL}/users/addUserBackOffice`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
    })
    .then(({ data }) => data.success)
    .catch(({ response }) =>
      popupDialog(`${response.data.message}`, () => {}, 'Ок')
    );
};

export const editUser = async (values, role, categories) => {
  const { id, additional_phone, phone, fullName } = values;
  const splitName = (fullName || '').split(' ');
  const data = {
    role: { role },
    name: splitName[0],
    surname: splitName[1],
    lastName: splitName[2],
    phone,
    additional_phone,
    fullName,
    categories,
  };

  return await axios
    .patch(`${BASE_URL}/users/${id}`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
    })
    .then(({ data }) => {
      console.log('data', data);
      return data.success;
    })
    .catch(({ response }) =>
      popupDialog(`${response.data.message}`, () => {}, 'Ок')
    );
};

export const changeEnableType = async (id) => {
  return await axios
    .patch(
      `${BASE_URL}/users/enabledOrDisabled/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      }
    )
    .then(({ data }) => console.log('data', data))
    .catch(({ response }) =>
      popupDialog(`${response.data.message}`, () => {}, 'Ок')
    );
};

export const removeUser = async (id) => {
  return await axios
    .delete(`${BASE_URL}/users/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
    })
    .then(({ data }) => console.log('data', data))
    .catch(({ response }) =>
      popupDialog(`${response.data.message}`, () => {}, 'Ок')
    );
};
