import React, { useEffect, useState } from 'react';
import styles from './Notifications.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';
import {
  getUserNotificationById,
  setNotificationIsRead,
} from '../../../services/UserService';
import { getUserId } from '../../../store/actions/loginActions';
import { setCurrentTenderIdToStorage } from '../../../store/actions/tendersActions';
import { setCurrentClient } from '../../../store/actions/clientsActions';
import { setAllNotifications, setUnreadNotifications } from '../../../store/actions/notificationsActions';

const Notifications = () => {
  const allNotifications = useSelector(state => state.notificationsReducer.allNotification);
  const notificationsIsActive = useSelector(state => state.notificationsReducer.isVisible);
  const socket = useSelector(state => state.tenderReducer.socket);
  const unreadNotification = allNotifications.filter(item => !item.read);
  const notifIsActive = useSelector(state => state.notificationsReducer.isVisible);
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = getUserId();

  useEffect(() => {
    if (userId) {
      (async () => {
        const notifications = await getUserNotificationById(userId) || [];
        dispatch(setAllNotifications(notifications));
      })()
    }
  }, [notificationsIsActive]);

  useEffect(() => {
    if (socket && userId) {
      socket.emit("subscribeUserToNotifications", userId);

      socket.on('notifications', async () => {
        const notifications = await getUserNotificationById(userId) || [];
        const unreadNotification = notifications.filter(item => !item.read).length;
        dispatch(setUnreadNotifications(unreadNotification));
      });
    }

    return () => {
      if (socket) {
        socket.off('notifications');
        socket.off('subscribeUserToNotifications');
        socket.emit("unsubscribeUserToNotifications", userId);
      }
    };
  }, [socket]);

  const getFullDate = (fullDate) => {
    const date = fullDate?.match(/\d+-\d+-\d+/)[0]?.split('-').slice(1, 3).reverse().join('-');
    const time = new Date(fullDate).toString()?.match(/\d+:\d+/)[0];

    return `${date} ${time}`
  };

  const openNotification = async (notification) => {
    if (!Object.entries(notification?.data || {}).length) {
      await setNotificationIsRead(userId, notification._id);
      history.push(routes.CLIENTS_PAGE);
    }

    if (notification?.data?.tender_id) {
      await setNotificationIsRead(userId, notification._id);
      setCurrentTenderIdToStorage(notification?.data?.tender_id);
      history.push(routes.TENDER_PAGE);
      history.push(routes.TENDER_DETAILS_PAGE);
    }

    if (notification?.data?.user_id) {
      await setNotificationIsRead(userId, notification._id);
      dispatch(setCurrentClient({ id: notification?.data?.user_id }));
      history.push(routes.HISTORY_CLIENT);
    }

    if (userId) {
      const notifications = await getUserNotificationById(userId) || [];
      const unreadNotification = notifications.filter(item => !item.read)?.length;
      dispatch(setUnreadNotifications(unreadNotification));
    }
  };

  return (
    <div
      className={styles.notificationsContainer}
      style={{ display: notifIsActive ? 'block' : 'none' }}
    >
      <div className={styles.notificationsHeader}>
        {unreadNotification.length !== 0 && `Ви маєте ${unreadNotification.length} непрочитаних сповіщень`}
      </div>
      <ul className={styles.notificationsContent}>
        {allNotifications.length === 0
          ? (
            <span>Сповіщення відсутні</span>
          )
          : (
            <>
              {allNotifications.map((notification, index) => (
                <li
                  key={notification._id}
                  style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f4f4f4' }}
                  onClick={async () => { await openNotification(notification) }}
                >
                  <div className={styles.text}>
                    <i className="pi pi-file" />
                    <div>{notification.body}</div>
                  </div>
                  <div className={styles.time}>
                    {(!notification.read) && <div className={styles.indicator} />}
                    {getFullDate(notification.created_at)}
                  </div>
                </li>
              ))}
            </>
          )
        }
      </ul>
      <div
        className={styles.notificationsFooter}
        onClick={() => console.log('click')}
      >
        {allNotifications.length !== 0 && 'Переглянути'}
      </div>
    </div>
  );
};

export default Notifications;
