import React from 'react';
import * as Yup from 'yup';
import Header from '../../Header';
import styles from '../Login.module.scss';
import {Field, Form, Formik} from 'formik';
import {Button} from 'primereact/button';
import FormPassword from '../../../commons/FormPassword';
import {setNewPassword} from '../../../services/LoginService';

const RestorePassword = ({backToLoginPage, toHomePage, temporaryToken}) => {

  const passwordValidationSchema = Yup.object().shape({
    password: Yup
    .string()
    .min(8, 'Пароль повинен містити мінімум 8 символів')
    .matches(/(?!.*[\s])(?=.*[a-z])(?=.*[0-9]).{8,}$/i, 'Пароль повинен містити мінімум одну цифру')
    .required("Це поле обов'язкове"),
    confirmPassword: Yup
    .string()
    .required("Це поле обов'язкове")
    .test('passwords-match', 'Паролі не співпадають',
        function(value) {
          return this.parent.password === value;
        }),
  });

  return (
      <>
        <Header/>
        <div className={styles.container}>
          <h2>Відновлення паролю</h2>
          <Formik
              initialValues={{
                password: '',
                confirmPassword: '',
              }}
              validationSchema={passwordValidationSchema}
              onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);
                const isValid = setNewPassword(temporaryToken, values.password);
                isValid && toHomePage()
              }}
              validateOnChange={true}
              validateOnMount={true}
          >
            {({values}) => (
                <Form className={styles.formContainer}>
                    <Field name={`password`}
                           label="Новий пароль"
                           component={FormPassword}
                    />
                    <Field name={`confirmPassword`}
                           label="Повторіть пароль"
                           component={FormPassword}
                    />
                  <div className={styles.buttonContainer}>
                    <Button
                        label="Зберегти"
                        type="submit"
                    />
                  </div>
                </Form>
            )}
          </Formik>
          <Button
              label="Авторизація"
              className="p-button-secondary"
              onClick={backToLoginPage}
          />

        </div>
      </>
  );
};

export default RestorePassword;
