// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SettingsPopup_popupContainer__1oZt9 {\n  min-height: 300px;\n  min-width: 500px; }\n\n.SettingsPopup_contentContainer__2LPcO {\n  min-height: 300px;\n  padding: 40px 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.SettingsPopup_buttonsContainer__2Obb1 {\n  padding: 0 80px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  flex-wrap: nowrap; }\n", ""]);
// Exports
exports.locals = {
	"popupContainer": "SettingsPopup_popupContainer__1oZt9",
	"contentContainer": "SettingsPopup_contentContainer__2LPcO",
	"buttonsContainer": "SettingsPopup_buttonsContainer__2Obb1"
};
module.exports = exports;
