import {
  SET_CURRENT_USER,
  SET_ALL_USERS,
  SET_USER_ROLE,
  SET_CLIENTS_CURRENT_PAGE,
  SET_CLIENTS_ROWS_IN_TABLE,
  SET_USERS_CURRENT_PAGE,
  SET_USERS_ROWS_IN_TABLE,
  SET_CURRENT_USER_ID,
  SET_USER_NAME,
} from '../constants/actionTypes';
import {getUserId} from './loginActions';
const AUTH_USER_ID = 'auth-user-id';

export const setAllUsers = (users) => ({type: SET_ALL_USERS, payload: users.filter(item => item.id !== localStorage.getItem(AUTH_USER_ID))});
export const setCurrentUser = (employee) => ({type: SET_CURRENT_USER, payload: employee});
export const setCurrentUserID = (id) => ({type: SET_CURRENT_USER_ID, payload: id});
export const setUserRole = (role) => ({type: SET_USER_ROLE, payload: role});
export const setUserName = (name) => ({type: SET_USER_NAME, payload: name});

export const setUserCurrentPage = (page) => ({type: SET_USERS_CURRENT_PAGE, payload: page});
export const setUserRowsInTable = (count) => ({type: SET_USERS_ROWS_IN_TABLE, payload: count});

// export const setAllUsersData = (users) => (dispatch) => {
//   dispatch(setAllUsers(users));
// };

