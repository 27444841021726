// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatRoma_sideBar__3A2P7 {\n  height: calc(100vh - 70px) !important;\n  margin-top: 70px;\n  width: 1500px;\n  background-color: #f9f9f9; }\n\n.ChatRoma_adminInputsContainer__2P65m {\n  height: calc(100vh - 72px) !important;\n  margin-top: 70px;\n  overflow: scroll;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: column-reverse;\n  align-items: flex-start;\n  padding-bottom: 70px;\n  overflow-x: hidden; }\n\n.ChatRoma_adminInput__E7r0d {\n  margin-top: 2rem;\n  width: 92%;\n  position: fixed;\n  bottom: 20px; }\n\n.ChatRoma_closeButton__3TNcE {\n  position: fixed;\n  right: 3px; }\n\n.ChatRoma_messageContainer__kGfTi {\n  display: flex;\n  flex-direction: column;\n  padding-right: 15px;\n  width: 100%; }\n\n.ChatRoma_controlsContainer__2kBDk {\n  width: 100%;\n  height: 60px;\n  background-color: #fff;\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.ChatRoma_buttonsContainer__1CrYO {\n  margin-left: 14em;\n  flex-direction: row;\n  display: flex; }\n", ""]);
// Exports
exports.locals = {
	"sideBar": "ChatRoma_sideBar__3A2P7",
	"adminInputsContainer": "ChatRoma_adminInputsContainer__2P65m",
	"adminInput": "ChatRoma_adminInput__E7r0d",
	"closeButton": "ChatRoma_closeButton__3TNcE",
	"messageContainer": "ChatRoma_messageContainer__kGfTi",
	"controlsContainer": "ChatRoma_controlsContainer__2kBDk",
	"buttonsContainer": "ChatRoma_buttonsContainer__1CrYO"
};
module.exports = exports;
