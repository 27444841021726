// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClientHistory_mainContainer__3OjLA {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.ClientHistory_container__1pkYf {\n  height: calc(100vh - 80px);\n  width: 100%;\n  padding: 50px;\n  overflow: scroll;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .ClientHistory_container__1pkYf .ClientHistory_title__1W8_k {\n    font-weight: bold;\n    font-size: 25px;\n    margin-bottom: 25px; }\n\n.ClientHistory_historyContainer__3p836 {\n  width: 100%;\n  max-width: 800px;\n  padding: 25px;\n  margin-bottom: 20px;\n  background-color: #f4f4f4;\n  border: 1px solid #c8c8c8; }\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "ClientHistory_mainContainer__3OjLA",
	"container": "ClientHistory_container__1pkYf",
	"title": "ClientHistory_title__1W8_k",
	"historyContainer": "ClientHistory_historyContainer__3p836"
};
module.exports = exports;
