import React from 'react';
import {render} from 'react-dom';
import {Button} from 'primereact/button';
import {Card} from 'primereact/card';
import styles from './PopupDialog.module.scss'

const PopupDialog = ({content, cbOk, labelOk, cbCancel, cancelLabel}) => {
  const cancel = () => {
    if (cbCancel) cbCancel();
    render(null, document.getElementById('popup'));
  };

  const ok = () => {
    if (cbOk) cbOk();
    cancel();
  };

  const footer = <span className={styles.buttonContainer}>
                <Button label={labelOk || 'Зберегти'} style={{marginRight: 20}}
                        onClick={ok}/>
                {!!cbCancel && (
                    <Button
                        label={cancelLabel || "Відмінити"}
                        className="p-button-secondary"
                        onClick={cancel}
                    />
                        )}
             </span>;

  return (
      <div className={styles.container}>
        <Card footer={footer} className={styles.card}>
          {content}
        </Card>
      </div>
  );
};

export const popupDialog = (content, cbOk, labelOk, cbCancel, cancelLabel) => {
  render(<PopupDialog
      content={content}
      cancelLabel={cancelLabel}
      cbOk={cbOk}
      cbCancel={cbCancel}
      labelOk={labelOk}/>, document.getElementById('popup'));
};
