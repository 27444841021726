// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Clients_container__3pqRI {\n  padding: 25px 50px;\n  height: calc(100vh - 80px);\n  overflow: scroll; }\n\n.Clients_mainContainer__25c5G {\n  height: calc(100vh - 70px);\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.Clients_buttonContainer__RpeCB {\n  width: 100%;\n  text-align: right;\n  padding-bottom: 1rem; }\n\n.Clients_tableButtonContainer__1_p0t {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .Clients_tableButtonContainer__1_p0t button {\n    margin-right: 5px !important; }\n    .Clients_tableButtonContainer__1_p0t button:last-child {\n      margin-right: 0 !important; }\n\n.Clients_statusActive__3t5It {\n  font-weight: 700;\n  font-size: 11px;\n  text-transform: uppercase;\n  color: #256029;\n  padding: 3px;\n  background-color: #c8e6c9; }\n\n.Clients_statusBlocked__1jXQA {\n  font-weight: 700;\n  font-size: 11px;\n  text-transform: uppercase;\n  color: #c63737;\n  padding: 3px;\n  background-color: #fbcdd2; }\n\n.Clients_ownerStyle__1IhEz {\n  font-weight: 700;\n  font-size: 11px;\n  text-transform: uppercase;\n  color: #23547b;\n  padding: 3px;\n  background-color: #b3e5fc; }\n\n.Clients_clientStyle__1JtkZ {\n  font-weight: 700;\n  font-size: 11px;\n  text-transform: uppercase;\n  color: #805b36;\n  padding: 3px;\n  background-color: #ffd8b2; }\n\n.Clients_dropdownContainer__201dR {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .Clients_dropdownContainer__201dR span {\n    margin-right: 20px; }\n  .Clients_dropdownContainer__201dR b {\n    font-size: 22px; }\n", ""]);
// Exports
exports.locals = {
	"container": "Clients_container__3pqRI",
	"mainContainer": "Clients_mainContainer__25c5G",
	"buttonContainer": "Clients_buttonContainer__RpeCB",
	"tableButtonContainer": "Clients_tableButtonContainer__1_p0t",
	"statusActive": "Clients_statusActive__3t5It",
	"statusBlocked": "Clients_statusBlocked__1jXQA",
	"ownerStyle": "Clients_ownerStyle__1IhEz",
	"clientStyle": "Clients_clientStyle__1JtkZ",
	"dropdownContainer": "Clients_dropdownContainer__201dR"
};
module.exports = exports;
