import React from 'react';
import {connect} from 'react-redux'
import Registration from '../../components/Registration';
import routes from '../../constants/routes';

const RegistrationPage = ({history}) => {
  const toLoginPage = () => {
    history.push(routes.LOGIN_PAGE);
  };

  const toHomePage = () => {
    history.push(routes.HOME_PAGE);
  };

  return (
      <Registration
          toHomePage={toHomePage}
          toLoginPage={toLoginPage}
      />
  );
};

export default connect()(RegistrationPage);
