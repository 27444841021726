// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PopupDialog_container__3VhmW {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap;\n  background-color: rgba(0, 0, 0, 0.19);\n  z-index: 10000;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  text-align: center; }\n\n.PopupDialog_card__1pWt1 {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap;\n  width: 350px;\n  min-height: 100px; }\n", ""]);
// Exports
exports.locals = {
	"container": "PopupDialog_container__3VhmW",
	"card": "PopupDialog_card__1pWt1"
};
module.exports = exports;
