import React, { useRef, useState } from 'react';
import { setNotificationsIsVisible } from '../../../store/actions/notificationsActions';
import Notifications from '../../Header/Notifications/Notifications';
import Header from '../../Header';
import styles from './OrdersRegistry.module.scss';
import SidebarPage from '../../../containers/Sidebar/SidebarPage';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from 'react-redux';
import SettingsPopup from '../../../commons/SettingsPopup/SettingsPopup';
import { setCurrentPageFreeTenders } from '../../../store/actions/tendersActions';
import { Dropdown } from 'primereact/dropdown';
import Preloader from '../../../commons/Preloader/Preloader';
import EditOrdersRegistry from './EditOrdersRegistry/EditOrdersRegistry';
import { BASE_URL } from '../../../constants/serviceConstants';

import { useTranslation } from 'react-i18next';
const OrdersRegistry = ({
  preloaderIsActive,
  refresh,
  setRefresh,
  isEditingOrdersRegistry,
  setIsEditingOrdersRegistry,
}) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { allTenders } = useSelector((state) => state.supplierReducer);
  const page = useSelector(
    (state) => state.tenderReducer.currentFreeTendersPage
  );
  const [rowCount, setRowCount] = useState(10);
  const [selectedTender, setSelectedTender] = useState(
    Array.isArray(allTenders) && allTenders.length ? allTenders[0] : null
  );
  const currentRow = useRef();

  const rowsCountData = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  const handleSelectTender = (e) => {
    if (e.originalEvent?.target) currentRow.current = e.originalEvent?.target;
    setSelectedTender(e.value);
    setIsEditingOrdersRegistry(true);
  };

  const dateStringFormat = (dateString) => {
    let date = new Date(dateString);
    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    let month =
      date.getMonth() < 9
        ? `0${date.getMonth() + 1}`
        : `0${date.getMonth() + 1}`;
    let year = date.getFullYear();
    let hour =
      date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    let min =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    return `${day}.${month}.${year} ${hour}:${min}`;
  };

  const closeOrderEditForm = () => {
    setIsEditingOrdersRegistry(false);
    if (currentRow.current?.tagName === 'TD') {
      currentRow.current.parentElement.focus();
    } else if (currentRow.current?.tagName === 'TR') {
      currentRow.current.focus();
    }
  };

  // FILES

  const newStyles = {
    display: 'flex',
    justifyContent: 'center',
  };

  return (
    <div style={{ position: 'relative' }}>
      <Preloader active={preloaderIsActive} />
      <Header />
      <div
        className={styles.mainContainer}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      >
        <SidebarPage />
        <Notifications />
        <div className={styles.contentContainer}>
          <div className={styles.dropdownContainer}>
            <div>
              <span>{t('suppliers.rowsCount')}:</span>
              <Dropdown
                value={rowCount}
                options={rowsCountData}
                onChange={(e) => setRowCount(e.value)}
              />
            </div>
          </div>
          <SettingsPopup
            visible={isEditingOrdersRegistry}
            onHide={closeOrderEditForm}
            headerText={t('suppliers.editPropose')}
          >
            <EditOrdersRegistry
              refresh={refresh}
              setRefresh={setRefresh}
              selectedTender={selectedTender}
              setEditingOrdersRegistry={setIsEditingOrdersRegistry}
            />
          </SettingsPopup>
          <DataTable
            paginator
            value={allTenders}
            editMode='cell'
            rows={rowCount}
            first={page}
            onPage={(e) => dispatch(setCurrentPageFreeTenders(e.first))}
            selection={selectedTender}
            onSelectionChange={handleSelectTender}
            selectionMode='single'
            dataKey='tender_item_id'
            stateStorage='session'
            className={styles.registryTable}
            emptyMessage='No tenders found.'
          >
            <Column
              field='internal_id'
              header={t('suppliers.orderNumber')}
              style={{ width: '15%', wordBreak: 'break-word' }}
              sortable
              filter
              bodyStyle={{ textAlign: 'center' }}
            />
            <Column
              field='client_name'
              header={t('suppliers.clientNumber')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              sortable
              filter
              filterMatchMode='contains'
            />
            <Column
              field='goods_delivery_address'
              header={t('suppliers.shippingAddress')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              sortable
              filter
              filterMatchMode='contains'
            />
            <Column
              field='category_name'
              header={t('suppliers.subCategory')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              sortable
              filter
              filterMatchMode='contains'
            />
            <Column
              field='attachments'
              header={t('suppliers.attachments')}
              style={{ width: '25%' }}
              body={(e) => (
                <div
                  className={styles.files}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {e?.files?.map((file, index) => {
                    const fileName = `${file.filename}.${file.extension}`;
                    return (
                      <div
                        style={{ position: 'relative', width: 100 }}
                        key={index}
                      >
                        <a
                          className={styles.fileWrapper}
                          target='_blank'
                          href={`${BASE_URL}${file.path}`}
                        >
                          {(() => {
                            switch (file.extension) {
                              case 'xlsx':
                                return (
                                  <i
                                    className='pi pi-file-excel'
                                    style={{ fontSize: 25 }}
                                  />
                                );
                              case 'pdf':
                                return (
                                  <i
                                    className='pi pi-file-pdf'
                                    style={{ fontSize: 25 }}
                                  />
                                );
                              case 'jpg':
                                return (
                                  <i
                                    className='pi pi-image'
                                    style={{ fontSize: 25 }}
                                  />
                                );
                              case 'png':
                                return (
                                  <i
                                    className='pi pi-image'
                                    style={{ fontSize: 25 }}
                                  />
                                );
                              default:
                                return (
                                  <i
                                    className='pi pi-file'
                                    style={{ fontSize: 25 }}
                                  />
                                );
                            }
                          })()}
                          {/* <span>{file.extension}</span> */}
                        </a>
                      </div>
                    );
                  })}
                  {e?.audio && (
                    <a
                      className={styles.fileWrapper}
                      target='_blank'
                      href={`${BASE_URL}${e?.audio?.path}`}
                    >
                      <i className='pi pi-volume-up' style={{ fontSize: 25 }} />
                    </a>
                  )}
                </div>
              )}
            />
            <Column
              field='tender_name'
              header={t('suppliers.orderName')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='item_name'
              header={t('suppliers.product')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='item_description'
              header={t('suppliers.description')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filterMatchMode='contains'
            />
            <Column
              field='tender_description'
              header={t('suppliers.orderDetails')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              filterMatchMode='contains'
            />
            <Column
              field='tender_item_amount'
              header={t('suppliers.count')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='tender_bargain_end_date'
              header={t('suppliers.endTime')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              body={(rowData) => {
                return (
                  <span>
                    {dateStringFormat(rowData?.tender_bargain_end_date)}
                  </span>
                );
              }}
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='supplierItem'
              header={t('suppliers.proposeProduct')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              filter
              sortable
              filterMatchMode='contains'
            />
            <Column
              field='offer_amount'
              header={t('suppliers.shippingCount')}
              style={{ width: '15%', wordBreak: 'break-word' }}
              sortable
            />
            <Column
              field='supplierPrice'
              header={t('suppliers.price')}
              style={{ width: '15%', wordBreak: 'break-word' }}
              sortable
            />
            <Column
              field='offer_sum'
              header={t('suppliers.summa')}
              style={{ width: '15%', wordBreak: 'break-word' }}
              sortable
            />
            <Column
              field='supplierComment'
              header={t('suppliers.comment')}
              style={{ width: '30%', wordBreak: 'break-word' }}
            />
            <Column
              field='tender_item_min_price'
              header={t('suppliers.minPrice')}
              style={{ width: '22%', wordBreak: 'break-word' }}
              sortable
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default OrdersRegistry;
