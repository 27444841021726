// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OwnerCard_container__2lB55 {\n  height: calc(100vh - 80px);\n  margin: 0 auto;\n  width: 70%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.OwnerCard_formContainer__1oz-U {\n  background-color: #fff;\n  padding: 50px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n  .OwnerCard_formContainer__1oz-U input {\n    width: 250px; }\n  .OwnerCard_formContainer__1oz-U b {\n    display: block;\n    font-size: 22px;\n    margin-bottom: 30px; }\n\n.OwnerCard_buttonContainer__1X1u1 {\n  padding: 10px; }\n  .OwnerCard_buttonContainer__1X1u1 button:first-child {\n    margin-top: 10px;\n    margin-right: 55px; }\n\n.OwnerCard_dropdownContainer__yV1ql {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px; }\n\n.OwnerCard_mainContainer__3tZZ8 {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.OwnerCard_companyContainer__3wPgH {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-column-gap: 20px;\n  column-gap: 20px;\n  grid-template-rows: repeat(4, 1fr);\n  padding: 0 20px; }\n\n.OwnerCard_wrapper__1WlMH {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.OwnerCard_ownerContainer__OwS1u {\n  padding: 0 20px; }\n", ""]);
// Exports
exports.locals = {
	"container": "OwnerCard_container__2lB55",
	"formContainer": "OwnerCard_formContainer__1oz-U",
	"buttonContainer": "OwnerCard_buttonContainer__1X1u1",
	"dropdownContainer": "OwnerCard_dropdownContainer__yV1ql",
	"mainContainer": "OwnerCard_mainContainer__3tZZ8",
	"companyContainer": "OwnerCard_companyContainer__3wPgH",
	"wrapper": "OwnerCard_wrapper__1WlMH",
	"ownerContainer": "OwnerCard_ownerContainer__OwS1u"
};
module.exports = exports;
