import React, { useEffect, useState } from 'react';
import styles from '../TenderCardEdit.module.scss';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { popupDialog } from '../../../../commons/PopupDialog/PopupDialog';
import { getUserRole } from '../../../../store/actions/loginActions';
import { addTenderOffer, deleteTenderOffer } from '../../../../store/actions/tendersActions';
import { useDispatch, useSelector } from 'react-redux';
import { uuid } from 'uuidv4';

const AddSuppliers = ({
  suppliersList, currentTender, setPreloaderIsActive,
  isAddSuppliers, setAddSuppliers,
  setCurrentSupplier, setEditSupplierActive, tenderId,
  amount, setAmount, newItem, setNewItem, newCategory,
  setNewCategory, setNewItemId, newItemId, supplierItem, setSupplierItem, supplierComment, setSupplierComment,
  selectedSupplier, setSelectedSupplier }) => {
  const dispatch = useDispatch();
  const userRole = Number(getUserRole() || 4);
  const [price, setPrice] = useState('');
  const [currentCategories, setCurrentCategories] = useState([...new Set(currentTender?.items?.map(category => category?.item?.category?.name))]);
  const [newCompanyName, setNewCompanyName] = useState('');
  
  const formValidation = !!price * !!amount * !!newCompanyName * !!newCategory * !!newItem;
  const currentItemsList = useSelector(state => state.tenderReducer.currentTender.items);
  const currentCategoriesObj = currentCategories?.map(category => ({ label: category, value: category }));
  const currentCategoriesObjBySupplier = suppliersList
    .find(supplier => supplier.id === selectedSupplier)?.categories
    .map((category) => ({ label: category.name, value: category.name }));
  const offersList = currentItemsList?.map(({ offers, item, _id }) => {
    if (!offers) {
      return
    }

    return offers.map((offer) => ({ ...offer, item, tenderItemId: _id }));
  })
  const currentOffersList = offersList?.flat().filter(Boolean);

  const removeOffer = ({ name, tenderItemId, _id }) => {
    const remove = async () => {
      setPreloaderIsActive(true);
      dispatch(deleteTenderOffer(tenderItemId, _id));
      setPreloaderIsActive(false);
    };
    popupDialog(`Ви дійсно хочете видалити пропозицію ${name}?`, async () => await remove(), 'Так', () => { }, 'Ні');
  };

  const getSum = (array) => {
    return array?.reduce((acc, item) => {
      if (item?.accepted) {
        return acc + +item.sum
      }

      return acc;
    }, 0)
  };

  const handleSubmit = async (itemId, newCompanyName, amount, price) => {
    if (!formValidation) {
      return popupDialog('Всі поля мають бути заповнені', () => { }, 'Ок')
    }

    setPreloaderIsActive(true);

    const resetForm = () => {
      setAddSuppliers(false);
      setNewCompanyName('');
      setNewCategory('');
      setNewItem('');
      setAmount('');
      setPrice('');
      setSupplierItem('');
      setSupplierComment('');
    };

    const selectedSupplierObject = suppliersList.find((supplier) => supplier.id === selectedSupplier)

    dispatch(addTenderOffer({
      _id: `new${uuid()}`,
      accepted: null,
      name: newCompanyName,
      description: "",
      date_begin: null,
      date_end: null,
      city: null,
      address: null,
      additional_conditions: null,
      currency: "грн",
      amount,
      price,
      sum: String(amount * price),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      supplierItem,
      supplierComment,
      supplierCount: null,
      supplierPrice: price,
      delivered: null,
      sent: null,
      tender: {
        _id: tenderId
      },
      supplier: selectedSupplierObject,
    },
      newItemId,
    ));

    resetForm();

    setPreloaderIsActive(false);
  };

  const handleChangeSupplier = (value) => {
    setSelectedSupplier(value);
    setNewCompanyName(suppliersList.find((supplier) => supplier.id === value)?.fullName);
  }
  const goodsList = currentTender?.items
    ?.filter(item => item?.item?.category?.name === newCategory)
    .map(category => (category?.item?.name))

  const uniqueGoods = [...new Set(goodsList)];
  const preparedUniqueGoods = uniqueGoods.map((good) => ({ label: good, value: good }))
  const preparedSuppliersList = suppliersList.filter(supplier => supplier?.categories
    .find(category => category.name === newCategory))
    .map(supplier => ({ label: supplier?.fullName, value: supplier?.id }));

  const footerGroup = (
    <ColumnGroup>
      {isAddSuppliers && (
        <Row>
          <Column columnKey='actions' header="Статус" footer={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <i
                className="pi pi-circle-off"
                style={{ color: "grey" }}
              />
            </div>
          } />
          <Column footer={<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button icon="pi pi-check" type={isAddSuppliers ? "submit" : "button"} tooltip="Зберегти" tooltipOptions={{ position: 'top' }} onClick={async () => { setAddSuppliers(false); await handleSubmit(tenderId, newCompanyName, amount, price) }} />
            <Button icon="pi pi-times" type="button" className="p-button-danger" tooltip="Видалити" tooltipOptions={{ position: 'top' }} onClick={() => setAddSuppliers(false)} />
          </div>}
          />
          <Column footer={<div>
            <Dropdown
              value={selectedSupplier}
              style={{ width: '100%', marginBottom: '5px' }}
              options={preparedSuppliersList}
              showClear
              onChange={(e) => handleChangeSupplier(e.value)}
              disabled={preparedSuppliersList.length === 0}
            />
            <InputText
              style={{ width: '100%' }}
              value={newCompanyName}
              onChange={e => setNewCompanyName(e.target.value)}
              disabled={selectedSupplier}
            />
          </div>}
          />
          <Column footer={<div>
            <Dropdown
              style={{ width: '100%' }}
              options={selectedSupplier ? currentCategoriesObjBySupplier : currentCategoriesObj}
              value={newCategory}
              onChange={e => setNewCategory(e.target.value)}
              filter={true}
              showClear={true}
              filterPlaceholder="Виберіть товар"
              filterBy="label,value"
              disabled
            />
          </div>}
          />
          <Column footer={<div>
            <Dropdown
              style={{ width: '100%' }}
              options={preparedUniqueGoods}
              value={newItem}
              onChange={e => setNewItem(e.target.value)}
              filter={true}
              showClear={true}
              filterPlaceholder="Виберіть товар"
              filterBy="label,value"
              disabled
            />
          </div>}
          />
          <Column footer={<div>
            <InputText
              style={{ width: '100%' }}
              value={supplierItem}
              onChange={e => setSupplierItem(e.target.value)}
              disabled={!newItem}
            />
          </div>}
          />
          <Column footer={<div>
            <InputText
              style={{ width: '100%' }}
              value={supplierComment}
              onChange={e => setSupplierComment(e.target.value)}
              disabled={!newItem}
            />
          </div>}
          />
          <Column footer={<div>
            <InputText
              style={{ width: '100%' }}
              value={amount}
              onChange={e => setAmount(e.target.value)}
              disabled={!newItem}
            />
          </div>}
          />
          <Column footer={<div>
            <InputText
              style={{ width: '100%' }}
              value={price}
              onChange={e => setPrice(e.target.value)}
              disabled={!newItem}
            />
          </div>}
          />
          <Column footer={<div style={{ textAlign: 'left', fontWeight: 500 }}>
            {price * amount || 0}
          </div>}
          />
        </Row>
      )
      }
      <Row>
        <Column footer="Загальна сума" colSpan={9} />
        <Column footer={getSum(currentOffersList)} style={{ textAlign: 'left' }} />
      </Row>
    </ColumnGroup>
  );

  useEffect(() => {
    (async () => {
      if (isAddSuppliers) {
        const filteredCategories = currentTender?.items?.map(category => category?.item?.category?.name);

        if (filteredCategories) {
          setCurrentCategories([...new Set(filteredCategories)]);
        }
      }
    })();
  }, [isAddSuppliers]);

  return (
    <DataTable value={currentOffersList} className={styles.table} footerColumnGroup={footerGroup}>
      <Column columnKey='actions' header="Статус" style={{ width: 65 }} body={(e) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <i
            className={e?.accepted === null ? "pi pi-circle-off" : e?.accepted === false ? "pi pi-times" : "pi pi-check"}
            style={{ color: e?.accepted === null ? "grey" : e?.accepted === false ? "red" : "green" }}
          />
        </div>
      )} />
      <Column columnKey='actions' header="Дії" style={{ width: 95 }} body={(e) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button icon="pi pi-pencil" type="button"
            onClick={() => { setEditSupplierActive(true); setCurrentSupplier(e); setNewItemId(e.tenderItemId) }} disabled={userRole === 4}
            tooltip="Редагувати" tooltipOptions={{ position: 'top' }} />
          <Button className="p-button-danger" icon="pi pi-trash" type="button"
            onClick={() => removeOffer(e)} disabled={userRole === 4}
            tooltip="Видалити" tooltipOptions={{ position: 'top' }} />
        </div>
      )} />
      <Column field="name" header="Постачальник" style={{ width: 130 }} body={(e) => (e.supplier?.fullName ? e.supplier?.fullName : e.name)} />
      <Column field="item.category.name" header="Категорія" sortable style={{ width: '18%' }} />
      <Column field="item.name" header="Товар" sortable style={{ width: 160 }} />
      <Column field="supplierItem" header="Товар Постачальника" sortable style={{ width: 130 }} />
      <Column field="supplierComment" header="Коментар" sortable style={{ width: 120 }} />
      <Column field="amount" header="Кількість" sortable style={{ width: '10%' }} body={(e) => (e.supplierCount ? e.supplierCount : e.amount)} />
      <Column field="price" header="Ціна" sortable style={{ width: '10%' }} body={(e) => (e.supplierPrice ? e.supplierPrice : e.price)} />
      <Column field="sum" header="Сума" sortable style={{ width: '10%' }} />
    </DataTable>
  );
};

export default AddSuppliers;
