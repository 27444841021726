import React, {useState, useEffect, useRef} from 'react';
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import styles from "./ChatRoma.module.scss";
import axios from "axios";
import { BASE_URL } from "../../constants/serviceConstants";
import {getUserId} from '../../store/actions/loginActions';
import MessageBubble from "./MessageBubble/MessageBubble";
import {useDispatch, useSelector} from 'react-redux';
import {setChatVisibility} from '../../store/actions/chatActions';
import {getCurrentTenderId} from '../../store/actions/tendersActions';
import {FileUpload} from 'primereact/fileupload';

const ChatRoma = ({setRecordAudio, setRefresh, refresh}) => {
  const [inputText, setInputText] = useState(null);
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();
  const chatIsVisible = useSelector(state => state.chatReducer.isVisible);
  const uploader = useRef(null);
  const tenderId = getCurrentTenderId();
  const userId = getUserId();
  const socket = useSelector(state => state.tenderReducer.socket);
  const AUTH_TOKEN = 'auth-token';

  const uploadFile = async (tenderId, userId, file) => {
    await sendFile(tenderId, userId, file.files[0]);
    uploader.current.clear();
  };

  const sendPost = async (text, tender_id, user_id) => {
    if (socket) {
      socket.emit("chatMessage", {
        text,
        tender_id,
        user_id,
      });

      socket.on("chatMessage", (data) => {
        console.log(data, "<<<< RESP");
      });

      socket.on("error", (error) => {
        console.log(error);
      });
    }

    setTimeout(async () => {
      await readMessagesById(user_id, tender_id)
    }, 1000);
  };

  const readMessagesById = async (userId, tenderId) => {

    return await axios.patch(
        `${BASE_URL}/users/${userId}/messages/${tenderId}/read`,
        {},
        {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
    .then(({data}) => data)
    .catch(err => console.log('error', err))
  };

  const sendFile = async (tender_id, user_id, file) => {
    socket && socket.emit("chatMessage", {
      text: '',
      tender_id,
      user_id,
      file: file ? {
        originalname: file.name,
        size: file.size,
        buffer: await file.arrayBuffer(),
      } : {}
    });

    setTimeout(async () => {
      await readMessagesById(user_id, tender_id)
    }, 1000);
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/messages/history/${tenderId}?size=1000&page=0`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
    .then((res) => {
      setMessages(res.data.reverse());
    })
    .catch((err) => {
      console.log(err);
    });

  }, []);


  useEffect(() => {
    if (socket) {
      socket.emit("joinChat", {
        user_id: userId,
        tender_id: tenderId,
      });

      socket.on("chatMessage", (data) => {
        setMessages((messages) => [...messages, data]);
      });
    }

    return () => {
      socket && socket.emit("leaveChat", {
        user_id: userId,
        tender_id: tenderId,
      });
    }
  }, [userId, tenderId, socket]);

  return (
    <>
      <Sidebar
        className={styles.sideBar}
        visible={chatIsVisible}
        onHide={() => {}}
        position="right"
        dismissable={false}
        showCloseIcon={false}
        modal={false}
      >
        <div className={styles.adminInputsContainer}>
          <div className={styles.messageContainer}>
            {messages.map((item) => (
              item.success === false ? null : userId === item.user._id ?
                <MessageBubble user={true} item={item} key={item._id} /> : <MessageBubble item={item} key={item._id} />
            ))}
          </div>
        </div>
        <div className={styles.controlsContainer} id="control">
            <div>
              <InputText
                  className={styles.adminInput}
                  value={inputText}
                  placeholder="Введіть повідомлення..."
                  style={{width: '78%'}}
                  onChange={(e) => {
                    setInputText(e.target.value);
                  }}
                  onKeyPress={async event => {
                    if (event.key === 'Enter') {
                      await sendPost(inputText, tenderId, userId);
                      setInputText("");
                    }
                  }}
              />
            </div>
          <div style={{marginRight: 20, marginBottom: 10}}>
            <FileUpload
                mode="basic" name="demo[]" customUpload={true}
                uploadHandler={async (file) => await uploadFile(tenderId, userId, file)}
                auto={true} chooseLabel="" ref={uploader} maxFileSize="10000000"
            />
            <button
                  type="button"
                  onClick={() => {dispatch(setChatVisibility(false)); setRecordAudio(true)}}
                  style={{backgroundColor: 'transparent', border: 'none', outline: 'none', padding: 0}}
              >
                <img src={require("./../../assets/img/micro.png")}
                     alt="Голосове повідомлення"
                     style={{height: 24, width: 22}}
                />
              </button>
            </div>
          </div>
        <div
          className={styles.closeButton}
          onClick={() => {
            dispatch(setChatVisibility(false));
            setRefresh(!refresh);
          }}
        >
          <i className="pi pi-times" style={{ fontSize: 36, fontWeight: 300 }}/>
        </div>
      </Sidebar>
    </>
  );
};

export default ChatRoma;
