import React from 'react';
import { Field, Form, Formik } from 'formik';
import styles from '../EditSupplier/EditSupplier.module.scss';
import FormInput from '../../../../commons/FormInput';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { editItemById } from '../../../../services/TenderService';
import { useDispatch, useSelector } from 'react-redux';
import { editTenderItem } from '../../../../store/actions/tendersActions';

const EditItem = ({
  currentItem,
  refresh,
  setRefresh,
  setEditItemActive,
  tenderId,
}) => {
  const dispatch = useDispatch();
  const currentTender = useSelector(
    (state) => state.tenderReducer.currentTender
  );
  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Це поле є обов'язковим"),
    item: Yup.string().required("Це поле є обов'язковим"),
    amount: Yup.string()
      .matches(/^\d+$/, 'Кількість повинна бути числом')
      .required("Це поле є обов'язковим"),
    maxPrice: Yup.string()
      .matches(/^\d+$/, 'Ціна повинна бути числом')
      .required("Це поле є обов'язковим"),
  });

  return (
    <Formik
      initialValues={{
        category: currentItem?.item?.category?.name,
        item: currentItem?.item?.name,
        amount: currentItem?.amount,
        maxPrice: currentItem?.maxPrice,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(false);

        const editedItemsList = currentTender.items.map((item) => {
          if (item._id === currentItem?._id) {
            return {
              ...item,
              amount: values.amount,
              maxPrice: values.maxPrice,
            };
          }

          return item;
        });

        dispatch(editTenderItem(editedItemsList));
        // await editItemById(tenderId, currentItem?._id, values.amount, values.maxPrice);
        resetForm();
        setEditItemActive(false);
        // setRefresh(!refresh);
      }}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values, resetForm }) => {
        return (
          <Form>
            <div className={styles.mainInfoContainer}>
              <div className={styles.inputContainer}>
                <Field
                  name={`category`}
                  label='Категорія'
                  component={FormInput}
                  disabled
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`item`}
                  label='Товар'
                  component={FormInput}
                  disabled
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`amount`}
                  label='Кількість'
                  component={FormInput}
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <Field
                  name={`maxPrice`}
                  label='Максимальна ціна'
                  component={FormInput}
                  required
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  label='Відмінити'
                  type='button'
                  className='p-button-secondary'
                  onClick={() => {
                    setEditItemActive(false);
                    resetForm();
                  }}
                />
                <Button label='Зберегти' type='submit' />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditItem;
