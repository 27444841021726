import React, {useEffect, useState} from 'react';
import Categories from '../../components/Categories';
import {
  getAllCategoriesTree,
  removeCategory,
  removeProduct,
} from '../../services/CategoriesService';
import {popupDialog} from '../../commons/PopupDialog/PopupDialog';

const CategoriesPage = ({history}) => {
  const [refresh, setRefresh] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);

  const deleteCategory = (id, name) => {
    popupDialog(
        `Ви дійсно хочите видалити категорію ${name}?`,
        async () => {await removeCategory(id); setRefresh(!refresh)},
        'Видалити',
        () => {});
  };

  const deleteItem = (id, name) => {
    popupDialog(
        `Ви дійсно хочите видалити товар ${name}?`,
        async () => {await removeProduct(id); setRefresh(!refresh)},
        'Видалити',
        () => {});
  };

  const shortCategoryName = (name) => {
    if(name.length >= 18) {
      return `${name.slice(0, 18).trim()}...`;
    }

    return name;
  };

  const rowsCountData = [
    {label: 5, value: 5},
    {label: 10, value: 10},
    {label: 50, value: 50},
    {label: 100, value: 100},
  ];

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const categories = await getAllCategoriesTree() || [];
      setCategoriesList(categories);
      setPreloaderIsActive(false);
    })()
  }, [refresh]);

  return (
      <Categories
          preloaderIsActive={preloaderIsActive}
          setPreloaderIsActive={setPreloaderIsActive}
          shortCategoryName={shortCategoryName}
          deleteCategory={deleteCategory}
          deleteItem={deleteItem}
          categoriesList={categoriesList}
          rowsCountData={rowsCountData}
          setRefresh={setRefresh}
          refresh={refresh}
      />
  );
};

export default CategoriesPage;