import React, { useEffect, useState } from 'react';
import Users from '../../components/Users';
import { useDispatch, useSelector } from 'react-redux';
import { setAllUsers } from '../../store/actions/usersActions';
import { setCurrentUserId } from '../../store/actions/loginActions';
import { popupDialog } from '../../commons/PopupDialog/PopupDialog';
import {
  changeEnableType,
  getAllUsers,
  removeUser,
} from '../../services/AdminService';

const UsersPage = ({ history }) => {
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.usersReducer.allUsers);

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const allUsers = (await getAllUsers()) || [];
      dispatch(setAllUsers(allUsers));
      setPreloaderIsActive(false);
    })();
  }, [refresh]);

  const setUserStatus = (id, enabled, fullName) => {
    const statusText = enabled
      ? `Ви дійсно хочите заблокувати користувача ${fullName || ''}?`
      : `Ви дійсно хочите розблокувати користувача ${fullName || ''}?`;
    const buttonText = enabled ? 'Заблокувати' : 'Розблокувати';
    const setUser = async () => {
      await changeEnableType(id);
      const allUsers = (await getAllUsers()) || [];
      dispatch(setAllUsers(allUsers));
    };
    popupDialog(
      statusText,
      async () => await setUser(),
      buttonText,
      () => {}
    );
  };

  const setCurrentUserToStore = (id) => {
    setCurrentUserId(id);
    setEditUser(true);
  };

  const addNewUser = () => {
    setAddUser(true);
  };

  const removeOneUser = (id) => {
    const removeCurrentUser = async () => {
      await removeUser(id);
      const allUsers = await getAllUsers();
      dispatch(setAllUsers(allUsers));
    };
    popupDialog(
      'Ви дійсно хочите видалити користувача?',
      () => removeCurrentUser(),
      'Видалити',
      () => {}
    );
  };

  const rowsCountData = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  return (
    <Users
      users={users}
      refresh={refresh}
      setRefresh={setRefresh}
      addUser={addUser}
      setAddUser={setAddUser}
      editUser={editUser}
      preloaderIsActive={preloaderIsActive}
      setEditUser={setEditUser}
      rowsCountData={rowsCountData}
      setCurrentUserToStore={setCurrentUserToStore}
      addNewUser={addNewUser}
      removeOneUser={removeOneUser}
      setUserStatus={setUserStatus}
    />
  );
};

export default UsersPage;
