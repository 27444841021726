import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import styles from './EditSupplier.module.scss';
import FormInput from '../../../../commons/FormInput';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { editTenderOffer } from '../../../../store/actions/tendersActions';

const EditSupplier = ({
  currentSupplier, setEditSupplierActive,
  newItemId, suppliersList
}) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    category_suppliers: Yup
      .string()
      .required("Це поле є обов'язковим"),
    item_suppliers: Yup
      .string()
      .required("Це поле є обов'язковим"),
    amount: Yup
      .string()
      .matches(/^\d+$/, "Кількість повинна бути числом")
      .required("Це поле є обов'язковим"),
    price: Yup
      .string()
      .matches(/^\d+$/, "Ціна повинна бути числом")
      .required("Це поле є обов'язковим"),
    tender_name: Yup
      .string()
      .required("Це поле є обов'язковим"),
    supplierItem: Yup
      .string()
      .required("Це поле є обов'язковим"),
    supplierComment: Yup
      .string(),
    supplier: Yup
      .string()
  });

  const preparedSuppliersList = suppliersList.map(supplier => ({ label: supplier?.fullName, value: supplier?.id }))

  const handleChangeSupplier = ({ value }, setFieldValue) => {
    setFieldValue('supplier', value || '');

    const supplierFullName = preparedSuppliersList.find((supplier) => supplier.value === value);
    setFieldValue('tender_name', supplierFullName?.label || '');
  }

  return (
    <Formik
      initialValues={{
        tender_name: currentSupplier?.supplier?.fullName || currentSupplier?.name || '',
        category_suppliers: currentSupplier?.item?.category?.name || '',
        item_suppliers: currentSupplier?.item?.name || '',
        price: currentSupplier?.price || '',
        amount: currentSupplier?.amount || '',
        measureUnit: currentSupplier?.item.measureUnit || '',
        supplierItem: currentSupplier?.supplierItem || '',
        supplierComment: currentSupplier?.supplierComment || '',
        supplier: currentSupplier?.supplier?.id || '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(false);
        const selectedSupplierObject = suppliersList.find((supplier) => supplier.id === values.supplier)

        const editedOffer = {
          supplier: {
            id: values.supplier,
          },
          name: selectedSupplierObject?.fullName || values.tender_name,
          amount: values.amount,
          supplierPrice: +values.price,
          price: +values.price,
          sum: String(values.amount * values.price),
          updated_at: new Date().toISOString(),
          supplierItem: values.supplierItem,
          supplierComment: values.supplierComment,
          supplier: selectedSupplierObject || null,
        }
        
        dispatch(editTenderOffer(editedOffer, newItemId, currentSupplier?._id));
        resetForm();
        setEditSupplierActive(false);
      }}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values, resetForm, setFieldValue }) => {
        console.log(values);
        return (
          <Form>
            <div className={styles.mainInfoContainer}>
              <div className={styles.dropdownContainer}>
                <label>
                  Выберите зарегистрированного поставщика
                </label>
                <Dropdown
                  value={values.supplier}
                  style={{ width: 250 }}
                  options={preparedSuppliersList}
                  showClear
                  onChange={(e) => handleChangeSupplier(e, setFieldValue)}
                />
              </div>
              <div className={styles.inputContainer}>
                <Field name={`tender_name`}
                  label="Наименование поставщика"
                  component={FormInput}
                  required
                  disabled={values.supplier}
                />
              </div>
              <div className={styles.inputContainer}>
                <Field name={`category_suppliers`}
                  label="Категорія"
                  component={FormInput}
                  disabled
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <Field name={`item_suppliers`}
                  label="Товар"
                  component={FormInput}
                  disabled
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <Field name={`supplierItem`}
                  label="Товар Постачальника"
                  component={FormInput}
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <Field name={`supplierComment`}
                  label="Коментар"
                  component={FormInput}
                />
              </div>
              <div className={styles.inputContainer}>
                <Field name={`amount`}
                  label="Кількість"
                  component={FormInput}
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <Field name={`price`}
                  label="Ціна"
                  component={FormInput}
                  required
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button label="Відмінити"
                  className="p-button-secondary"
                  onClick={() => { setEditSupplierActive(false); resetForm() }} />
                <Button label="Зберегти" type="submit" />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
};

export default EditSupplier;