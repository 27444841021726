// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MessageBubble_rightAudio__2lXDk audio::-webkit-media-controls-panel {\n  background-color: #ceeaf7; }\n\n.MessageBubble_rightAudio__2lXDk audio {\n  margin-left: -11px; }\n\n.MessageBubble_leftAudio__1IwDM audio::-webkit-media-controls-panel {\n  background-color: #f4f4f4; }\n\n.MessageBubble_leftAudio__1IwDM audio {\n  margin-left: -10px; }\n\n.MessageBubble_fileContainer__1f-zS {\n  display: flex;\n  height: 40px;\n  width: 250px;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: flex-start; }\n\n.MessageBubble_img__1Vn2B {\n  height: 300px;\n  width: 300px;\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n  margin: 0 auto; }\n", ""]);
// Exports
exports.locals = {
	"rightAudio": "MessageBubble_rightAudio__2lXDk",
	"leftAudio": "MessageBubble_leftAudio__1IwDM",
	"fileContainer": "MessageBubble_fileContainer__1f-zS",
	"img": "MessageBubble_img__1Vn2B"
};
module.exports = exports;
