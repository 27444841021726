import axios from 'axios';
import { BASE_URL } from '../constants/serviceConstants';
import { popupDialog } from '../commons/PopupDialog/PopupDialog';
const AUTH_USER_ID = 'auth-user-id';

export const AUTH_TOKEN = 'auth-token';

export const getTendersForSupplier = async () => {
  return await axios.get(
    `${BASE_URL}/tenders/items/for-supplier`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const getAcceptedTendersForSupplier = async () => {
  return await axios.get(
    `${BASE_URL}/tenders/items/for-supplier/accepted`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const addTenderOffer = async ({ offerItem, offerAmount, offerPrice, offerDescription }, tenderId, tenderItemId) => {
  const data = {
    tender: { _id: tenderId },
    supplier: { id: localStorage.getItem(AUTH_USER_ID) },
    tender_item: { _id: tenderItemId },
    supplierComment: offerDescription,
    amount: offerAmount,
    supplierPrice: offerPrice,
    supplierItem: offerItem,
    price: offerPrice
  };

  const formData = new FormData();
  formData.append('json', JSON.stringify(data));

  return await axios({
    method: 'post',
    url: `${BASE_URL}/offers`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
  })
    .then((data) => { return data })
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'));
};

export const editTenderOffer = async ({ offerItem, offerAmount, offerPrice, offerDescription }, offerId, tenderId, tenderItemId) => {
  const data = {
    supplierComment: offerDescription,
    amount: offerAmount,
    supplierPrice: offerPrice,
    supplierItem: offerItem,
    price: offerPrice
  };

  return await axios({
    method: 'patch',
    url: `${BASE_URL}/offers/${offerId}`,
    data,
    headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
  })
    .then((data) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'));
};

export const setShipmentStatus = async (data) => {
  return await axios({
    method: 'patch',
    url: `${BASE_URL}/offers/update-status/sent`,
    data,
    headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
  })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};
