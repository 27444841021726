import {
  SET_ALL_NOTIFICATIONS,
  SET_NOTIFICATION_IS_VISIBLE,
  SET_UNREAD_NOTIFICATIONS,
} from '../constants/actionTypes';

const initialState = {
  isVisible: false,
  unreadNotifications: 0,
  allNotification: [],
};

const offersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_IS_VISIBLE:
      return {...state, isVisible: action.payload};

    case SET_UNREAD_NOTIFICATIONS:
      return {...state, unreadNotifications: action.payload};

    case SET_ALL_NOTIFICATIONS:
      return {...state, allNotification: action.payload};

    default:
      return state;

  }
};

export default offersReducer;