import axios from 'axios';
import {BASE_URL} from '../constants/serviceConstants';
import {popupDialog} from '../commons/PopupDialog/PopupDialog';
const AUTH_TOKEN = 'auth-token';

export const getAllCategories = async () => {
  return await axios.get(
      `${BASE_URL}/categories`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(err => console.log('error', err))
};

export const getRootCategories = async () => {
  return await axios.get(
      `${BASE_URL}/categories/root`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(err => console.log('error', err))
};

export const getItemsFromCategory = async (id) => {
  return await axios.get(
      `${BASE_URL}/categories/${id}/recursive`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(err => console.log('error', err))
};

export const getAllCategoriesTree = async () => {
  return await axios.get(
      `${BASE_URL}/categories/tree`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(err => console.log('error', err))
};

export const removeCategory = async (id) => {
  return await axios.delete(
      `${BASE_URL}/categories/${id}`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const removeProduct = async (id) => {
  return await axios.delete(
      `${BASE_URL}/items/${id}`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const addNewCategory = async (name) => {
  const data = {
    name,
    childCategoriesId: [],
    parentCategoryId: ""
  };

  return await axios.post(
      `${BASE_URL}/categories`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const addNewCategoryToParent = async (name, parentCategoryId) => {
  const data = {
    name,
    childCategoriesId: [],
    parentCategoryId,
  };

  return await axios.post(
      `${BASE_URL}/categories`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const addNewGoods = async (values, category_id, description, note) => {
  const {newGoods, measureUnit} = values;
  const data = {
    category_id,
    name: newGoods,
    measureUnit,
    note,
    description,
    data: {},
  };

  return await axios.post(
      `${BASE_URL}/items`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const addGoodsArray = async dataArray =>{
  return await axios.post(
      `${BASE_URL}/items/many`,
      dataArray,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
}

export const editProduct = async (values, id, description, note) => {
  const {newGoods, measureUnit} = values;
  const data = {
    name: newGoods,
    measureUnit,
    description,
    note,
    data: {},
  };

  return await axios.patch(
      `${BASE_URL}/items/${id}`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const editCategory = async (name, id) => {
  const data = {
    name,
  };

  return await axios.patch(
      `${BASE_URL}/categories/${id}`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const getCategory = async id =>{
  return await axios.get(
      `${BASE_URL}/categories/${id}`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(err => console.log('error', err))
};

