// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Preloader_preloaderContainer__3LvZz {\n  position: fixed;\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.1);\n  z-index: 100;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n", ""]);
// Exports
exports.locals = {
	"preloaderContainer": "Preloader_preloaderContainer__3LvZz"
};
module.exports = exports;
