import React from 'react';
import TenderCardEdit from '../../components/Home/TenderCardEdit';
import {useSelector} from 'react-redux';

const TenderDetailsPage = ({history}) => {
  const statusChecker = status => {

    switch (status) {
      case 0:
        return "Новий. Очікує обробки";
      case 3:
        return "Вибір постачальника";
      case 1:
        return "Вимагає підтвердження";
      case 4:
        return "Документи";
      case 5:
        return "Доставка очікується";
      case 2:
        return "Підтверджений";
      case 6:
        return "Доставка успішна";
      default:
        break;
    }
  };

  const colorByStatus = status => {
    switch (status) {
      case "Новий. Очікує обробки":
        return '#0A9BCF';
      case "Вимагає підтвердження":
      case "Підтверджений":
      case "Вибір постачальника":
        return '#219653';
      case "Документи":
      case "Доставка очікується":
        return '#E51A4B';
      case "Доставка успішна":
        return '#828282';
      default:
        break;
    }
  };

  const getName = (name) => {
    if (name.length > 10) {
      return name.slice(0, 10);
    }

    return name;
  };
  return (
      <TenderCardEdit
          statusChecker={statusChecker}
          colorByStatus={colorByStatus}
          getName={getName}
          history={history}
      />
  );
};

export default TenderDetailsPage;
