import {
  SET_ALL_CLIENTS,
  SET_CLIENTS_CURRENT_PAGE, SET_CLIENTS_ROWS_IN_TABLE,
  SET_CURRENT_CLIENT,
} from '../constants/actionTypes';

const initialState = {
  allClients: [],
  currentClient: {},
  currentPage: 0,
  rowsInTable: 10,
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_CLIENTS:
      return {...state,
        allClients: action.payload.map(item => ({
          key: item.company._id,
          data: {
            id: item.id,
            fullName: item.fullName,
            email: item.email,
            company: item.company.name,
            status: item.authentication?.enabled ? 'blocked' : 'active',
            phone: item.phone,
            additional_phone: item.additional_phone,
            created_at: item.created_at.split('-').reverse().join('-'),
            role: 'Owner'
          },
          children: item.company.users.map(user => ({
            data: {
              id: user.id,
              fullName: user.fullName,
              email: user.email,
              status: user.authentication?.enabled ? 'blocked' : 'active',
              company: item.company.name,
              role: user.role.role === 0 ? 'User' : 'UserFull',
              phone: user.phone,
              additional_phone: user.additional_phone,
              created_at: user.created_at.split('-').reverse().join('-'),
            },
          }))
        }))
      };
    case SET_CURRENT_CLIENT:
      return {...state, currentClient: action.payload};
    case SET_CLIENTS_CURRENT_PAGE:
      return {...state, currentPage: action.payload};
    case SET_CLIENTS_ROWS_IN_TABLE:
      return {...state, rowsInTable: action.payload};
    default:
      return state;

  }
};

export default clientsReducer;
