// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AudioRecord_audioContainer__12hdo {\n  position: fixed;\n  z-index: 100;\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.3);\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.AudioRecord_recordControls__3XqvA {\n  height: 350px;\n  width: 500px;\n  background-color: #fff; }\n  .AudioRecord_recordControls__3XqvA canvas {\n    width: 125px;\n    height: 53px; }\n\n.AudioRecord_reactMicContainer__1n-nI {\n  position: absolute;\n  top: 52px;\n  left: 245px;\n  z-index: 101; }\n\n.AudioRecord_recordContainer__3m_pI {\n  padding: 0 10px;\n  height: 54px;\n  width: 300px;\n  background-color: #f1f3f4;\n  border-radius: 40px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.AudioRecord_stopPlayButton__LQJKd {\n  height: 33px;\n  width: 33px;\n  border-radius: 50%;\n  background-color: #f1f3f4;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .AudioRecord_stopPlayButton__LQJKd span:hover {\n    cursor: pointer; }\n\n.AudioRecord_timer__3zclj {\n  padding: 0 35px 0 15px;\n  font-size: 13.5px; }\n\n.AudioRecord_playerContainer__3uE7N {\n  position: relative;\n  padding: 20px 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .AudioRecord_playerContainer__3uE7N label {\n    font-weight: bold;\n    margin-bottom: 10px; }\n\n.AudioRecord_buttonsContainer__2J_VU {\n  width: 100%;\n  padding: 25px 60px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  flex-wrap: nowrap; }\n", ""]);
// Exports
exports.locals = {
	"audioContainer": "AudioRecord_audioContainer__12hdo",
	"recordControls": "AudioRecord_recordControls__3XqvA",
	"reactMicContainer": "AudioRecord_reactMicContainer__1n-nI",
	"recordContainer": "AudioRecord_recordContainer__3m_pI",
	"stopPlayButton": "AudioRecord_stopPlayButton__LQJKd",
	"timer": "AudioRecord_timer__3zclj",
	"playerContainer": "AudioRecord_playerContainer__3uE7N",
	"buttonsContainer": "AudioRecord_buttonsContainer__2J_VU"
};
module.exports = exports;
