// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EditSupplier_mainInfoContainer__3TRbA {\n  margin: 0 auto;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .EditSupplier_mainInfoContainer__3TRbA input {\n    width: 250px; }\n\n.EditSupplier_inputContainer__2q0yo {\n  display: flex;\n  justify-content: center; }\n\n.EditSupplier_buttonContainer__zHzYU {\n  display: flex;\n  margin-top: 30px;\n  width: 200px;\n  justify-content: space-between; }\n\n.EditSupplier_dropdownContainer__1H4vW {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n  margin-bottom: 10px; }\n  .EditSupplier_dropdownContainer__1H4vW label {\n    margin: 0;\n    font-weight: bold; }\n", ""]);
// Exports
exports.locals = {
	"mainInfoContainer": "EditSupplier_mainInfoContainer__3TRbA",
	"inputContainer": "EditSupplier_inputContainer__2q0yo",
	"buttonContainer": "EditSupplier_buttonContainer__zHzYU",
	"dropdownContainer": "EditSupplier_dropdownContainer__1H4vW"
};
module.exports = exports;
