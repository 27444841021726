// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserCard_container__3Bcse {\n  height: calc(100vh - 80px);\n  margin: 0 auto;\n  width: 70%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.UserCard_mainContainer__VYNGH {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.UserCard_formContainer__S2Moq {\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .UserCard_formContainer__S2Moq input {\n    width: 250px; }\n\n.UserCard_buttonContainer__2MP8y {\n  padding: 10px;\n  margin-top: 20px; }\n  .UserCard_buttonContainer__2MP8y button:first-child {\n    margin-right: 55px; }\n\n.UserCard_dropdownContainer__2QPu5 {\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  margin-bottom: 10px; }\n  .UserCard_dropdownContainer__2QPu5 label {\n    font-weight: bold; }\n", ""]);
// Exports
exports.locals = {
	"container": "UserCard_container__3Bcse",
	"mainContainer": "UserCard_mainContainer__VYNGH",
	"formContainer": "UserCard_formContainer__S2Moq",
	"buttonContainer": "UserCard_buttonContainer__2MP8y",
	"dropdownContainer": "UserCard_dropdownContainer__2QPu5"
};
module.exports = exports;
