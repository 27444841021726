import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import styles from './EditOrdersRegistry.module.scss';
import { Field, Form, Formik } from 'formik';
import { popupDialog } from '../../../../commons/PopupDialog/PopupDialog';
import FormInput from '../../../../commons/FormInput';
import { Button } from 'primereact/button';
import {
  addTenderOffer,
  editTenderOffer,
} from '../../../../services/SupplierService';

import { useTranslation } from 'react-i18next';

const EditOrdersRegistry = ({
  refresh,
  setRefresh,
  setEditingOrdersRegistry,
  selectedTender,
}) => {
  const { t, i18n } = useTranslation();
  const createValidationSchema = Yup.object().shape({
    offerItem: Yup.string().required("Це поле є обов'язковим"),
    offerAmount: Yup.number()
      .typeError('Повинно бути числом')
      .integer('Повинно бути цілим')
      // moreThan(0, 'Повинно бути більше 0').
      .required("Це поле є обов'язковим"),
    offerPrice: Yup.number()
      .typeError('Повинно бути числом')
      //moreThan(0, 'Повинно бути більше 0').
      .required("Це поле є обов'язковим"),
    offerDescription: Yup.string().nullable(true),
    // .required("Це поле є обов'язковим"),
  });

  const firstInputRef = useRef();

  useEffect(() => {
    if (firstInputRef.current?.element) {
      setTimeout(() => firstInputRef.current.element.focus(), 200);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        offerItem: selectedTender?.supplierItem || '',
        offerAmount: selectedTender?.offer_amount || '',
        offerPrice: selectedTender?.supplierPrice || '',
        offerDescription: selectedTender?.supplierComment || '',
      }}
      validationSchema={createValidationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const { offer_id, item_id, tender_item_id, tender_item_min_price } =
          selectedTender;
        console.log();
        if (
          tender_item_min_price &&
          values.offerPrice > tender_item_min_price &&
          !values.offerDescription
        ) {
          setFieldError(
            'offerDescription',
            'Ціна більше мінімальної, додайте коментар'
          );
          return;
        }
        setSubmitting(false);
        const isSuccess = selectedTender?.offer_id
          ? editTenderOffer(values, offer_id, item_id, tender_item_id)
          : addTenderOffer(values, item_id, tender_item_id);
        isSuccess
          .then((res) => {
            setEditingOrdersRegistry(false);
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            popupDialog('Помилка. Повторіть спробу', () => {}, 'Ок');
          });
      }}
      validateOnChange={true}
      // enableReinitialize={true}
    >
      {() => (
        <Form className={styles.formContainer}>
          <h3 className={styles.formHeader}>{selectedTender?.item_name}</h3>

          <p className={styles.formSubHeader}>{`${t('suppliers.needed')}: ${
            selectedTender?.tender_item_amount
          }${selectedTender?.item_measure_unit || 'шт.'}`}</p>
          <Field
            name={`offerItem`}
            label={t('suppliers.product')}
            keyfilter={'offerItem'}
            inputRef={firstInputRef}
            tabIndex={0}
            component={FormInput}
          />
          <Field
            name={`offerAmount`}
            label={t('suppliers.count')}
            keyfilter={'offerAmount'}
            component={FormInput}
          />
          <Field
            name={`offerPrice`}
            label={t('suppliers.price')}
            keyfilter={'offerPrice'}
            component={FormInput}
          />
          <Field
            name={`offerDescription`}
            label={t('suppliers.comment')}
            keyfilter={'offerDescription'}
            component={FormInput}
          />
          <div className={styles.buttonContainer}>
            <Button type='submit' label={t('suppliers.saveChanges')} />
            <Button
              label={t('suppliers.discardChanges')}
              className='p-button-secondary'
              type='button'
              onClick={() => setEditingOrdersRegistry(false)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditOrdersRegistry;
