// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EditOrdersRegistry_container__2lHd- {\n  height: calc(100vh - 80px);\n  margin: 0 auto;\n  width: 70%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.EditOrdersRegistry_mainContainer__3u1-M {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.EditOrdersRegistry_formContainer__2l5lv {\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .EditOrdersRegistry_formContainer__2l5lv input {\n    width: 250px; }\n\n.EditOrdersRegistry_formHeader__1HsHy {\n  margin: 0; }\n\n.EditOrdersRegistry_formSubHeader__2LBuK {\n  margin-top: 0;\n  font-weight: bold; }\n\n.EditOrdersRegistry_buttonContainer__1lfyh {\n  padding: 10px;\n  margin-top: 20px; }\n  .EditOrdersRegistry_buttonContainer__1lfyh button:first-child {\n    margin-right: 55px; }\n\n.EditOrdersRegistry_dropdownContainer__3YMzF {\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  margin-bottom: 10px; }\n  .EditOrdersRegistry_dropdownContainer__3YMzF label {\n    font-weight: bold; }\n", ""]);
// Exports
exports.locals = {
	"container": "EditOrdersRegistry_container__2lHd-",
	"mainContainer": "EditOrdersRegistry_mainContainer__3u1-M",
	"formContainer": "EditOrdersRegistry_formContainer__2l5lv",
	"formHeader": "EditOrdersRegistry_formHeader__1HsHy",
	"formSubHeader": "EditOrdersRegistry_formSubHeader__2LBuK",
	"buttonContainer": "EditOrdersRegistry_buttonContainer__1lfyh",
	"dropdownContainer": "EditOrdersRegistry_dropdownContainer__3YMzF"
};
module.exports = exports;
