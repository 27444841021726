import axios from 'axios';
import { BASE_URL } from '../constants/serviceConstants';
import { popupDialog } from '../commons/PopupDialog/PopupDialog';
const AUTH_TOKEN = 'auth-token';

export const getUserData = async (id) => {
  return await axios.get(
    `${BASE_URL}/users/${id}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => { return data })
    .catch(err => console.log('error', err))
};

export const editProfile = async (values) => {
  const { id, additional_phone, phone, fullName, role } = values;
  const splitName = (fullName || '').split(' ');
  const data = {
    role: { role: role.role },
    phone,
    additional_phone,
    fullName,
    name: splitName[0],
    surname: splitName[1],
    lastName: splitName[2],
  };

  return await axios.patch(
    `${BASE_URL}/users/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data.success)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const setTwoFA = async (id, twoFA) => {
  return await axios.patch(
    `${BASE_URL}/users/${id}`,
    { twoFA },
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data.success)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const getUserById = async (id) => {
  return await axios.get(
    `${BASE_URL}/users/${id}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const getAllUserInfoById = async (id) => {
  return await axios.get(
    `${BASE_URL}/users/${id}/profile`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const getUserNotificationById = async (id) => {
  return await axios.get(
    `${BASE_URL}/users/${id}/notifications`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const setNotificationIsRead = async (userId, notificationId) => {
  return await axios.patch(`${BASE_URL}/users/${userId}/notifications/${notificationId}`,
    {},
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};
