import React, {useState} from 'react';
import styles from './TenderInfo.module.scss';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import routes from '../../../constants/routes';
import {
  setCurrentPageMyTenders,
  setCurrentTenderIdToStorage, setPrevPage, setSort,
} from '../../../store/actions/tendersActions';
import {Dropdown} from 'primereact/dropdown';
import {setChatVisibility} from '../../../store/actions/chatActions';

const TenderInfo = () => {
  const myTenders = useSelector(state => state.tenderReducer.myTenders);
  const sortBy = useSelector(state => state.tenderReducer.sortType);
  const page = useSelector(state => state.tenderReducer.currentMyTendersPage);
  const [rowCount, setRowCount] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const statusChecker = status => {
    switch (status) {
      case 0:
        return "Новий. Очікує обробки";
      case 3:
        return "Вибір постачальника";
      case 1:
        return "Вимагає підтвердження";
      case 4:
        return "Документи";
      case 5:
        return "Доставка очікується";
      case 2:
        return "Підтверджений";
      case 6:
        return "Доставка успішна";
      default:
        break;
    }
  };

  const rowsCountData = [
    {label: 5, value: 5},
    {label: 10, value: 10},
    {label: 50, value: 50},
    {label: 100, value: 100},
  ];

  const getFullDate = (fullDate) => {
    const date = fullDate.match(/\d+-\d+-\d+/)[0].split('-').reverse().join('-');
    const time = new Date(fullDate).toString().match(/\d+:\d+/)[0];

    return `${date} ${time}`
  };

  const sortData = [
    {label: 'датою створення', value: 'created'},
    {label: 'датою оновлення', value: 'updated'},
  ];

  const tendersWithStatus = myTenders.map(item => (
      {...item,
        created_at: getFullDate(item.created_at),
        updated_at: getFullDate(item.updated_at),
        status_code: statusChecker(item.status_code),
        status: item.status_code,
      }));

  return (
      <div className={styles.contentContainer}>
        <div className={styles.dropdownContainer}>
          <div>
            <span>Сортувати за:</span>
            <Dropdown
                value={sortBy}
                options={sortData}
                onChange={e => dispatch(setSort(e.value))}
            />
          </div>
          <div>
            <span>Кількість рядків у таблиці:</span>
            <Dropdown
                value={rowCount}
                options={rowsCountData}
                onChange={e => setRowCount(e.value)}
            />
          </div>
        </div>
        <DataTable paginator value={tendersWithStatus} rows={rowCount} first={page} onPage={e => dispatch(setCurrentPageMyTenders(e.first))}>
          <Column field="internal_id" header="ID" style={{width: 80}} sortable filter bodyStyle={{textAlign: 'center'}}/>
          <Column columnKey='actions' header="Дії" style={{width: 100}} body={(e) => (
              <div className={styles.tableButtonContainer}>
                <Button
                    icon="pi pi-file"
                    tooltip="Відкрити заявку"
                    tooltipOptions={{position: 'top'}}
                    onClick={() => {
                      dispatch(setPrevPage(0));
                      dispatch(setChatVisibility(false));
                      setCurrentTenderIdToStorage(e?._id);
                      history.push(routes.TENDER_DETAILS_PAGE);
                    }}
                />
              </div>)}/>
          <Column field="name" header="Назва заявки" style={{width: '28%'}} sortable filter filterMatchMode="contains"/>
          <Column field="company.name" header="Компанія" style={{width: '28%'}} sortable filter filterMatchMode="contains"/>
          <Column field="status_code" header="Статус" style={{width: '22%'}} filter sortable sortField="status"/>
          <Column
              field={sortBy === 'created' ? 'created_at' : 'updated_at'}
              header={sortBy === 'created' ? 'Дата створення' : 'Дата оновлення'}
              style={{width: '22%'}} sortable bodyStyle={{textAlign: 'center'}}/>
        </DataTable>
      </div>
  );
};

export default TenderInfo;
