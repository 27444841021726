import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../containers/Home/HomePage';
import LoginPage from '../containers/Login/LoginPage';
import RegistrationPage from '../containers/Registration/RegistrationPage';
import routes from '../constants/routes';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import ClientsPage from '../containers/Clients/ClientsPage';
import UsersPage from '../containers/Users/UsersPage';
import ProfilePage from '../containers/Profile/ProfilePage';
import RestorePasswordPage from '../containers/Login/RestorePasswordPage';
import PublicRoute from './PublicRoute/PublicRoute';
import RestorePasswordWithEmailPage
  from '../containers/Login/RestorePasswordWithEmailPage';
import CategoriesPage from '../containers/Categories/CategoriesPage';
import FreeTendersPage from '../containers/Home/FreeTendersPage';
import AllTendersPage from '../containers/Home/AllTendersPage';
import TenderCardEditPage from '../containers/Home/TenderCardEditPage';
import TenderDetailsPage from '../containers/Home/TenderDetailsPage';
import ClientHistoryPage from '../containers/Clients/ClientHistoryPage';
import ShipmentRegistryPage from '../containers/Suppliers/ShipmentRegistryPage';

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.HOME_PAGE} exact component={HomePage} />
      <PrivateRoute path={routes.TENDER_DETAILS_PAGE} exact component={TenderCardEditPage} />
      <PrivateRoute path={routes.HISTORY_CLIENT} exact component={ClientHistoryPage} />
      <PrivateRoute path={routes.TENDER_PAGE} exact component={TenderDetailsPage} />
      <PrivateRoute path={routes.TENDERS_PAGE} exact component={AllTendersPage} />
      <PrivateRoute path={routes.FREE_TENDERS_PAGE} exact component={FreeTendersPage} />
      <PrivateRoute path={routes.CATEGORIES_PAGE} exact component={CategoriesPage} />
      <PrivateRoute path={routes.CLIENTS_PAGE} exact component={ClientsPage} />
      <PrivateRoute path={routes.USERS_PAGE} exact component={UsersPage} />
      <PrivateRoute path={routes.PROFILE_PAGE} exact component={ProfilePage} />
      <PrivateRoute path={routes.SHIPMENT_REGISTRY_PAGE} exact component={ShipmentRegistryPage} />
      <PublicRoute path={routes.LOGIN_PAGE} exact component={LoginPage} />
      <PublicRoute path={routes.RESTORE_PASSWORD_PAGE} exact component={RestorePasswordWithEmailPage} />
      <Route path={routes.REGISTRATION_PAGE} exact component={RegistrationPage} />
      <Route path={`${routes.RESTORE_PASSWORD_PAGE}/:temporaryToken`} exact component={RestorePasswordPage} />
    </Switch>
  )
};
