// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormInput_container__1wTIE {\n  position: relative;\n  margin-bottom: 10px; }\n  .FormInput_container__1wTIE .FormInput_formLabel__1cbmu {\n    width: 100%;\n    font-weight: 700;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: flex-start;\n    flex-wrap: nowrap; }\n\n.FormInput_formError__YS6f7 {\n  position: absolute;\n  top: 62px;\n  font-size: 12px;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  white-space: nowrap;\n  color: #E51A4B; }\n", ""]);
// Exports
exports.locals = {
	"container": "FormInput_container__1wTIE",
	"formLabel": "FormInput_formLabel__1cbmu",
	"formError": "FormInput_formError__YS6f7"
};
module.exports = exports;
