export const AUTH_TOKEN = 'auth-token';
export const AUTH_USER_ID = 'auth-user-id';
export const AUTH_TEMPORARY_TOKEN = 'auth-temporary-token';
export const AUTH_USER_ROLE = 'auth-user-role';
export const AUTH_CURRENT_USER_ID = 'auth-current-user-id';

export const loggedIn = () => {
  return !!localStorage.getItem(AUTH_TOKEN);
};

export const setToken = token => {
  return localStorage.setItem(AUTH_TOKEN, token)
};

export const setUserId = id => {
  return localStorage.setItem(AUTH_USER_ID, id)
};

export const setUserRole = role => {
  return localStorage.setItem(AUTH_USER_ROLE, role)
};

export const getUserRole = () => {
  return localStorage.getItem(AUTH_USER_ROLE)
};

export const setCurrentUserId = role => {
  return localStorage.setItem(AUTH_CURRENT_USER_ID, role)
};

export const getCurrentUserId = () => {
  return localStorage.getItem(AUTH_CURRENT_USER_ID)
};

export const getUserId = () => {
  return localStorage.getItem(AUTH_USER_ID)
};

export const getTemporaryToken = () => {
  return localStorage.getItem(AUTH_TEMPORARY_TOKEN)
};

export const setTemporaryToken = token => {
  return localStorage.setItem(AUTH_TEMPORARY_TOKEN, token)
};


