import {
  SET_ALL_OFFERS,
  SET_CURRENT_OFFER,
  SET_CURRENT_OFFER_ID,
} from '../constants/actionTypes';

const initialState = {
  currentOffer: {},
  allOffers: [],
  currentOfferId: '',
};

const offersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_OFFER:
      return {...state, currentOffer: action.payload};
    case SET_ALL_OFFERS:
      return {...state, allOffers: action.payload};
    case SET_CURRENT_OFFER_ID:
      return {...state, currentOfferId: action.payload};
    default:
      return state;

  }
};

export default offersReducer;
