import React, { useEffect } from 'react';
import RestorePassword from '../../components/Login/RestorePassword';
import routes from '../../constants/routes';
import { useParams } from 'react-router-dom';
// import { setTemporaryToken } from '../../store/actions/loginActions';

const RestorePasswordPage = ({ history }) => {
  const { temporaryToken } = useParams();

  const backToLoginPage = () => {
    history.push(routes.LOGIN_PAGE);
  };

  const toHomePage = () => {
    history.push(routes.HOME_PAGE);
  };

  // useEffect(() => {
  //   setTemporaryToken(temporaryToken);
  // }, []);

  return (
    <RestorePassword
      temporaryToken={temporaryToken}
      backToLoginPage={backToLoginPage}
      toHomePage={toHomePage}
    />
  );
};

export default RestorePasswordPage;
