import {
  SET_ALL_CLIENTS,
  SET_CLIENTS_CURRENT_PAGE, SET_CLIENTS_ROWS_IN_TABLE,
  SET_CURRENT_CLIENT,
} from '../constants/actionTypes';


const setAllClients = (clients) => ({type: SET_ALL_CLIENTS, payload: clients});
export const setCurrentClient = (user) => ({type: SET_CURRENT_CLIENT, payload: user});

export const setClientCurrentPage = (page) => ({type: SET_CLIENTS_CURRENT_PAGE, payload: page});
export const setClientRowsInTable = (count) => ({type: SET_CLIENTS_ROWS_IN_TABLE, payload: count});

export const getAllClients = (clients) => (dispatch) => {
  dispatch(setAllClients(clients));
};

export const setClientId = (client) => localStorage.setItem('client-id', client);
export const getClientId = () => localStorage.getItem('client-id');
