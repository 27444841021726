import React, { useEffect, useState } from 'react';
import styles from './ClientCard.module.scss';
import { Field, Form, Formik } from 'formik';
import FormInput from '../../../commons/FormInput';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { editClient } from '../../../services/ClientService';
import { popupDialog } from '../../../commons/PopupDialog/PopupDialog';
import { getUserData } from '../../../services/UserService';
import { getClientId } from '../../../store/actions/clientsActions';
import { getUserRole } from '../../../store/actions/loginActions';
import * as Yup from 'yup';

const ClientCard = ({ setEditClient, refresh, setRefresh }) => {
  const userRole = Number(getUserRole() || 4);
  const clientId = getClientId();
  const [client, setClient] = useState({});
  const [role, setRole] = useState('');

  const validationSchema = Yup.object().shape({
    email: Yup
      .string()
      .email('Некоректна електронна пошта')
      .required("Це поле є обов'язковим"),
    fullName: Yup
      .string()
      .required("Це поле є обов'язковим"),
    phone: Yup
      .string('Некоректний номер')
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
      .nullable(),
    additional_phone: Yup
      .string('Некоректний номер')
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Некоректний номер')
      .nullable(),
  });

  useEffect(() => {
    if (clientId) {
      (async () => {
        const client = await getUserData(clientId);
        setClient(client);
        setRole(client?.role?.role);
      })()
    }
  }, []);

  return (
    <Formik
      initialValues={client}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        const isSuccess = await editClient(values, role);
        isSuccess && setEditClient(false); setRefresh(!refresh);
        !isSuccess && popupDialog('Помилка. Повторіть спробу', () => { }, 'Ok');
      }}
      validateOnChange={false}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {() => (
        <Form className={styles.formContainer}>
          <Field name={`email`}
            label="Email"
            keyfilter={'email'}
            component={FormInput}
            disabled
            required
          />
          {client?.role?.role !== 2 ? (
            <div className={styles.dropdownContainer}>
              <label style={{ fontSize: 16, fontWeight: 'bold' }}>
                Роль
                <span style={{ fontSize: 12, color: '#E51A4B' }}>*</span>
              </label>
              <Dropdown
                value={role}
                style={{ width: 250 }}
                options={[
                  { label: 'User', value: 0 },
                  { label: 'UserFull', value: 1 },
                ]}
                onChange={(e) => setRole(e.value)}
                disabled={userRole === 4}
              />
            </div>
          ) : (
            <Field name={`role.role`}
              label="Роль"
              component={FormInput}
              value={'Owner'}
              required
              disabled
            />
          )
          }
          <Field name={`fullName`}
            label="ПІБ"
            component={FormInput}
            required
            disabled={userRole === 4}
          />
          <Field name={`phone`}
            label={`Телефон`}
            component={FormInput}
            disabled={userRole === 4}
          />
          <Field name={`additional_phone`}
            label={`Додатковий номер`}
            component={FormInput}
            disabled={userRole === 4}
          />
          <div className={styles.buttonContainer}>
            {userRole !== 4 && <Button type="submit" label="Зберегти" />}
            <Button label="Відмінити" type="button" className="p-button-secondary"
              onClick={() => setEditClient(false)} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ClientCard;
