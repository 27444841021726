import React, { useEffect, useState } from 'react';
import AllTenders from '../../components/Home/AllTenders/AllTenders';
import { getAllTenders } from '../../services/TenderService';
import { setAllTendersData } from '../../store/actions/tendersActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../services/AdminService';

const AllTendersPage = () => {
  const [refresh, setRefresh] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);
  const dispatch = useDispatch();
  const filterType = useSelector(
    (state) => state.tenderReducer.sortTypeAllTenders
  );

  const statusChecker = (status) => {
    switch (status) {
      case 0:
        return 'Новий. Очікує обробки';
      case 3:
        return 'Вибір постачальника';
      case 1:
        return 'Вимагає підтвердження';
      case 4:
        return 'Документи';
      case 5:
        return 'Доставка очікується';
      case 2:
        return 'Підтверджений';
      case 6:
        return 'Доставка успішна';
      default:
        break;
    }
  };

  const rowsCountData = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  const getFullDate = (fullDate) => {
    const date = fullDate
      .match(/\d+-\d+-\d+/)[0]
      .split('-')
      .reverse()
      .join('-');
    const time = new Date(fullDate).toString().match(/\d+:\d+/)[0];

    return `${date} ${time}`;
  };

  const sortData = [
    { label: 'датою створення', value: 'created' },
    { label: 'датою оновлення', value: 'updated' },
  ];

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const allTenders = (await getAllTenders(filterType)) || [];
      const allUsers = (await getAllUsers()) || [];
      setPreloaderIsActive(false);
      setAllUsers(allUsers);
      dispatch(setAllTendersData(allTenders));
    })();
  }, [filterType, refresh]);

  return (
    <AllTenders
      sortData={sortData}
      preloaderIsActive={preloaderIsActive}
      getFullDate={getFullDate}
      rowsCountData={rowsCountData}
      allUsers={allUsers}
      statusChecker={statusChecker}
      refreshPage={refreshPage}
    />
  );
};

export default AllTendersPage;
