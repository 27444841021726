import React, { useState } from 'react';
import Header from '../../Header';
import styles from './AllTenders.module.scss';
import SidebarPage from '../../../containers/Sidebar/SidebarPage';
import { Dropdown } from 'primereact/dropdown';
import {
  setCurrentPageAllTenders,
  setCurrentTenderData,
  setCurrentTenderIdToStorage,
  setPrevPage,
  setSortAllTenders,
} from '../../../store/actions/tendersActions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';
import { getUserRole } from '../../../store/actions/loginActions';
import Notifications from '../../Header/Notifications/Notifications';
import { setNotificationsIsVisible } from '../../../store/actions/notificationsActions';
import SettingsPopup from '../../../commons/SettingsPopup/SettingsPopup';
import ReassignManager from './ReassignManager/ReassignManager';
import { setChatVisibility } from '../../../store/actions/chatActions';
import Preloader from '../../../commons/Preloader/Preloader';
import { getRoles } from '@testing-library/react';
import { BASE_URL } from '../../../constants/serviceConstants';

const AllTenders = ({
  statusChecker,
  refreshPage,
  allUsers,
  getFullDate,
  sortData,
  rowsCountData,
  preloaderIsActive,
}) => {
  const [isNewManager, setIsNewManager] = useState(false);
  const allTenders = useSelector((state) => state.tenderReducer.allTenders);
  const currentTender = useSelector(
    (state) => state.tenderReducer.currentTender
  );
  const sortBy = useSelector((state) => state.tenderReducer.sortTypeAllTenders);
  const userRole = Number(getUserRole() || 4);
  const page = useSelector(
    (state) => state.tenderReducer.currentAllTendersPage
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [rowCount, setRowCount] = useState(10);
  const allManagers = allUsers
    .filter(
      (item) =>
        item.role?.role === 6 || item.role?.role === 5 || item.role?.role === 4
    )
    .map((item) => ({ label: item?.fullName, value: item?.id }))
    .filter((item) => item.value !== currentTender?.manager?.id);

  const tableData = allTenders?.map((item) => ({
    ...item,
    // created_at: getFullDate(item.created_at),
    created_at: new Date(item.created_at),
    // updated_at: getFullDate(item.updated_at),
    updated_at: new Date(item.updated_at),
    status_code: statusChecker(item.status_code),
    status: item.status_code,
  }));

  return (
    <div style={{ position: 'relative' }}>
      <Preloader active={preloaderIsActive} />
      <Header />
      <div
        className={styles.mainContainer}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      >
        <Notifications />
        <SidebarPage />
        <SettingsPopup
          visible={isNewManager}
          onHide={() => setIsNewManager(false)}
          headerText='Переназначення заявки на іншого менеджера'
        >
          <ReassignManager
            allManagers={allManagers}
            currentTender={currentTender}
            refreshPage={refreshPage}
            setIsNewManager={setIsNewManager}
          />
        </SettingsPopup>
        <div className={styles.contentContainer}>
          <div className={styles.dropdownContainer}>
            <div>
              <span>Сортувати за:</span>
              <Dropdown
                value={sortBy}
                options={sortData}
                onChange={(e) => dispatch(setSortAllTenders(e.value))}
              />
            </div>
            <div>
              <span>Кількість рядків у таблиці:</span>
              <Dropdown
                value={rowCount}
                options={rowsCountData}
                onChange={(e) => setRowCount(e.value)}
              />
            </div>
          </div>
          <DataTable
            paginator
            value={tableData}
            rows={rowCount}
            first={page}
            onPage={(e) => dispatch(setCurrentPageAllTenders(e.first))}
          >
            <Column
              field='internal_id'
              header='ID'
              style={{ width: 80 }}
              sortable
              filter
              bodyStyle={{ textAlign: 'center' }}
            />
            <Column
              columnKey='actions'
              header='Дії'
              style={{ width: 100 }}
              body={(e) => (
                <div className={styles.tableButtonContainer}>
                  {userRole === 6 && (
                    <Button
                      icon='pi pi-user-plus'
                      tooltip='Переназначити менеджера'
                      tooltipOptions={{ position: 'top' }}
                      onClick={() => {
                        dispatch(setCurrentTenderData(e));
                        setIsNewManager(true);
                      }}
                    />
                  )}
                  <Button
                    icon='pi pi-file'
                    tooltip='Відкрити заявку'
                    tooltipOptions={{ position: 'top' }}
                    onClick={() => {
                      setCurrentTenderIdToStorage(e?._id);
                      dispatch(setChatVisibility(false));
                      dispatch(setPrevPage(1));
                      history.push(routes.TENDER_PAGE);
                    }}
                  />
                  {/* {(() => {
                    if (e.files.length > 0 || e.hasOwnProperty('audio')) {
                      return <i className='pi pi-paperclip'></i>;
                    }
                  })()} */}
                </div>
              )}
            />
            <Column
              field='name'
              header='Назва заявки'
              style={{ width: '18%' }}
              sortable
              filter
              filterMatchMode='contains'
            />
            <Column
              field='company.name'
              header='Компанія'
              style={{ width: '18%' }}
              sortable
              filter
              filterMatchMode='contains'
            />
            {/* <Column
              field='attachments'
              header='Прикріплені файли'
              style={{ width: '18%' }}
              body={(e) => (
                <div className={styles.files}>
                  {e?.files?.map((file, index) => {
                    const fileName = `${file.filename}.${file.extension}`;
                    return (
                      <div
                        style={{ position: 'relative', width: 100 }}
                        key={index}
                      >
                        <i
                          className='pi pi-times'
                          style={{
                            position: 'absolute',
                            fontSize: 12,
                            zIndex: 1,
                            color: '#e51a4b',
                            top: 7,
                            right: 20,
                          }}
                        />
                        <a
                          className={styles.fileWrapper}
                          target='_blank'
                          href={`${BASE_URL}${file.path}`}
                        >
                          <i
                            className='pi pi-file-pdf'
                            style={{ fontSize: 40 }}
                          />
                          <span>{fileName}</span>
                        </a>
                      </div>
                    );
                  })}
                  {e?.audio && (
                    <a
                      className={styles.fileWrapper}
                      target='_blank'
                      href={`${BASE_URL}${e.audio?.path}`}
                    >
                      <i className='pi pi-volume-up' style={{ fontSize: 40 }} />
                    </a>
                  )}
                </div>
              )}
            /> */}

            <Column
              field='manager.fullName'
              header='Менеджер'
              style={{ width: '18%' }}
              sortable
              filter
              filterMatchMode='contains'
            />
            <Column
              field='status_code'
              header='Статус'
              style={{ width: '14%' }}
              filter
              sortable
              sortField='status'
              filterMatchMode='contains'
            />
            <Column
              field={sortBy === 'created' ? 'created_at' : 'updated_at'}
              header={
                sortBy === 'created' ? 'Дата створення' : 'Дата оновлення'
              }
              body={(rowData) =>
                sortBy === 'created'
                  ? rowData?.created_at?.toLocaleString()
                  : rowData?.updated_at?.toLocaleString()
              }
              body={(rowData) =>
                sortBy === 'created'
                  ? rowData?.created_at?.toLocaleString()
                  : rowData?.updated_at?.toLocaleString()
              }
              style={{ width: '14%' }}
              sortable
              bodyStyle={{ textAlign: 'center' }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default AllTenders;
