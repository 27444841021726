import React from 'react';
import { Calendar } from 'primereact/calendar';
import styles from './FormCalendar.module.scss';
import { setTenderMainInfo } from '../../store/actions/tendersActions';
import { useDispatch } from 'react-redux';

const FormCalendar = ({ field, form, saveToRedux, minDate, maxDate, ...props }) => {
  const dispatch = useDispatch();

  const onFieldBlur = (e) => {
    if (saveToRedux) {
      dispatch(setTenderMainInfo(String(e.value), field.name));
    }

    field.onChange(e);
  }

  return (
    <div className={styles.container}>
      <div className={styles.formLabel}>{props.label}: </div>
      <Calendar
        dateFormat="dd/mm/yy"
        {...field}
        {...props}
        onChange={onFieldBlur}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};
export default FormCalendar;