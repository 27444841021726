import React from 'react';
import { InputText } from 'primereact/inputtext';
import styles from './FormInput.module.scss';
import { getIn } from 'formik';
import { setTenderMainInfo } from '../../store/actions/tendersActions';
import { useDispatch } from 'react-redux';

const FormInput = ({ field, form, meta, defaultValue, saveToRedux, ...props }) => {
  const dispatch = useDispatch();
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  const onFieldBlur = (e) => {
    if (saveToRedux) {
      dispatch(setTenderMainInfo(field.value, field.name));
    }

    field.onBlur(e);
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.container}>
        <div className={styles.formLabel}>
          {props.label}
          {props.required && <div style={{ fontSize: 12, color: '#E51A4B' }}>*</div>}
        </div>
        <InputText {...field} {...props}
          onBlur={onFieldBlur}
          style={{ borderColor: touch && error ? '#E51A4B' : '#a6a6a6' }} />
      </div>
      <div className={styles.formError}>{touch && error ? error : ''}</div>
    </div>
  );
};

export default FormInput;