import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { loggedIn } from '../../store/actions/loginActions'
import routes from '../../constants/routes';

const PrivateRoute = ({component: Component, ...rest}) => {
  return (
      <Route {...rest} render={props => (
          loggedIn() ?
              <Component {...props} />
              : <Redirect to={routes.LOGIN_PAGE} />
      )} />
  );
};

export default PrivateRoute;
