import React, {useState} from 'react';
import styles from './Clients.module.scss';
import Header from '../Header';
import {Column} from 'primereact/column';
import {TreeTable} from 'primereact/treetable';
import {Button} from 'primereact/button';
import SidebarPage from '../../containers/Sidebar/SidebarPage';
import {getUserRole} from '../../store/actions/loginActions';
import {Dropdown} from 'primereact/dropdown';
import {useDispatch, useSelector} from 'react-redux';
import {
  setClientCurrentPage,
  setClientRowsInTable,
} from '../../store/actions/clientsActions';
import {setNotificationsIsVisible} from '../../store/actions/notificationsActions';
import Notifications from '../Header/Notifications/Notifications';
import SettingsPopup from '../../commons/SettingsPopup/SettingsPopup';
import OwnerCard from './OwnerCard/OwnerCard';
import ClientCard from './ClientCard';
import RestoreOwner from './RestoreOwner';
import ChangeOwnerStatus from './ChangeOwnerStatus';
import AddClient from './AddClient';
import Preloader from '../../commons/Preloader/Preloader';

const Clients = ({clients, setClientStatus, removeClient, preloaderIsActive,
                   addCurrentClientToStore, addSubUser, rowsCountData,
                   restoreOwner, changeOwnerStatus, goToHistoryPage,
                   editOwner, setEditOwner, editClient, setEditClient,
                   setRefresh, refresh, setRemoveOwner, removeOwner,
                   setChangeStatus, changeStatus, addClient, setAddClient}) => {
  const page = useSelector(state => state.clientsReducer.currentPage);
  const rowsInTable = useSelector(state => state.clientsReducer.rowsInTable);
  const dispatch = useDispatch();
  const userRole = Number(getUserRole() || 4);

  return (
      <div style={{position: 'relative'}}>
        <Preloader active={preloaderIsActive}/>
        <Header/>
        <div
            className={styles.mainContainer}
            onClick={() => dispatch(setNotificationsIsVisible(false))}
        >
          <SidebarPage/>
          <Notifications/>
          <SettingsPopup
              visible={editOwner}
              onHide={() => setEditOwner(false)}
              headerText="Редагування власника"
          >
            <OwnerCard
              setEditOwner={setEditOwner}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </SettingsPopup>
          <SettingsPopup
              visible={editClient}
              onHide={() => setEditClient(false)}
              headerText="Редагування працівника"
          >
            <ClientCard
                refresh={refresh}
                setRefresh={setRefresh}
                setEditClient={setEditClient}
            />
          </SettingsPopup>
          <SettingsPopup
              visible={removeOwner}
              onHide={() => setRemoveOwner(false)}
              headerText="Видалення власника"
          >
            <RestoreOwner
                refresh={refresh}
                setRefresh={setRefresh}
                setRemoveOwner={setRemoveOwner}
            />
          </SettingsPopup>
          <SettingsPopup
              visible={changeStatus}
              onHide={() => setChangeStatus(false)}
              headerText="Зміна статуса власника"
          >
            <ChangeOwnerStatus
                refresh={refresh}
                setRefresh={setRefresh}
                setChangeStatus={setChangeStatus}
            />
          </SettingsPopup>
          <SettingsPopup
              visible={addClient}
              onHide={() => setAddClient(false)}
              headerText="Додавання нового працівника"
          >
            <AddClient
                refresh={refresh}
                setRefresh={setRefresh}
                setAddClient={setAddClient}
            />
          </SettingsPopup>
          <div className={styles.container}>
            <div className={styles.dropdownContainer}>
              <div><b>Список клієнтів</b></div>
              <div>
                <span>Кількість рядків у таблиці:</span>
                <Dropdown
                    value={rowsInTable}
                    options={rowsCountData}
                    onChange={e => dispatch(setClientRowsInTable(e.value))}
                />
              </div>
            </div>
            <div className={styles.tableContainer}>
              <TreeTable responsive={true} value={clients} paginator rows={rowsInTable} first={page} onPage={e => dispatch(setClientCurrentPage(e.first))}>
                <Column field="fullName" expander header="ПІБ" style={{width: '20%'}} sortable filter filterMatchMode="contains"/>
                <Column field="company" header="Компанія" style={{width: 150}} sortable filter filterMatchMode="contains"/>
                <Column field="email" header="Email" style={{width: '20%'}} sortable filter filterMatchMode="contains"/>
                <Column field="role" header="Роль" style={{width: 86}}
                        body={(e) => {
                          return e.data?.role === 'Owner' ?
                              <span className={styles.ownerStyle}>{e.data?.role || ''}</span> :
                              <span className={styles.clientStyle}>{e.data?.role || ''}</span>;
                        }}/>
                <Column field="status" header="Статус" style={{width: 83}}
                        body={(e) => {
                          return e.data?.status === 'active' ?
                              <span className={styles.statusBlocked}>inactive</span> :
                              <span className={styles.statusActive}>active</span>;
                        }}/>
                <Column columnKey='actions' header="Дії" style={{width: 205}} body={(e) => (
                    <div className={styles.tableButtonContainer}>
                      <Button icon="pi pi-pencil"
                              tooltip="Редагувати"
                              tooltipOptions={{position: 'top'}}
                              onClick={() => {addCurrentClientToStore(e)}}/>
                      {userRole !== 4 &&
                        (<>
                              <Button
                                  className={e.data?.status !== 'blocked' ? "p-button-success" : "p-button-warning"}
                                  icon="pi pi-ban"
                                  tooltip={e.data?.status === 'blocked' ? "Заблокувати" : "Розблокувати"}
                                  tooltipOptions={{position: 'top'}}
                                  onClick={() => {e.key
                                      ? (e.children?.length === 0 || e.data?.status === 'active') ? setClientStatus(e.data.id, e.data?.status) : changeOwnerStatus(e?.data?.id)
                                      : setClientStatus(e.data.id, e.data?.status)}
                                  }
                              />
                              <Button className="p-button-danger" icon="pi pi-trash"
                                      tooltip={e.key
                                          ? e.children?.length === 0 ? "Видалити компанію" : "Видалити власника з переназначенням на іншого"
                                          : "Видалити працівника"}
                                      tooltipOptions={{position: 'top'}}
                                      onClick={() => {e.key
                                          ? e.children?.length === 0 ? removeClient(e.data?.id, e.data?.fullName) : restoreOwner(e?.data?.id)
                                          : removeClient(e.data?.id)}
                                      }
                              />
                              {e.key && <Button
                                  icon="pi pi-plus"
                                  tooltip="Додати працівника"
                                  tooltipOptions={{position: 'top'}}
                                  onClick={() => addSubUser(e)}
                                  disabled={(e.children?.length > 199) || (userRole === 4)}
                              />}
                        </>)
                      }
                      <Button className="p-button-secondary" icon="pi pi-info"
                              tooltip="Історія змін"
                              tooltipOptions={{position: 'top'}}
                              onClick={() => goToHistoryPage(e)}
                      />
                    </div>)}/>
                <Column field="created_at" header="Дата створення" style={{width: 100}} sortable/>
              </TreeTable>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Clients;
