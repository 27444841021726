export const SET_CURRENT_TENDER = 'SET_CURRENT_TENDER';
export const SET_CURRENT_SUPPLIERS = 'SET_CURRENT_SUPPLIERS';
export const SET_ALL_TENDERS = 'SET_ALL_TENDERS';
export const SET_MY_TENDERS = 'SET_MY_TENDERS';
export const SET_CURRENT_TENDER_ID = 'SET_CURRENT_TENDER_ID';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const SET_SORT_TYPE_ALL_TENDERS = 'SET_SORT_TYPE_ALL_TENDERS';
export const SET_SORT_TYPE_FREE_TENDERS = 'SET_SORT_TYPE_FREE_TENDERS';
export const SET_FREE_TENDERS = 'SET_FREE_TENDERS';

export const SET_ALL_OFFERS = 'SET_ALL_OFFERS';
export const SET_CURRENT_OFFER_ID = 'SET_CURRENT_OFFER_ID';
export const SET_CURRENT_OFFER = 'SET_CURRENT_OFFER';

export const SET_NOTIFICATION_IS_VISIBLE = 'SET_NOTIFICATION_IS_VISIBLE';
export const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS';
export const SET_ALL_NOTIFICATIONS = 'SET_ALL_NOTIFICATIONS';

export const SET_ALL_TENDERS_CURRENT_PAGE = 'SET_ALL_TENDERS_CURRENT_PAGE';
export const SET_MY_TENDERS_CURRENT_PAGE = 'SET_MY_TENDERS_CURRENT_PAGE';
export const SET_FREE_TENDERS_CURRENT_PAGE = 'SET_FREE_TENDERS_CURRENT_PAGE';

export const SET_PREV_PAGE = 'SET_PREV_PAGE';

export const SET_CHAT_VISIBILITY = 'SET_CHAT_VISIBILITY';
export const LOAD_CHAT_MESSAGES = 'LOAD_CHAT_MESSAGES';

export const SET_CURRENT_USER = 'SET_CURRENT_EMPLOYEE';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_ALL_USERS = 'SET_ALL_STAFF';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const SET_AUDIO_ID = 'SET_AUDIO_ID';
export const SET_USER_ROLE = 'SET_USER_ROLE';

export const SET_ALL_CLIENTS = 'SET_ALL_CLIENTS';
export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT';

export const SET_CLIENTS_CURRENT_PAGE = 'SET_CLIENTS_CURRENT_PAGE';
export const SET_CLIENTS_ROWS_IN_TABLE = 'SET_CLIENTS_ROWS_IN_TABLE';

export const SET_CATEGORIES_CURRENT_PAGE = 'SET_CATEGORIES_CURRENT_PAGE';
export const SET_CATEGORIES_ROWS_IN_TABLE = 'SET_CATEGORIES_ROWS_IN_TABLE';

export const SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE';
export const SET_USERS_ROWS_IN_TABLE = 'SET_USERS_ROWS_IN_TABLE';

export const SET_CHAT_NOTIFICATIONS = 'SET_CHAT_NOTIFICATIONS';

export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';


export const SET_SOCKET = 'SET_SOCKET';

export const ADD_TENDER_ITEM = 'ADD_TENDER_ITEM';
export const EDIT_TENDER_ITEM = 'EDIT_TENDER_ITEM';
export const DELETE_TENDER_ITEM = 'DELETE_TENDER_ITEM';
export const SET_TENDER_OFFERS = 'SET_TENDER_OFFERS';
export const ADD_TENDER_OFFER = 'ADD_TENDER_OFFER';
export const EDIT_TENDER_OFFER = 'EDIT_TENDER_OFFER';
export const DELETE_TENDER_OFFER = 'DELETE_TENDER_OFFER';
export const ADD_TENDER_CONTRACTS = 'ADD_TENDER_CONTRACTS';
export const DELETE_TENDER_FILE = 'DELETE_TENDER_FILE';
export const DELETE_TENDER_CONTRACT = 'DELETE_TENDER_CONTRACT';
export const CHANGE_TENDER_STATUS = 'CHANGE_TENDER_STATUS';
export const SET_CURRENT_TENDER_COPY = 'SET_CURRENT_TENDER_COPY';
export const SET_TENDERS_FOR_SUPPLIER = 'SET_TENDERS_FOR_SUPPLIER';
export const SET_ACCEPTED_TENDERS_FOR_SUPPLIER = 'SET_ACCEPTED_TENDERS_FOR_SUPPLIER';
export const SET_ALL_USERS_DATA = 'SET_ALL_USERS_DATA';
export const SET_TENDER_MAIN_INFO = 'SET_TENDER_MAIN_INFO';
export const SET_ALL_SUPPLIERS = 'SET_ALL_SUPPLIERS';
export const SET_SENT_FOR_SUPPLIER = 'SET_SENT_FOR_SUPPLIER';
export const SET_DISABLED_ACCEPTED_TENDERS_FOR_SUPPLIER = 'SET_DISABLED_ACCEPTED_TENDERS_FOR_SUPPLIER';
