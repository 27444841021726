import axios from 'axios';
import { BASE_URL } from '../constants/serviceConstants';
import { popupDialog } from '../commons/PopupDialog/PopupDialog';
const AUTH_TOKEN = 'auth-token';

export const getAllTenders = async (filterType) => {
  return await axios.get(
    `${BASE_URL}/tenders?filter=${filterType}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const getAllTendersForCurrentUser = async (id, filterType) => {
  return await axios.get(
    `${BASE_URL}/tenders/manager/${id}?filter=${filterType}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const getFreeTenders = async (filterType) => {
  return await axios.get(
    `${BASE_URL}/tenders/available?filter=${filterType}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const getOffersByTenderId = async (id) => {
  return await axios.get(
    `${BASE_URL}/tenders/${id}/offers`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const changeStatusById = async (id, status) => {
  return await axios.patch(`${BASE_URL}/tenders/${id}/status/${status}`,
    {},
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data.success)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const removeOfferById = async (id) => {
  return await axios.delete(
    `${BASE_URL}/offers/${id}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const updateTender = async (id, data) => {
  return await axios.patch(
    `${BASE_URL}/tenders/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => { })
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const createOffer = async (tender_id, item_id, name, amount, price) => {
  const data = {
    currency: "грн",
    description: "",
    name,
    amount,
    price,
    tender_id,
    item_id
  };

  const formData = new FormData();
  formData.append('json', JSON.stringify(data));

  return await axios({
    method: 'post',
    url: `${BASE_URL}/offers`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
  })
    .then((data) => { console.log(data.status); return data.status })
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'));
};

export const editOfferById = async (id, values) => {
  const { tender_name, price, amount } = values;
  const body = { name: tender_name, price, amount };

  return await axios.patch(
    `${BASE_URL}/offers/${id}`,
    body,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const editItemById = async (tenderId, itemId, amount, maxPrice) => {
  return await axios.patch(
    `${BASE_URL}/tenders/${tenderId}/items/${itemId}`,
    { amount, maxPrice },
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const addItemToTender = async (item_id, tenderId, amount, maxPrice) => {
  const body = { items: [{ amount, item_id, maxPrice }] };

  return await axios.patch(
    `${BASE_URL}/tenders/${tenderId}/items`,
    body,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const getTenderById = async (id) => {
  return await axios.get(
    `${BASE_URL}/tenders/${id}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(err => console.log('error', err))
};

export const removeFileById = async (id, tenderId) => {
  return await axios.delete(
    `${BASE_URL}/files/${id}`,
    // `${BASE_URL}/files/${id}/${tenderId}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const removeItem = async (tenderId, itemId) => {
  return await axios.delete(
    `${BASE_URL}/tenders/${tenderId}/items/${itemId}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data.success)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};

export const addManagerToTender = async (tenderId, userId) => {
  return await axios.patch(`${BASE_URL}/tenders/${tenderId}/manager/${userId}`,
    {},
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data.success)
    .catch(err => err.success)
};

export const attachContractsToTender = async (id, contracts) => {
  const formData = new FormData();

  contracts.forEach((item) => {
    formData.append('file', item);
  });

  return await axios({
    method: 'patch',
    url: `${BASE_URL}/tenders/${id}/contracts/attach`,
    data: formData,
    headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
  })
    .then((response) => response.data.contracts)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'));
};

export const getItemById = async (id) => {
  return await axios.get(
    `${BASE_URL}/items/${id}`,
    { headers: { "Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}` } })
    .then(({ data }) => data)
    .catch(({ response }) => popupDialog(`${response.data.message}`, () => { }, 'Ок'))
};
