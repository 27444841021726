import React, {useState} from 'react';
import {addNewGoods} from '../../../services/CategoriesService';
import {Field, Form, Formik} from 'formik';
import styles from '../Categories.module.scss';
import FormInput from '../../../commons/FormInput';
import {Button} from 'primereact/button';
import * as Yup from 'yup';

const AddItem = ({setRefresh, refresh, setNewItem, currentCategory}) => {
  const [descriptionValue, setDescriptionValue] = useState('');
  const [noteValue, setNoteValue] = useState('');

  const goodsValidationSchema = Yup.object().shape({
    newGoods: Yup
    .string()
    .required("Це поля є обов'язковим"),
    measureUnit: Yup
    .string()
    .required("Це поля є обов'язковим"),
  });

  return (
      <Formik
          initialValues={{
            newGoods: '',
            measureUnit: '',
          }}
          validationSchema={goodsValidationSchema}
          onSubmit={async (values, {setSubmitting}) => {
            setSubmitting(false);
            const isSuccess = await addNewGoods(values, currentCategory?.key, descriptionValue, noteValue);
            isSuccess && setNewItem(false);
            setRefresh(!refresh);
          }}
          validateOnChange={false}
      >
        {({values}) => (
            <Form className={styles.container}>
                <div className={styles.categoryContainer}>
                  <span><b>Категорія:</b></span>
                  <span>{currentCategory?.data?.name || ''}</span>
                </div>
                <Field name={`newGoods`}
                       label="Товар"
                       component={FormInput}
                       required
                />
                <Field name={`measureUnit`}
                       label="Одиниця вимірювання"
                       component={FormInput}
                       required
                />
                <div className={styles.textareaContainer}>
                  <label>Опис</label>
                  <textarea
                      style={{width: 250, height: 100}}
                      value={descriptionValue}
                      onChange={e => setDescriptionValue(e.target.value)}
                  />
                </div>
                <div className={styles.textareaContainer}>
                  <label>Примітка</label>
                  <textarea
                      value={noteValue}
                      onChange={e => setNoteValue(e.target.value)}
                      style={{width: 250, height: 100}}
                  />
                </div>
              <div className={styles.submitButtonContainer}>
                <Button type="submit" label="Додати"/>
                <Button label="Відмінити" type="button" className="p-button-secondary"
                        onClick={() => setNewItem(false)}/>
              </div>
            </Form>
        )}
      </Formik>
  );
};

export default AddItem;