// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TenderInfo_mainContainer__lIkzm {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.TenderInfo_contentContainer__2Yp5C {\n  width: 100%;\n  height: 100%;\n  background-color: #ecf0f5;\n  padding: 25px 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.TenderInfo_tableButtonContainer__1hQVv {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .TenderInfo_tableButtonContainer__1hQVv button {\n    margin-right: 5px !important; }\n    .TenderInfo_tableButtonContainer__1hQVv button:last-child {\n      margin-right: 0 !important; }\n\n.TenderInfo_dropdownContainer__2a7rk {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .TenderInfo_dropdownContainer__2a7rk span {\n    margin-right: 20px; }\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "TenderInfo_mainContainer__lIkzm",
	"contentContainer": "TenderInfo_contentContainer__2Yp5C",
	"tableButtonContainer": "TenderInfo_tableButtonContainer__1hQVv",
	"dropdownContainer": "TenderInfo_dropdownContainer__2a7rk"
};
module.exports = exports;
