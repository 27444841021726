// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Login_container__2wXPP {\n  margin: 4rem auto;\n  width: 30%;\n  min-height: 400px;\n  background-color: #fff;\n  border: 1px solid #c8c8c8;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.Login_formContainer__mHwUs {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.Login_resendText__2HGHY {\n  color: #000;\n  font-size: 12px;\n  margin-left: 100px;\n  text-decoration: underline; }\n  .Login_resendText__2HGHY:hover {\n    cursor: pointer; }\n\n.Login_buttonContainer__2QRmm {\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .Login_buttonContainer__2QRmm button:first-child {\n    margin-bottom: 5px; }\n", ""]);
// Exports
exports.locals = {
	"container": "Login_container__2wXPP",
	"formContainer": "Login_formContainer__mHwUs",
	"resendText": "Login_resendText__2HGHY",
	"buttonContainer": "Login_buttonContainer__2QRmm"
};
module.exports = exports;
