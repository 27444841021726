// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddClient_formContainer__3TzN2 {\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .AddClient_formContainer__3TzN2 input {\n    width: 250px; }\n\n.AddClient_buttonContainer__2yHG1 {\n  padding: 20px 10px; }\n  .AddClient_buttonContainer__2yHG1 button:first-child {\n    margin-right: 55px; }\n\n.AddClient_dropdownContainer__2cMaj {\n  display: flex;\n  margin-bottom: 10px;\n  flex-direction: column; }\n  .AddClient_dropdownContainer__2cMaj label {\n    font-weight: bold; }\n\n.AddClient_mainContainer__3otIH {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n", ""]);
// Exports
exports.locals = {
	"formContainer": "AddClient_formContainer__3TzN2",
	"buttonContainer": "AddClient_buttonContainer__2yHG1",
	"dropdownContainer": "AddClient_dropdownContainer__2cMaj",
	"mainContainer": "AddClient_mainContainer__3otIH"
};
module.exports = exports;
