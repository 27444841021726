import React from 'react';
import Header from '../Header';
import * as Yup from 'yup';
import styles from './Profile.module.scss';
import { Field, Form, Formik } from 'formik';
import FormInput from '../../commons/FormInput';
import { Button } from 'primereact/button';
import { editProfile, setTwoFA } from '../../services/UserService';
import { popupDialog } from '../../commons/PopupDialog/PopupDialog';
import SidebarPage from '../../containers/Sidebar/SidebarPage';
import { InputSwitch } from 'primereact/inputswitch';
import { useDispatch } from 'react-redux';
import { setNotificationsIsVisible } from '../../store/actions/notificationsActions';
import Notifications from '../Header/Notifications/Notifications';

const Profile = ({
  editable,
  makeEditableForm,
  cancelEditableForm,
  userData,
  goBack,
  setRefresh,
  refresh,
}) => {
  const userRole =
    userData?.role?.role === 4
      ? 'Reader'
      : userData?.role?.role === 5
      ? 'Manager'
      : userData?.role?.role === 3
      ? 'Supplier'
      : 'Admin';
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Це поле є обов'язковим"),
    fullName: Yup.string().nullable(),
    phone: Yup.string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        'Некоректний номер'
      )
      .nullable(),
    additionalPhone: Yup.string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        'Некоректний номер'
      )
      .nullable(),
  });

  const handleTwoFA = (userData) => {
    popupDialog(
      `Ви дійсно хочете ${
        !userData?.twoFA ? 'включити' : 'відключити'
      } двофакторну аутентифікацію?`,
      async () => {
        await setTwoFA(userData.id, !userData.twoFA);
        setRefresh(!refresh);
      },
      'Так',
      () => {},
      'Ні'
    );
  };

  return (
    <div>
      <Header />
      <div
        className={styles.mainContainer}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      >
        <SidebarPage />
        <Notifications />
        <div className={styles.container}>
          <Formik
            initialValues={userData}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false);
              const isSuccess = await editProfile(values);
              isSuccess &&
                popupDialog('Дані успішно збережені', () => goBack(), 'Ok');
            }}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {({ handleReset }) => (
              <Form className={styles.formContainer}>
                <b>Профіль</b>
                <Field
                  name={`fullName`}
                  label='ПІБ'
                  component={FormInput}
                  disabled={!editable}
                  required={editable}
                />
                <Field
                  name={`role`}
                  label='Роль'
                  component={FormInput}
                  disabled
                  value={userRole}
                  required={editable}
                />
                <Field
                  name={`email`}
                  label='Email'
                  keyfilter={'email'}
                  component={FormInput}
                  disabled
                  required={editable}
                />
                <Field
                  name={`phone`}
                  label='Телефон'
                  keyfilter='pint'
                  component={FormInput}
                  disabled={!editable}
                />
                <Field
                  name={`additional_phone`}
                  label='Додатковий телефон'
                  keyfilter='pint'
                  component={FormInput}
                  disabled={!editable}
                />
                <div className={styles.twoFAContainer}>
                  <span>Двофакторна аутентифікація</span>
                  <InputSwitch
                    checked={userData?.twoFA}
                    onChange={() => handleTwoFA(userData)}
                    disabled={!editable}
                  />
                </div>
                <div className={styles.buttonContainer}>
                  {!editable ? (
                    <Button
                      type='button'
                      label='Редагувати'
                      onClick={(e) => {
                        e.preventDefault();
                        return makeEditableForm();
                      }}
                    />
                  ) : (
                    <>
                      <Button type='submit' label='Зберегти' />
                      <Button
                        type='button'
                        label='Відмінити'
                        className='p-button-secondary'
                        onClick={(e) => {
                          handleReset();
                          e.preventDefault();
                          return cancelEditableForm();
                        }}
                      />
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Profile;
