import React, {useState} from 'react';
import Header from '../../Header';
import styles from './FreeTenders.module.scss';
import SidebarPage from '../../../containers/Sidebar/SidebarPage';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'primereact/button';
import {
  setCurrentPageFreeTenders, setCurrentTenderIdToStorage, setPrevPage,
  setSortFreeTenders,
} from '../../../store/actions/tendersActions';
import {Dropdown} from 'primereact/dropdown';
import {popupDialog} from '../../../commons/PopupDialog/PopupDialog';
import {setNotificationsIsVisible} from '../../../store/actions/notificationsActions';
import Notifications from '../../Header/Notifications/Notifications';
import Preloader from '../../../commons/Preloader/Preloader';
import {getUserRole} from '../../../store/actions/loginActions';

const FreeTenders = ({statusChecker, goToTender, preloaderIsActive}) => {
  const sortBy = useSelector(state => state.tenderReducer.sortTypeFreeTenders);
  const userRole = Number(getUserRole() || 4);
  const dispatch = useDispatch();
  const freeTenders = useSelector(state => state.tenderReducer.freeTenders);
  const page = useSelector(state => state.tenderReducer.currentFreeTendersPage);
  const [rowCount, setRowCount] = useState(10);
  const rowsCountData = [
    {label: 5, value: 5},
    {label: 10, value: 10},
    {label: 50, value: 50},
    {label: 100, value: 100},
  ];
  const sortData = [
    {label: 'датою створення', value: 'created'},
    {label: 'датою оновлення', value: 'updated'},
  ];

  const getFullDate = (fullDate) => {
    const date = fullDate.match(/\d+-\d+-\d+/)[0].split('-').reverse().join('-');
    const time = new Date(fullDate).toString().match(/\d+:\d+/)[0];

    return `${date} ${time}`
  };

  const tendersWithStatus = freeTenders.map(item => ({
    ...item,
    created_at: getFullDate(item.created_at),
    updated_at: getFullDate(item.updated_at),
    status_code: statusChecker(item.status_code),
    status: item.status_code,
  }));

  return (
      <div style={{position: 'relative'}}>
        <Preloader active={preloaderIsActive}/>
        <Header/>
        <div
            className={styles.mainContainer}
            onClick={() => dispatch(setNotificationsIsVisible(false))}
        >
          <SidebarPage/>
          <Notifications/>
          <div className={styles.contentContainer}>
            <div className={styles.dropdownContainer}>
              <div>
                <span>Сортувати за:</span>
                <Dropdown
                    value={sortBy}
                    options={sortData}
                    onChange={e => dispatch(setSortFreeTenders(e.value))}
                />
              </div>
              <div>
                <span>Кількість рядків у таблиці:</span>
                <Dropdown
                    value={rowCount}
                    options={rowsCountData}
                    onChange={e => setRowCount(e.value)}
                />
              </div>
            </div>
              <DataTable paginator value={tendersWithStatus} rows={rowCount} first={page} onPage={e => dispatch(setCurrentPageFreeTenders(e.first))}>
                <Column field="internal_id" header="ID" style={{width: 80}} sortable filter bodyStyle={{textAlign: 'center'}}/>
                <Column columnKey='actions' header="Дії" style={{width: 100}} body={(e) => (
                    <div className={styles.tableButtonContainer}>
                      <Button icon="pi pi-plus"
                              tooltip='Додати до "Мої заявки"'
                              tooltipOptions={{position: 'top'}}
                              onClick={() => {popupDialog(`Ви дійсно хочите додати заявку ${e.name}?`,
                                   async () => await goToTender(e),
                                  'Додати',
                                  () => {});
                              setCurrentTenderIdToStorage(e._id);
                              dispatch(setPrevPage(0))
                              }}
                              disabled={userRole === 4}
                      />
                    </div>)}
                />
                <Column field="name" header="Назва заявки" style={{width: '28%'}} sortable filter  filterMatchMode="contains"/>
                <Column field="company.name" header="Компанія" style={{width: '28%'}} sortable filter  filterMatchMode="contains"/>
                <Column field="status_code" header="Статус" style={{width: '22%'}} filter sortable sortField="status"  filterMatchMode="contains"/>
                <Column
                    field={sortBy === 'created' ? 'created_at' : 'updated_at'}
                    header={sortBy === 'created' ? 'Дата створення' : 'Дата оновлення'}
                    style={{width: '22%'}} sortable bodyStyle={{textAlign: 'center'}}/>
              </DataTable>
          </div>
        </div>
      </div>
  );
};

export default FreeTenders;