import React, { useEffect, useState } from 'react';
import Profile from '../../components/Profile';
import { getUserData } from '../../services/UserService';
import { getUserId } from '../../store/actions/loginActions';
import routes from '../../constants/routes';

const ProfilePage = ({ history }) => {
  const [editable, setEditable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userData, setUserData] = useState({});
  const userId = getUserId();

  useEffect(() => {
    if (userId) {
      (async () => {
        const fullUserData = await getUserData(userId);
        setUserData(fullUserData)
      })();
    }
  }, [refresh]);

  const makeEditableForm = (e) => {
    setEditable(true);
  };

  const cancelEditableForm = (e) => {
    setEditable(false)
  };

  const goBack = () => {
    history.push(routes.HOME_PAGE)
  };

  return (
    <Profile
      goBack={goBack}
      refresh={refresh}
      setRefresh={setRefresh}
      setEditable={setEditable}
      userData={userData}
      editable={editable}
      makeEditableForm={makeEditableForm}
      cancelEditableForm={cancelEditableForm}
    />
  );
};

export default ProfilePage;
