import React, { useEffect, useState } from 'react';
import styles from './TenderCardEdit.module.scss';
import { getOffersByTenderId, getTenderById } from '../../../services/TenderService';
import Header from '../../Header';
import SidebarPage from '../../../containers/Sidebar/SidebarPage';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../../services/CategoriesService';
import { getCurrentTenderId, setCurrentTenderData, setTenderOffers } from '../../../store/actions/tendersActions';
import TenderCard from './TenderCard/TenderCard';
import { setNotificationsIsVisible } from '../../../store/actions/notificationsActions';
import Notifications from '../../Header/Notifications/Notifications';
import Preloader from '../../../commons/Preloader/Preloader';

const TenderCardEdit = ({ history, getName }) => {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const tenderId = getCurrentTenderId();
  const currentTender = useSelector(state => state.tenderReducer.currentTender);
  // const [statusCode, setStatusCode] = useState(currentTender?.status_code);
  const [descriptionValue, setDescriptionValue] = useState(currentTender?.description);
  const [additionalConditions, setAdditionalConditions] = useState(currentTender?.additional_conditions);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const offersList = await getOffersByTenderId(tenderId);
      const currentCategories = await getTenderById(tenderId);
      const tender = await getTenderById(tenderId);
      const allCategories = await getAllCategories() || [];
      const preparedOffersList = offersList?.map(offer => ({ ...offer, current_amount: `${offer?.amount || ''} ${offer?.item?.measureUnit || ''}` }));

      dispatch(setCurrentTenderData(tender));
      dispatch(setTenderOffers(preparedOffersList));
      setDescriptionValue(tender?.description);
      setAdditionalConditions(tender?.additional_conditions);
      // setStatusCode(tender.status_code);
      setAllCategories(allCategories);
      setCategories(currentCategories);
      setPreloaderIsActive(false);
    })();
  }, [refresh]);

  return (
    <div className={styles.container}>
      <Preloader active={preloaderIsActive} />
      <div style={{ position: 'fixed', width: '100%', height: 70, zIndex: 10 }}>
        <Header />
      </div>
      <div
        className={styles.mainContainer}
        onClick={() => dispatch(setNotificationsIsVisible(false))}
      >
        <div style={{ position: 'fixed', width: 230, height: '100%', zIndex: 10 }}>
          <SidebarPage />
        </div>
        <Notifications />
        <TenderCard
          setPreloaderIsActive={setPreloaderIsActive}
          setCategories={setCategories}
          setAdditionalConditions={setAdditionalConditions}
          setDescriptionValue={setDescriptionValue}
          // setStatusCode={setStatusCode}
          descriptionValue={descriptionValue}
          additionalConditions={additionalConditions}
          // status_code={statusCode}
          currentTender={currentTender}
          allCategories={allCategories}
          categories={categories}
          history={history}
          setRefresh={setRefresh}
          getName={getName}
          refresh={refresh}
          tenderId={tenderId}
        />
      </div>
    </div>
  );
};

export default TenderCardEdit;
