// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Registration_container__GKx5l {\n  margin: 4rem auto;\n  width: 30%;\n  min-height: 400px;\n  background-color: #fff;\n  border: 1px solid #c8c8c8;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.Registration_formContainer__3OaWC {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n\n.Registration_buttonContainer__3fy-L {\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .Registration_buttonContainer__3fy-L button:first-child {\n    margin-bottom: 15px; }\n", ""]);
// Exports
exports.locals = {
	"container": "Registration_container__GKx5l",
	"formContainer": "Registration_formContainer__3OaWC",
	"buttonContainer": "Registration_buttonContainer__3fy-L"
};
module.exports = exports;
