import React, { useState, useEffect } from 'react';
import OrdersRegistry from '../../components/Suppliers/OrdersRegistry/OrdersRegistry';
import { getTendersForSupplier } from '../../services/SupplierService';
import { useDispatch } from 'react-redux';
import { setTendersForSupplier } from '../../store/actions/supplierActions';

const OrdersRegistryPage = () => {
  const dispatch = useDispatch();
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);
  const [isEditingOrdersRegistry, setIsEditingOrdersRegistry] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const tenders = await getTendersForSupplier() || [];
      dispatch(setTendersForSupplier(tenders));
      setPreloaderIsActive(false);
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      setPreloaderIsActive(true);
      const tenders = await getTendersForSupplier() || [];
      dispatch(setTendersForSupplier(tenders));
      setPreloaderIsActive(false);
    })()
  }, []);

  return (
    <OrdersRegistry
      preloaderIsActive={preloaderIsActive}
      refresh={refresh}
      setRefresh={setRefresh}
      isEditingOrdersRegistry={isEditingOrdersRegistry}
      setIsEditingOrdersRegistry={setIsEditingOrdersRegistry}
    />
  );
}

export default OrdersRegistryPage;
