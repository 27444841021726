import React, {useState} from 'react';
import styles from './Login.module.scss';
import {Field, Form, Formik} from 'formik';
import FormInput from '../../commons/FormInput';
import FormPassword from '../../commons/FormPassword';
import * as Yup from 'yup';
import Header from '../Header';
import {Button} from 'primereact/button';
import {confirmCode, login, resendPassword} from '../../services/LoginService';
import {popupDialog} from '../../commons/PopupDialog/PopupDialog';
import Preloader from '../../commons/Preloader/Preloader';

const Login = ({toRestorePasswordPage, toHomePage}) => {
  const [isConfirmCode, setIsConfirmCode] = useState(false);
  const [preloaderIsActive, setPreloaderIsActive] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup
      .string()
      .email('Неправильний email')
      .required("Це поле обов'язкове"),
    password: Yup
    .string()
    .min(8, 'Пароль повинен містити мінімум 8 символів')
    .matches(/(?!.*[\s])(?=.*[a-z])(?=.*[0-9]).{8,}$/i, 'Пароль може містити цифри, букви англійського алфавіту та спецсимволи')
    .required("Це поле обов'язкове"),
  });

  return (
      <>
        <Preloader active={preloaderIsActive}/>
        <Header/>
        <div className={styles.container}>
          <h2>Вхід</h2>
          <Formik
              initialValues={{
                email: '',
                password: '',
                code: '',
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, {setSubmitting}) => {
                setPreloaderIsActive(true);
                setSubmitting(false);
                if (isConfirmCode) {
                  const isValidCode = await confirmCode(Number(values.code), values.email, values.password);
                  setPreloaderIsActive(false);
                  return isValidCode && toHomePage();
                }

                const data = await login(values.email, values.password);
                setPreloaderIsActive(false);
                !data.twoFA && toHomePage();
                data.twoFA && popupDialog('Код відправлений на Ваш email', () => setIsConfirmCode(data.success), 'Ок')
              }}
              validateOnChange={false}
              validateOnMount={true}
          >
            {({values}) => (
                <Form className={styles.formContainer}>
                  {
                    isConfirmCode
                      ? (
                          <>
                            <Field name={`code`}
                                   label="Код"
                                   component={FormInput}
                                   required
                            />
                            <div
                                className={styles.resendText}
                                onClick={() => popupDialog(
                                    'Ви дійсно хочете відправити код повторно?',
                                    () => resendPassword(values.email),
                                    'Так',
                                    () => {},
                                    'Ні')}
                            >
                              Відправити повторно
                            </div>
                          </>
                        )
                      : (
                            <>
                              <Field name={`email`}
                                     label="Пошта"
                                     keyfilter='email'
                                     component={FormInput}
                                     required
                              />
                              <Field name={`password`}
                                     label="Пароль"
                                     component={FormPassword}
                                     required
                              />
                            </>
                        )
                  }
                  <div className={styles.buttonContainer}>
                    <Button label="Увійти" type="submit"/>
                  </div>
                </Form>
            )}
          </Formik>
          {!isConfirmCode && (
              <Button
                  label="Забули пароль?"
                  type="submit"
                  className="p-button-secondary"
                  onClick={toRestorePasswordPage}
              />
          )}
        </div>
      </>
  );
};

export default Login;
