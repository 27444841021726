import {
  SET_CHAT_VISIBILITY,
  LOAD_CHAT_MESSAGES,
  SET_CURRENT_USER_ID,
  SET_AUDIO_ID,
} from '../constants/actionTypes';

const initialState = {
  isVisible: false,
  chatMessages: [],
  currentUserId: '',
  currentAudioId: '',
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_VISIBILITY:
      return {...state, isVisible: action.payload};
    case LOAD_CHAT_MESSAGES:
      return {...state, chatMessages: action.payload};
    case SET_CURRENT_USER_ID:
      return {...state, currentUserId: action.payload};
    case SET_AUDIO_ID:
      return {...state, currentAudioId: action.payload};
    default:
      return state;
  }
};

export default chatReducer;
