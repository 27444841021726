import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom'
import Header from '../../Header';
import styles from './ClientHistory.module.scss';
import SidebarPage from '../../../containers/Sidebar/SidebarPage';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {getAllClientsWithUsers} from '../../../services/ClientService';
import {Button} from 'primereact/button';
import routes from '../../../constants/routes';
import {setNotificationsIsVisible} from '../../../store/actions/notificationsActions';
import Notifications from '../../Header/Notifications/Notifications';
import {useDispatch} from 'react-redux';

const ClientHistory = ({currentHistory}) => {
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const getFullDate = (fullDate) => {
    const date = fullDate.match(/\d+-\d+-\d+/)[0].split('-').reverse().join('-');
    const time = new Date(fullDate).toString().match(/\d+:\d+/)[0];

    return `${date} ${time}`
  };

  useEffect(() => {
    (async () => {
      const users = await getAllClientsWithUsers() || [];
      setUsers(users);
    })()
  }, []);

const tableData = currentHistory.map(item => {
  const data = Object.entries(item.data);
  const date = getFullDate(item.created_at);
  const userName = users.find(manager => manager.id === item.author_id)?.fullName;

  return data.map(elem => ({
    date,
    label: `${elem[0]}`,
    value: `${elem[1]}`,
    name: userName,
  }));
});

const currentTableData = tableData.map(item => ({...item[0]}));

  return (
      <div>
        <Header/>
        <div
            className={styles.mainContainer}
            onClick={() => dispatch(setNotificationsIsVisible(false))}
        >
          <SidebarPage/>
          <Notifications/>
          <div className={styles.container}>
            <div className={styles.title}>Історія</div>
            <DataTable emptyMessage="Історія відсутня" value={currentTableData}>
              <Column field="name" header="Автор" style={{width: '22%'}} sortable/>
              <Column field="label" header="Поле" style={{width: '22%'}} sortable/>
              <Column field="value" header="Значення" style={{width: '22%'}} sortable/>
              <Column field="date" header="Дата" style={{width: '22%'}} sortable bodyStyle={{textAlign: 'center'}}/>
            </DataTable>
            <Button
              style={{marginTop: 30}}
              label="Назад"
              onClick={() => history.push(routes.CLIENTS_PAGE)}
            />
          </div>
        </div>
      </div>
  );
};

export default ClientHistory;