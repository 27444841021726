import React from 'react';
import {addNewCategory} from '../../../services/CategoriesService';
import {popupDialog} from '../../../commons/PopupDialog/PopupDialog';
import {Field, Form, Formik} from 'formik';
import styles from '../Categories.module.scss';
import FormInput from '../../../commons/FormInput';
import {Button} from 'primereact/button';
import * as Yup from 'yup';

const AddNewCategory = ({setRefresh, refresh, setNewCategory}) => {
  const categoryValidationSchema = Yup.object().shape({
    newCategory: Yup
    .string()
    .required("Це поля є обов'язковим"),
  });

  return (
      <Formik
          initialValues={{
            newCategory: '',
          }}
          validationSchema={categoryValidationSchema}
          onSubmit={async (values, {setSubmitting}) => {
            setSubmitting(false);
            const isSuccess = await addNewCategory(values.newCategory);
            isSuccess
                && setNewCategory(false); setRefresh(!refresh)
          }}
          validateOnChange={false}
      >
        {({values}) => (
            <Form>
                <Field name={`newCategory`}
                       label="Категорія"
                       component={FormInput}
                       required
                />
              <div className={styles.submitButtonContainer}>
                <Button type="submit" label="Додати"/>
                <Button label="Відмінити" type="button" className="p-button-secondary"
                        onClick={() => setNewCategory(false)}/>
              </div>
            </Form>
        )}
      </Formik>
  );
};

export default AddNewCategory;