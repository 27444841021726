import React from 'react';
import RestorePasswordWithEmail from '../../components/Login/RestorePasswordWithEmail';
import routes from '../../constants/routes';

const RestorePasswordWithEmailPage = ({history}) => {
  const backToLoginPage = () => {
    history.push(routes.LOGIN_PAGE);
  };

  return (
      <RestorePasswordWithEmail
          backToLoginPage={backToLoginPage}
      />
  );
};

export default RestorePasswordWithEmailPage;