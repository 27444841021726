// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrdersRegistry_mainContainer__1-9sU {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.OrdersRegistry_contentContainer__1kFvJ {\n  width: 100%;\n  height: calc(100vh - 70px);\n  background-color: #ecf0f5;\n  padding: 25px 50px;\n  overflow: scroll;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: nowrap; }\n\n.OrdersRegistry_dropdownContainer__1QYph {\n  width: 100%;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap; }\n  .OrdersRegistry_dropdownContainer__1QYph span {\n    margin-right: 20px; }\n\n.OrdersRegistry_registryTable__3X630 td {\n  padding: 5px !important;\n  font-size: 12px; }\n\n.OrdersRegistry_registryTable__3X630 th {\n  padding: 3px !important;\n  font-size: 12px;\n  font-weight: 500; }\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "OrdersRegistry_mainContainer__1-9sU",
	"contentContainer": "OrdersRegistry_contentContainer__1kFvJ",
	"dropdownContainer": "OrdersRegistry_dropdownContainer__1QYph",
	"registryTable": "OrdersRegistry_registryTable__3X630"
};
module.exports = exports;
