import {
  SET_CURRENT_USER,
  SET_ALL_USERS,
  SET_USER_ROLE,
  SET_USERS_CURRENT_PAGE,
  SET_USERS_ROWS_IN_TABLE,
  SET_CURRENT_USER_ID,
  SET_USER_NAME,
} from '../constants/actionTypes';

const initialState = {
  currentUser: {
    id: '',
    fullName: '',
    email: '',
    role: '',
    phone: '',
    additional_phone: '',
  },
  allUsers: [],
  userRole: null,
  currentPage: 0,
  rowsInTable: 10,
  currentUserId: '',
  userName: '',
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {...state, currentUser: action.payload};
    case SET_USER_NAME:
      return {...state, userName: action.payload?.fullName};
    case SET_USER_ROLE:
      return {...state, userRole: action.payload?.role?.role};
    case SET_ALL_USERS:
      return {...state, allUsers: action.payload};
    case SET_USERS_CURRENT_PAGE:
      return {...state, currentPage: action.payload};
    case SET_USERS_ROWS_IN_TABLE:
      return {...state, rowsInTable: action.payload};
    case SET_CURRENT_USER_ID:
      return {...state, currentUserId: action.payload};
    default:
      return state;

  }
};

export default usersReducer;
