import axios from 'axios';
import {BASE_URL} from '../constants/serviceConstants';
import {popupDialog} from '../commons/PopupDialog/PopupDialog';
const AUTH_TOKEN = 'auth-token';

export const getAllCompanies = async () => {
  return await axios.get(
      `${BASE_URL}/users/ownersWithStaff`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
      .then(({data}) => data)
      .catch(err => console.log('error', err))
};

export const editClient = async (values, role) => {
  const {id, additional_phone, phone, fullName} = values;
  const splitName = (fullName || '').split(' ');
  const data = {role: {role}, name: splitName[0], surname: splitName[1], lastName: splitName[2], phone, additional_phone, fullName};

  return await axios.patch(
      `${BASE_URL}/users/${id}`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data.success)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const editOwnerById = async (values) => {
  const {id, additional_phone, fullName, phone, company_phone, mfi,
    company_additional_phone, bank_name, documents_delivery_address,
    edrpou, goods_delivery_address, payment_account, company_id, role_id} = values;

  const data = {
    company: {
      _id: company_id,
      additional_phone: company_additional_phone,
      bank_name,
      documents_delivery_address,
      edrpou,
      goods_delivery_address,
      mfi,
      payment_account,
      phone: company_phone,
      users: [],
    },
    profile: {
      id,
      additional_phone,
      fullName,
      phone,
      role: {
        id: role_id,
        role: 2,
      },
    },
  };

  return await axios.patch(
      `${BASE_URL}/users/profile`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const removeCurrentClient = async (id) => {
  return await axios.delete(
      `${BASE_URL}/users/${id}`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => console.log('data', data))
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const getAllClientsWithUsers = async () => {
  return await axios.get(
      `${BASE_URL}/users`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data)
  .catch(err => console.log('error', err))
};

export const getClientsHistoryById = async (id) => {
  return await axios.get(
      `${BASE_URL}/users/${id}/changes`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => {console.log('data', data); return data})
  .catch(err => console.log('error', err))
};

export const restoreOwner = async (oldId, newId) => {
  return await axios.patch(
      `${BASE_URL}/users/reassignOwner`,
      {
        ownerToRemoveId: oldId,
        newOwnerId: newId
      },
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data.success)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const changeEnableTypeOwner = async (ownerId, staffWorkerId) => {
  return await axios.patch(
      `${BASE_URL}/users/setOwnerEnabledOrDisabled`,
      {ownerId, staffWorkerId},
      {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
  .then(({data}) => data.success)
  .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};

export const addNewClient = async (values, companyData, role) => {
  const {additional_phone, phone, email, fullName} = values;
  const splitName = (fullName || '').split(' ');

    return await axios.post(`${BASE_URL}/users/addUserToCompany`,
        {
                name: splitName[0],
                surname: splitName[1],
                lastName: splitName[2],
                fullName,
                company_name: companyData.data.company,
                email,
                registrationType: 'email',
                role,
                companyId: companyData.key,
                phone,
                additional_phone
            },
        {headers: {"Authorization": `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}})
        .then(({data}) => data.success)
        .catch(({response}) => popupDialog(`${response.data.message}`, () => {}, 'Ок'))
};
