// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormCalendar_container__1BJ-8 {\n  position: relative;\n  margin-bottom: 10px; }\n  .FormCalendar_container__1BJ-8 .FormCalendar_formLabel__EaAKR {\n    width: 100%;\n    font-weight: 700; }\n  .FormCalendar_container__1BJ-8 .FormCalendar_formError__3Y5yl {\n    position: absolute;\n    right: -15px;\n    transform: translateX(100%); }\n", ""]);
// Exports
exports.locals = {
	"container": "FormCalendar_container__1BJ-8",
	"formLabel": "FormCalendar_formLabel__EaAKR",
	"formError": "FormCalendar_formError__3Y5yl"
};
module.exports = exports;
